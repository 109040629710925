import axios from "axios"
import csvParser from "papaparse"

import { DataStore } from "../../types/types"
import { transform } from "../utils"
import { csv } from "../tableCsvExports/Industry_Table_021420"

const parsedData = csvParser.parse(csv(), {
  delimiter: ",",
})

function csvToMap(parsedData: object[]) {
  const map = {}
  for (let x = 2; x < parsedData.length; x++) {
    if (parsedData[x][2] !== undefined) {
      map[`${parsedData[x][2]}`] = [parsedData[x][1]]
    }
  }

  map["Utilities General"] = ["22"]
  map["Professional, Scientific, and Technical Services General"] = ["54"]
  map["Management of Companies and Enterprises General"] = ["55"]
  map["Educational Services General"] = ["61"]
  map["Unclassified Industry General"] = ["99"]

  return map
}

const CSV_DATA = csvToMap(parsedData.data)

const OAUTH_URL = "https://agnitio.emsicloud.com/emsi.us.industry/latest"

export function getPostData(fromYear: number, toYear: number) {
  return {
    metrics: [
      {
        name: `Jobs.${fromYear}`,
        as: `${fromYear} Jobs`,
      },
      {
        name: `Jobs.${toYear}`,
        as: `${toYear} Jobs`,
      },
      {
        name: "Earnings.2019",
        as: "2019 Earnings",
      },
      {
        name: "Jobs.2019",
        as: "2019 Locations Quotient",
        operation: {
          name: "LocationQuotient",
          geoparent: "0",
          along: "Industry",
        },
      },
    ],
    constraints: [
      {
        dimensionName: "Area",
        map: {
          "Fayetteville-Springdale-Rogers, AR": ["5007", "5087", "5143"],
        },
      },
      {
        dimensionName: "Industry",
        map: CSV_DATA,
      },
    ],
  }
}

export function transformName(name: string, fromYear: number, toYear: number) {
  switch (name) {
    case "Industry":
      return "description"
    case `${fromYear} Jobs`:
      return `jobs${fromYear}`
    case `${toYear} Jobs`:
      return `jobs${toYear}`
    case "2019 Earnings":
      return "currentWagesSalariesProprietorEarnings"
    case "2019 Locations Quotient":
      return "locationsQuotient2019"
    default:
      return name
  }
}

function dynamicSort(property) {
  var sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function industryApiCall(
  store: DataStore,
  token: string,
  toYear: number,
  fromYear: number
) {
  return axios({
    method: "POST",
    url: OAUTH_URL,
    data: getPostData(fromYear, toYear),
    headers: {
      "content-type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      const transformedData = transform({
        data: response.data.data,
        fromYear,
        toYear,
        transformName,
      })
      const finalizedData = transformedData.map(el => {
        el["naics"] = CSV_DATA[el.description]
        return el
      })
      const sortedData = finalizedData.sort(dynamicSort("naics"))
      store.updateIndustryTable(sortedData)
    })
    .catch(function(error) {
      console.log("err", error)
    })
}
