import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { TiArrowBack } from "react-icons/ti"

const LinkStyles = styled.div`
  width: 182px;
  height: 25px;
  text-align: center;
  font-size: 34px;
`

const StartOverButton: React.FC = () => {
  return (
    <Link style={{ marginLeft: 21 }} to="/" className="reset">
      <LinkStyles>
        <span style={{ fontSize: 14 }}>
          <TiArrowBack
            style={{
              marginRight: 4,
              marginBottom: -5,
              height: 20,
              width: 20,
            }}
          />
          Start Over
        </span>
      </LinkStyles>
    </Link>
  )
}

export default StartOverButton
