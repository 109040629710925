import axios from "axios"
import { DataStore } from "../../types/types"
import { transform } from "../utils"
const OAUTH_URL = "https://agnitio.emsicloud.com/emsi.us.demographics/latest"
const MAP = {
  "Under 5 years": ["1"],
  "5 to 9 years": ["2"],
  "10 to 14 years": ["3"],
  "15 to 19 years": ["4"],
  "20 to 24 years": ["5"],
  "25 to 29 years": ["6"],
  "30 to 34 years": ["7"],
  "35 to 39 years": ["8"],
  "40 to 44 years": ["9"],
  "45 to 49 years": ["10"],
  "50 to 54 years": ["11"],
  "55 to 59 years": ["12"],
  "60 to 64 years": ["13"],
  "65 to 69 years": ["14"],
  "70 to 74 years": ["15"],
  "75 to 79 years": ["16"],
  "80 to 84 years": ["17"],
  "85 to 89 years": ["18"],
}

export function getPostData(fromYear: number, toYear: number) {
  return {
    metrics: [
      {
        name: `Population.${fromYear}`,
        as: `${fromYear} Population`,
      },
      {
        name: `Population.${toYear}`,
        as: `${toYear} Population`,
      },
    ],
    constraints: [
      {
        dimensionName: "Area",
        map: {
          "Fayetteville-Springdale-Rogers, AR": ["5007", "5087", "5143"],
        },
      },
      {
        dimensionName: "AgeGroup",
        map: MAP,
      },
    ],
  }
}

export function transformName(name: string, fromYear: number, toYear: number) {
  switch (name) {
    case "AgeGroup":
      return "demographic"
    case `${fromYear} Population`:
      return `population${fromYear}`
    case `${toYear} Population`:
      return `population${toYear}`
    default:
      return name
  }
}

export function makePct(number: number) {
  return `${Math.round(100 * number)}%`
}

function calculatePctOfPopulation(
  data: object[],
  fromYear: number,
  toYear: number
) {
  let fromTotal = 0
  let toTotal = 0
  for (let x = 0; x < data.length; x++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    fromTotal += parseInt(data[x][`population${fromYear}`].replace(/,/g, ""))
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    toTotal += parseInt(data[x][`population${toYear}`].replace(/,/g, ""))
  }
  for (let x = 0; x < data.length; x++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    data[x][`pctPopulation${fromYear}`] =
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      makePct(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore ts doesn't like variable names for objects
        parseInt(data[x][`population${fromYear}`].replace(/,/g, "")) / fromTotal
      )

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    data[x][`pctPopulation${toYear}`] = makePct(
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      parseInt(data[x][`population${toYear}`].replace(/,/g, "")) / toTotal
    )

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    data[x][`population${fromYear}`] / fromTotal
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    data[x][`pctOf${toYear}Population`]
  }
}

export function demographicsApiCall(
  store: DataStore,
  token: string,
  toYear: number,
  fromYear: number
) {
  return axios({
    method: "POST",
    url: OAUTH_URL,
    data: getPostData(fromYear, toYear),
    headers: {
      "content-type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      const transformed = transform({
        data: response.data.data,
        fromYear,
        toYear,
        transformName,
      })
      calculatePctOfPopulation(transformed, fromYear, toYear)
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      const firstElement = transformed.pop()
      const spliced = transformed.splice(9)
      const secondElement = transformed.pop()
      transformed.unshift(secondElement)
      transformed.unshift(firstElement)
      const final = [...transformed, ...spliced]
      store.updateDemographicsTable(final)
    })
    .catch(function(error) {
      console.log("err", error)
    })
}
