export function csv() {
  return `
"Level","SOC","Description","2001 Jobs","2019 Jobs","2001 - 2019 % Change","2001 - 2019 Change","Annual Replacement Jobs","Median Annual Earnings"
2,11-0000,"Management Occupations",16326.6300007,24675.2902493,0.511300300116,8348,1705.90511111,69577.3481631
3,11-1000,"Top Executives",2926.0982025,5447.2931381,0.861585782638,2521,344.295777778,79534.4590367
4,11-1010,"Chief Executives",616.423827879,829.5418673,0.347402597403,214,53.9227777778,86702.6229454
5,11-1011,"Chief Executives",616.423827879,829.5418673,0.347402597403,214,53.9227777778,86702.6229454
4,11-1020,"General and Operations Managers",2231.82326673,4516.21504653,1.02329749104,2284,283.152055556,80331.5423201
5,11-1021,"General and Operations Managers",2231.82326673,4516.21504653,1.02329749104,2284,283.152055556,80331.5423201
4,11-1030,Legislators,77.8511078901,101.536224264,0.307692307692,24,7.22094444444,19117.2523446
5,11-1031,Legislators,77.8511078901,101.536224264,0.307692307692,24,7.22094444444,19117.2523446
3,11-2000,"Advertising, Marketing, Promotions, Public Relations, and Sales Managers",776.57434845,1661.17044458,1.13770913771,884,105.030222222,141513.251952
4,11-2010,"Advertising and Promotions Managers",40.8885520294,50.3438539051,0.219512195122,9,3.934,120363.296361
5,11-2011,"Advertising and Promotions Managers",40.8885520294,50.3438539051,0.219512195122,9,3.934,120363.296361
4,11-2020,"Marketing and Sales Managers",700.582774601,1489.20100514,1.12410841655,788,94.6437222222,142141.698373
5,11-2021,"Marketing Managers",328.806127376,848.777472262,1.58054711246,520,47.6327222222,154156.552275
5,11-2022,"Sales Managers",371.776647226,640.423532874,0.720430107527,268,47.011,118341.825795
4,11-2030,"Public Relations and Fundraising Managers",35.103021819,121.625585544,2.48571428571,87,6.4525,140122.553166
5,11-2031,"Public Relations and Fundraising Managers",35.103021819,121.625585544,2.48571428571,87,6.4525,140122.553166
3,11-3000,"Operations Specialties Managers",1820.52016038,3687.28039566,1.02471169687,1866,211.235888889,116303.059403
4,11-3010,"Administrative Services Managers",167.88644815,332.530454839,0.982142857143,165,20.4913333333,96595.8374739
5,11-3011,"Administrative Services Managers",167.88644815,332.530454839,0.982142857143,165,20.4913333333,96595.8374739
4,11-3020,"Computer and Information Systems Managers",237.084349043,522.304048063,1.20253164557,285,29.6398888889,105036.1025
5,11-3021,"Computer and Information Systems Managers",237.084349043,522.304048063,1.20253164557,285,29.6398888889,105036.1025
4,11-3030,"Financial Managers",587.771226078,1301.86997599,1.21428571429,714,71.9585555556,123655.266572
5,11-3031,"Financial Managers",587.771226078,1301.86997599,1.21428571429,714,71.9585555556,123655.266572
4,11-3050,"Industrial Production Managers",333.967563152,466.107877058,0.395209580838,132,27.3177777778,100273.761967
5,11-3051,"Industrial Production Managers",333.967563152,466.107877058,0.395209580838,132,27.3177777778,100273.761967
4,11-3060,"Purchasing Managers",121.560096497,213.579223297,0.754098360656,92,14.5306666667,144076.270219
5,11-3061,"Purchasing Managers",121.560096497,213.579223297,0.754098360656,92,14.5306666667,144076.270219
4,11-3070,"Transportation, Storage, and Distribution Managers",184.816684838,412.913316952,1.23243243243,228,21.7677777778,119542.062705
5,11-3071,"Transportation, Storage, and Distribution Managers",184.816684838,412.913316952,1.23243243243,228,21.7677777778,119542.062705
4,11-3110,"Compensation and Benefits Managers",76.6040741871,33.3056292311,-0.571428571429,-44,4.0465,158866.123706
5,11-3111,"Compensation and Benefits Managers",76.6040741871,33.3056292311,-0.571428571429,-44,4.0465,158866.123706
4,11-3120,"Human Resources Managers",66.2602736317,325.057584473,3.92424242424,259,15.8408888889,120750.576841
5,11-3121,"Human Resources Managers",66.2602736317,325.057584473,3.92424242424,259,15.8408888889,120750.576841
4,11-3130,"Training and Development Managers",44.5694448001,79.6122857534,0.777777777778,35,5.6425,144576.37658
5,11-3131,"Training and Development Managers",44.5694448001,79.6122857534,0.777777777778,35,5.6425,144576.37658
3,11-9000,"Other Management Occupations",10803.4372894,13879.546271,0.284828288438,3077,1045.34316667,49649.8693045
4,11-9010,"Farmers, Ranchers, and Other Agricultural Managers",6718.78125785,4570.36619017,-0.319839261795,-2149,534.454833333,40370.0950625
5,11-9013,"Farmers, Ranchers, and Other Agricultural Managers",6718.78125785,4570.36619017,-0.319839261795,-2149,534.454833333,40370.0950625
4,11-9020,"Construction Managers",772.152096673,1259.44549374,0.630829015544,487,71.8792777778,56933.0602382
5,11-9021,"Construction Managers",772.152096673,1259.44549374,0.630829015544,487,71.8792777778,56933.0602382
4,11-9030,"Education Administrators",474.486163526,849.923860168,0.793248945148,376,48.1836666667,85962.2905866
5,11-9031,"Education Administrators, Preschool and Childcare Center/Program",80.2407773927,132.696191813,0.6625,53,8.40016666667,33201.4228867
5,11-9032,"Education Administrators, Elementary and Secondary School",179.394067132,308.126415581,0.720670391061,129,18.3186666667,92865.9247467
5,11-9033,"Education Administrators, Postsecondary",162.229999171,251.466794364,0.549382716049,89,14.5038888889,123413.951771
5,11-9039,"Education Administrators, All Other",52.6213198304,157.634458411,1.98113207547,105,6.96094444444,49983.3522369
4,11-9040,"Architectural and Engineering Managers",174.335259284,152.61029132,-0.120689655172,-21,11.2633888889,115826.873782
5,11-9041,"Architectural and Engineering Managers",174.335259284,152.61029132,-0.120689655172,-21,11.2633888889,115826.873782
4,11-9050,"Food Service Managers",312.521497113,490.820966522,0.568690095847,178,44.3662777778,25744.1254932
5,11-9051,"Food Service Managers",312.521497113,490.820966522,0.568690095847,178,44.3662777778,25744.1254932
4,11-9060,"Funeral Service Managers",4.22148074611,3.11298275309,-0.25,-1,0.337944444444,39759.8667857
5,11-9061,"Funeral Service Managers",4.22148074611,3.11298275309,-0.25,-1,0.337944444444,39759.8667857
4,11-9070,"Gaming Managers",0.541055870281,6.91754496923,6,6,0.16,38970.9691406
5,11-9071,"Gaming Managers",0.541055870281,6.91754496923,6,6,0.16,38970.9691406
4,11-9080,"Lodging Managers",85.266314391,206.416541518,1.42352941176,121,14.8885,30632.8106047
5,11-9081,"Lodging Managers",85.266314391,206.416541518,1.42352941176,121,14.8885,30632.8106047
4,11-9110,"Medical and Health Services Managers",256.796872664,723.696909487,1.81712062257,467,35.0427222222,78526.3069231
5,11-9111,"Medical and Health Services Managers",256.796872664,723.696909487,1.81712062257,467,35.0427222222,78526.3069231
4,11-9120,"Natural Sciences Managers",17.9076358063,33.9543793269,0.888888888889,16,2.46222222222,166877.020082
5,11-9121,"Natural Sciences Managers",17.9076358063,33.9543793269,0.888888888889,16,2.46222222222,166877.020082
4,11-9130,"Postmasters and Mail Superintendents",0.627130422344,0,-1,-1,0.108388888889,0
5,11-9131,"Postmasters and Mail Superintendents",0.627130422344,0,-1,-1,0.108388888889,0
4,11-9140,"Property, Real Estate, and Community Association Managers",718.404773415,2306.40321211,2.21169916435,1588,113.418555556,47456.7158149
5,11-9141,"Property, Real Estate, and Community Association Managers",718.404773415,2306.40321211,2.21169916435,1588,113.418555556,47456.7158149
4,11-9150,"Social and Community Service Managers",123.925773562,248.173578014,1,124,17.0991666667,55902.4171281
5,11-9151,"Social and Community Service Managers",123.925773562,248.173578014,1,124,17.0991666667,55902.4171281
4,11-9160,"Emergency Management Directors",4.13836464076,10.0586180361,1.5,6,0.603777777778,42592.7015731
5,11-9161,"Emergency Management Directors",4.13836464076,10.0586180361,1.5,6,0.603777777778,42592.7015731
4,11-9190,"Miscellaneous Managers",1139.33161341,3017.64570282,1.64969271291,1879,151.074444444,43671.7813444
5,11-9199,"Managers, All Other",1139.33161341,3017.64570282,1.64969271291,1879,151.074444444,43671.7813444
2,13-0000,"Business and Financial Operations Occupations",8142.83022939,19429.9022084,1.3860984895,11287,1254.92644444,64414.2162844
3,13-1000,"Business Operations Specialists",4836.15766646,12837.6986255,1.65467328371,8002,813.336166667,66014.8478171
4,13-1010,"Agents and Business Managers of Artists, Performers, and Athletes",26.3013020933,46.4268237873,0.769230769231,20,4.99233333333,33350.9687268
5,13-1011,"Agents and Business Managers of Artists, Performers, and Athletes",26.3013020933,46.4268237873,0.769230769231,20,4.99233333333,33350.9687268
4,13-1020,"Buyers and Purchasing Agents",1249.16438527,1719.70465149,0.377101681345,471,146.733333333,75092.3391202
5,13-1028,"Buyers and Purchasing Agents",1249.16438527,1719.70465149,0.377101681345,471,146.733333333,75092.3391202
4,13-1030,"Claims Adjusters, Appraisers, Examiners, and Investigators",160.740795667,201.268409983,0.248447204969,40,16.4795,59942.4339216
5,13-1031,"Claims Adjusters, Examiners, and Investigators",155.495429301,194.773577037,0.258064516129,40,16.1689444444,59953.3114392
5,13-1032,"Insurance Appraisers, Auto Damage",5.24536636566,6.49483294607,0.2,1,0.3105,52988.9489043
4,13-1040,"Compliance Officers",116.869021829,536.019619261,3.5811965812,419,26.8236111111,61155.1971171
5,13-1041,"Compliance Officers",116.869021829,536.019619261,3.5811965812,419,26.8236111111,61155.1971171
4,13-1050,"Cost Estimators",235.352866438,359.820765309,0.531914893617,125,29.658,58938.2616663
5,13-1051,"Cost Estimators",235.352866438,359.820765309,0.531914893617,125,29.658,58938.2616663
4,13-1070,"Human Resources Workers",315.783749414,1226.26966867,2.87974683544,910,69.0761111111,61038.0064274
5,13-1071,"Human Resources Specialists",310.606900149,1205.82679506,2.87781350482,895,67.1856666667,61270.0916808
5,13-1074,"Farm Labor Contractors",0.157278822161,0.119416705963,0,0,0.0515555555556,43435.6732012
5,13-1075,"Labor Relations Specialists",5.01957044213,20.3234569047,3,15,1.83888888889,47961.5933213
4,13-1080,Logisticians,171.74526285,1305.37788366,6.58720930233,1133,54.338,65533.9868495
5,13-1081,Logisticians,171.74526285,1305.37788366,6.58720930233,1133,54.338,65533.9868495
4,13-1110,"Management Analysts",741.741068293,2170.04987723,1.92452830189,1428,136.612777778,80864.7078615
5,13-1111,"Management Analysts",741.741068293,2170.04987723,1.92452830189,1428,136.612777778,80864.7078615
4,13-1120,"Meeting, Convention, and Event Planners",51.0466934148,346.58856474,5.80392156863,296,21.0077777778,39734.8749299
5,13-1121,"Meeting, Convention, and Event Planners",51.0466934148,346.58856474,5.80392156863,296,21.0077777778,39734.8749299
4,13-1130,Fundraisers,148.519611564,142.604611968,-0.0402684563758,-6,13.5038333333,47205.7218864
5,13-1131,Fundraisers,148.519611564,142.604611968,-0.0402684563758,-6,13.5038333333,47205.7218864
4,13-1140,"Compensation, Benefits, and Job Analysis Specialists",136.247057234,165.804476744,0.220588235294,30,15.9431666667,62682.0439859
5,13-1141,"Compensation, Benefits, and Job Analysis Specialists",136.247057234,165.804476744,0.220588235294,30,15.9431666667,62682.0439859
4,13-1150,"Training and Development Specialists",272.774805316,654.780089631,1.39926739927,382,45.6990555556,56475.6330062
5,13-1151,"Training and Development Specialists",272.774805316,654.780089631,1.39926739927,382,45.6990555556,56475.6330062
4,13-1160,"Market Research Analysts and Marketing Specialists",262.702909024,2385.659641,8.07224334601,2123,112.652222222,72714.3718866
5,13-1161,"Market Research Analysts and Marketing Specialists",262.702909024,2385.659641,8.07224334601,2123,112.652222222,72714.3718866
4,13-1190,"Miscellaneous Business Operations Specialists",947.168138056,1577.32354207,0.665258711721,630,119.8165,55519.7714718
5,13-1199,"Business Operations Specialists, All Other",947.168138056,1577.32354207,0.665258711721,630,119.8165,55519.7714718
3,13-2000,"Financial Specialists",3306.67256293,6592.20358289,0.993347444814,3285,441.590222222,60975.8271188
4,13-2010,"Accountants and Auditors",1403.54520038,2287.08376487,0.628917378917,883,171.473333333,63319.9288466
5,13-2011,"Accountants and Auditors",1403.54520038,2287.08376487,0.628917378917,883,171.473333333,63319.9288466
4,13-2020,"Appraisers and Assessors of Real Estate",288.501730447,557.27976261,0.927335640138,268,35.3291666667,44397.9743089
5,13-2021,"Appraisers and Assessors of Real Estate",288.501730447,557.27976261,0.927335640138,268,35.3291666667,44397.9743089
4,13-2030,"Budget Analysts",34.3137214889,46.1688531385,0.352941176471,12,4.18066666667,71561.5642146
5,13-2031,"Budget Analysts",34.3137214889,46.1688531385,0.352941176471,12,4.18066666667,71561.5642146
4,13-2040,"Credit Analysts",21.841839116,55.2987323484,1.5,33,4.23861111111,52613.1958763
5,13-2041,"Credit Analysts",21.841839116,55.2987323484,1.5,33,4.23861111111,52613.1958763
4,13-2050,"Financial Analysts and Advisors",629.813211455,2212.49969557,2.51111111111,1582,113.060277778,77081.1679709
5,13-2051,"Financial Analysts",197.211531223,563.306376201,1.8578680203,366,33.9890555556,91005.6463703
5,13-2052,"Personal Financial Advisors",402.519428093,1645.21317185,3.08188585608,1242,77.2119444444,69697.0494599
5,13-2053,"Insurance Underwriters",30.0822521393,3.98014751402,-0.866666666667,-26,1.85927777778,50136.1592488
4,13-2060,"Financial Examiners",4.20845442246,38.2862028446,8.5,34,1.293,70464.0665431
5,13-2061,"Financial Examiners",4.20845442246,38.2862028446,8.5,34,1.293,70464.0665431
4,13-2070,"Credit Counselors and Loan Officers",347.334021601,489.474222245,0.409221902017,142,37.0578888889,64024.0763022
5,13-2071,"Credit Counselors",5.98610541203,11.3930147735,0.833333333333,5,0.723,39515.3844375
5,13-2072,"Loan Officers",341.347916188,478.081207472,0.401759530792,137,36.3348888889,64487.0381016
4,13-2080,"Tax Examiners, Collectors and Preparers, and Revenue Agents",181.82569705,243.375441764,0.335164835165,61,22.3008333333,43207.645657
5,13-2081,"Tax Examiners and Collectors, and Revenue Agents",62.3683956389,55.8170555673,-0.0967741935484,-6,5.48961111111,66708.9496384
5,13-2082,"Tax Preparers",119.457301411,187.558386196,0.579831932773,69,16.8112222222,35475.8782987
4,13-2090,"Miscellaneous Financial Specialists",395.288686975,662.736907508,0.678481012658,268,52.6564444444,35599.7390284
5,13-2099,"Financial Specialists, All Other",395.288686975,662.736907508,0.678481012658,268,52.6564444444,35599.7390284
2,15-0000,"Computer and Mathematical Occupations",3392.4281383,8463.40973285,1.49498820755,5071,432.840222222,66298.706243
3,15-1100,"Computer Occupations",3296.44541789,8183.70152361,1.48300970874,4888,421.645166667,66403.6902594
4,15-1110,"Computer and Information Research Scientists",17.4943383029,29.7935056892,0.764705882353,13,1.77127777778,93085.8479348
5,15-1111,"Computer and Information Research Scientists",17.4943383029,29.7935056892,0.764705882353,13,1.77127777778,93085.8479348
4,15-1120,"Computer and Information Analysts",677.800711797,1777.46463643,1.6209439528,1099,87.2755555556,75730.3334838
5,15-1121,"Computer Systems Analysts",612.273887135,1355.95367162,1.21568627451,744,72.7632777778,71950.6154068
5,15-1122,"Information Security Analysts",65.5268246622,421.510964811,5.39393939394,356,14.5123333333,89186.401054
4,15-1130,"Software Developers and Programmers",1424.05437036,3341.48684713,1.34620786517,1917,168.789,80042.879506
5,15-1131,"Computer Programmers",630.809772062,466.78079102,-0.259904912837,-164,44.8285555556,58448.3793645
5,15-1132,"Software Developers, Applications",489.739912924,2158.91965749,3.40612244898,1669,84.6941111111,88113.1062663
5,15-1133,"Software Developers, Systems Software",230.665319923,437.091314219,0.891774891775,206,25.9472777778,89310.3957837
5,15-1134,"Web Developers",72.8393654522,278.695084399,2.82191780822,206,13.3190555556,45316.9095981
4,15-1140,"Database and Systems Administrators and Network Architects",397.588316629,857.842033677,1.15577889447,460,48.2495,68570.2722021
5,15-1141,"Database Administrators",67.1221283604,104.590358667,0.567164179104,38,6.71244444444,74987.5955472
5,15-1142,"Network and Computer Systems Administrators",250.485716357,534.207047959,1.136,284,30.6540555556,64905.7359291
5,15-1143,"Computer Network Architects",79.9804719121,219.044627051,1.7375,139,10.883,78905.0889788
4,15-1150,"Computer Support Specialists",640.201582689,1822.9631459,1.8484375,1183,101.468944444,38058.0125732
5,15-1151,"Computer User Support Specialists",396.851718037,1041.66439387,1.62468513854,645,59.6118888889,38256.0378405
5,15-1152,"Computer Network Support Specialists",243.349864652,781.298752024,2.21399176955,538,41.8570555556,37906.3469308
4,15-1190,"Miscellaneous Computer Occupations",139.306098114,354.151354787,1.54676258993,215,14.0908333333,59993.6844803
5,15-1199,"Computer Occupations, All Other",139.306098114,354.151354787,1.54676258993,215,14.0908333333,59993.6844803
3,15-2000,"Mathematical Science Occupations",95.9827204071,279.708209236,1.91666666667,184,11.1950555556,63996.9379212
4,15-2010,Actuaries,8.95527004625,15.6908859046,0.777777777778,7,0.964333333333,90129.6352331
5,15-2011,Actuaries,8.95527004625,15.6908859046,0.777777777778,7,0.964333333333,90129.6352331
4,15-2020,Mathematicians,0,0.190317353393,0,0,0.0925555555556,74719.1770313
5,15-2021,Mathematicians,0,0.190317353393,0,0,0.0925555555556,74719.1770313
4,15-2030,"Operations Research Analysts",21.6042765418,106.110237532,3.81818181818,84,4.12272222222,61387.5964097
5,15-2031,"Operations Research Analysts",21.6042765418,106.110237532,3.81818181818,84,4.12272222222,61387.5964097
4,15-2040,Statisticians,29.7234069506,157.6723443,4.26666666667,128,5.18911111111,63804.6750368
5,15-2041,Statisticians,29.7234069506,157.6723443,4.26666666667,128,5.18911111111,63804.6750368
4,15-2090,"Miscellaneous Mathematical Science Occupations",35.6997668685,0.0444241451028,-1,-36,0.826388888889,51480
5,15-2098,"Miscellaneous Mathematical Science Occupations",35.6997668685,0.0444241451028,-1,-36,0.826388888889,51480
2,17-0000,"Architecture and Engineering Occupations",2475.68973402,2965.27611572,0.197495961228,489,219.146166667,61815.1220715
3,17-1000,"Architects, Surveyors, and Cartographers",318.137345319,530.049913887,0.666666666667,212,31.8008333333,56767.0518742
4,17-1010,"Architects, Except Naval",242.865668225,434.480808381,0.786008230453,191,25.412,58886.3246874
5,17-1011,"Architects, Except Landscape and Naval",200.792498137,359.146574483,0.786069651741,158,20.9623333333,55880.1954117
5,17-1012,"Landscape Architects",42.0731700873,75.334233898,0.785714285714,33,4.44966666667,87226.442483
4,17-1020,"Surveyors, Cartographers, and Photogrammetrists",75.271677094,95.569105506,0.28,21,6.38883333333,52244.7386645
5,17-1021,"Cartographers and Photogrammetrists",4.31073997527,7.4785700942,0.75,3,0.346111111111,55364.0004368
5,17-1022,Surveyors,70.9609371187,88.0905354118,0.239436619718,17,6.04272222222,52096.7349591
3,17-2000,Engineers,1255.21793862,1634.74142311,0.302788844622,380,98.2285555556,73570.4771641
4,17-2010,"Aerospace Engineers",109.533855784,52.3149420738,-0.527272727273,-58,3.18205555556,43528.7861617
5,17-2011,"Aerospace Engineers",109.533855784,52.3149420738,-0.527272727273,-58,3.18205555556,43528.7861617
4,17-2020,"Agricultural Engineers",3.12273799037,0,-1,-3,0.129722222222,0
5,17-2021,"Agricultural Engineers",3.12273799037,0,-1,-3,0.129722222222,0
4,17-2030,"Biomedical Engineers",3.24330026436,7.35461028161,1.33333333333,4,0.609888888889,65179.0966359
5,17-2031,"Biomedical Engineers",3.24330026436,7.35461028161,1.33333333333,4,0.609888888889,65179.0966359
4,17-2040,"Chemical Engineers",16.7586362037,27.4530079671,0.588235294118,10,1.38977777778,51495.2476259
5,17-2041,"Chemical Engineers",16.7586362037,27.4530079671,0.588235294118,10,1.38977777778,51495.2476259
4,17-2050,"Civil Engineers",219.745956802,446.829099869,1.03181818182,227,26.3462222222,73363.4226305
5,17-2051,"Civil Engineers",219.745956802,446.829099869,1.03181818182,227,26.3462222222,73363.4226305
4,17-2060,"Computer Hardware Engineers",48.6439047054,25.9301092531,-0.469387755102,-23,2.60761111111,49564.9751674
5,17-2061,"Computer Hardware Engineers",48.6439047054,25.9301092531,-0.469387755102,-23,2.60761111111,49564.9751674
4,17-2070,"Electrical and Electronics Engineers",198.818560916,180.44132578,-0.0954773869347,-19,11.9696111111,81265.0306966
5,17-2071,"Electrical Engineers",97.4247655094,106.323440329,0.0927835051546,9,6.45372222222,78250.465357
5,17-2072,"Electronics Engineers, Except Computer",101.393795406,74.1178854505,-0.267326732673,-27,5.51588888889,86036.1065216
4,17-2080,"Environmental Engineers",21.4066583802,17.3009906236,-0.190476190476,-4,1.53377777778,75046.3553168
5,17-2081,"Environmental Engineers",21.4066583802,17.3009906236,-0.190476190476,-4,1.53377777778,75046.3553168
4,17-2110,"Industrial Engineers, Including Health and Safety",224.100278716,349.10431837,0.558035714286,125,19.4387222222,86141.9364017
5,17-2111,"Health and Safety Engineers, Except Mining Safety Engineers and Inspectors",13.3788394366,20.2428115465,0.538461538462,7,0.963944444444,80177.1880314
5,17-2112,"Industrial Engineers",210.721439279,328.861506823,0.559241706161,118,18.4748333333,86603.467604
4,17-2120,"Marine Engineers and Naval Architects",5.03530010712,4.17750984647,-0.2,-1,0.259833333333,83304
5,17-2121,"Marine Engineers and Naval Architects",5.03530010712,4.17750984647,-0.2,-1,0.259833333333,83304
4,17-2130,"Materials Engineers",12.0316403127,31.3592379268,1.58333333333,19,1.1795,80660.766133
5,17-2131,"Materials Engineers",12.0316403127,31.3592379268,1.58333333333,19,1.1795,80660.766133
4,17-2140,"Mechanical Engineers",187.602964749,238.344763726,0.265957446809,50,14.3556666667,63858.2121858
5,17-2141,"Mechanical Engineers",187.602964749,238.344763726,0.265957446809,50,14.3556666667,63858.2121858
4,17-2150,"Mining and Geological Engineers, Including Mining Safety Engineers",0.505934342535,3.58047777805,3,3,0.270166666667,100916.526435
5,17-2151,"Mining and Geological Engineers, Including Mining Safety Engineers",0.505934342535,3.58047777805,3,3,0.270166666667,100916.526435
4,17-2160,"Nuclear Engineers",5.50558679926,8.87343527772,0.5,3,0.715611111111,89405.079166
5,17-2161,"Nuclear Engineers",5.50558679926,8.87343527772,0.5,3,0.715611111111,89405.079166
4,17-2170,"Petroleum Engineers",4.92393897653,70.7486965801,13.2,66,2.42872222222,136089.157672
5,17-2171,"Petroleum Engineers",4.92393897653,70.7486965801,13.2,66,2.42872222222,136089.157672
4,17-2190,"Miscellaneous Engineers",194.238683577,170.92889776,-0.118556701031,-23,11.8115555556,60130.946818
5,17-2199,"Engineers, All Other",194.238683577,170.92889776,-0.118556701031,-23,11.8115555556,60130.946818
3,17-3000,"Drafters, Engineering Technicians, and Mapping Technicians",902.334450079,800.484778717,-0.113082039911,-102,89.1168333333,46822.00635
4,17-3010,Drafters,391.07556815,376.690553034,-0.0358056265985,-14,40.4718333333,45994.1730954
5,17-3011,"Architectural and Civil Drafters",121.942817331,172.078645524,0.409836065574,50,16.0351666667,45362.3832953
5,17-3012,"Electrical and Electronics Drafters",40.3041334863,34.03746834,-0.15,-6,3.90227777778,57606.435431
5,17-3013,"Mechanical Drafters",202.904153645,151.365931811,-0.256157635468,-52,18.2706666667,45564.2356362
5,17-3019,"Drafters, All Other",25.9244636879,19.2085073584,-0.269230769231,-7,2.26377777778,44608.1090343
4,17-3020,"Engineering Technicians, Except Drafters",425.919163325,315.641360786,-0.258215962441,-110,36.3957777778,52310.0836904
5,17-3021,"Aerospace Engineering and Operations Technicians",9.08025274274,3.35597050271,-0.666666666667,-6,0.3275,60315.84
5,17-3022,"Civil Engineering Technicians",101.944706856,89.5149549813,-0.117647058824,-12,9.8665,44054.0838184
5,17-3023,"Electrical and Electronics Engineering Technicians",111.810513845,68.5989519234,-0.383928571429,-43,8.66833333333,60830.552392
5,17-3024,"Electro-Mechanical Technicians",3.86697388134,6.75426873339,0.75,3,0.831611111111,52827.8794808
5,17-3025,"Environmental Engineering Technicians",13.7800961658,19.3601541126,0.357142857143,5,1.9805,42961.6106307
5,17-3026,"Industrial Engineering Technicians",72.7010882945,48.9954183242,-0.328767123288,-24,5.77072222222,43423.2653505
5,17-3027,"Mechanical Engineering Technicians",39.6481232946,29.0618205806,-0.275,-11,3.82383333333,57060.7486634
5,17-3029,"Engineering Technicians, Except Drafters, All Other",73.087408245,49.9998216275,-0.315068493151,-23,5.12683333333,63248.1132218
4,17-3030,"Surveying and Mapping Technicians",85.3397186048,108.152864897,0.270588235294,23,12.2492222222,38833.3133495
5,17-3031,"Surveying and Mapping Technicians",85.3397186048,108.152864897,0.270588235294,23,12.2492222222,38833.3133495
2,19-0000,"Life, Physical, and Social Science Occupations",1120.86709692,1843.50240339,0.64495985727,723,153.001777778,53200.8215064
3,19-1000,"Life Scientists",162.877906701,315.239002872,0.932515337423,152,26.7467777778,64493.7615885
4,19-1010,"Agricultural and Food Scientists",73.1196187777,154.545885302,1.12328767123,82,14.7502222222,82791.2933753
5,19-1011,"Animal Scientists",6.74082615026,7.72146350422,0.142857142857,1,0.555222222222,57682.6761139
5,19-1012,"Food Scientists and Technologists",57.3438040326,134.147908336,1.35087719298,77,12.6560555556,85325.2313943
5,19-1013,"Soil and Plant Scientists",9.03498859477,12.6765134611,0.444444444444,4,1.53894444444,75403.1207649
4,19-1020,"Biological Scientists",38.0573451646,102.501249068,1.71052631579,65,6.17372222222,58048.7086331
5,19-1021,"Biochemists and Biophysicists",1.58452662341,6.33005179451,2,4,0.346833333333,36655.4240213
5,19-1022,Microbiologists,15.4779921688,57.6867937131,2.86666666667,43,3.13766666667,53909.7591049
5,19-1023,"Zoologists and Wildlife Biologists",4.31546484355,3.346459828,-0.25,-1,0.319444444444,52161.7685863
5,19-1029,"Biological Scientists, All Other",16.6793615289,35.1379437321,1.05882352941,18,2.36983333333,79784.6059298
4,19-1030,"Conservation Scientists and Foresters",19.3955283961,13.2321820424,-0.315789473684,-6,1.81827777778,51663.6175952
5,19-1031,"Conservation Scientists",6.67472238516,4.73262663563,-0.285714285714,-2,0.625944444444,46997.9217054
5,19-1032,Foresters,12.7208060109,8.49955540678,-0.384615384615,-5,1.19238888889,53693.8755604
4,19-1040,"Medical Scientists",24.8063661801,40.3909933437,0.6,15,3.31622222222,34620.5842806
5,19-1041,Epidemiologists,0.0546804290218,0.153927581261,0,0,0.0280555555556,65441.9951274
5,19-1042,"Medical Scientists, Except Epidemiologists",24.751685751,40.2370657625,0.6,15,3.28811111111,34555.1766674
4,19-1090,"Miscellaneous Life Scientists",7.49904818225,4.56869311653,-0.285714285714,-2,0.688277777778,75573.846258
5,19-1099,"Life Scientists, All Other",7.49904818225,4.56869311653,-0.285714285714,-2,0.688277777778,75573.846258
3,19-2000,"Physical Scientists",208.553288824,272.492746749,0.301435406699,63,26.1878888889,71106.8765565
4,19-2010,"Astronomers and Physicists",2.3252111004,7.23759317581,2.5,5,0.3935,53478.8299125
5,19-2011,Astronomers,0,2.5371968184,,3,0.093,102259.938394
5,19-2012,Physicists,2.3252111004,4.7003963574,1.5,3,0.300555555556,45100.9504962
4,19-2020,"Atmospheric and Space Scientists",7.83399458276,12.6282601101,0.625,5,0.985111111111,91053.8027084
5,19-2021,"Atmospheric and Space Scientists",7.83399458276,12.6282601101,0.625,5,0.985111111111,91053.8027084
4,19-2030,"Chemists and Materials Scientists",45.0225057532,55.1123726272,0.222222222222,10,5.06966666667,52386.2951979
5,19-2031,Chemists,42.7166432064,45.9124056123,0.0697674418605,3,4.41116666667,45050.1882659
5,19-2032,"Materials Scientists",2.30586254674,9.19996701493,3.5,7,0.6585,172760.453213
4,19-2040,"Environmental Scientists and Geoscientists",94.1258610649,133.406375844,0.414893617021,39,14.0476666667,74982.2422931
5,19-2041,"Environmental Scientists and Specialists, Including Health",45.347289154,61.4905505818,0.355555555556,16,6.49866666667,63014.6035453
5,19-2042,"Geoscientists, Except Hydrologists and Geographers",12.6968542428,25.3707501455,0.923076923077,12,2.69838888889,63133.9482105
5,19-2043,Hydrologists,36.0817176681,46.5450751168,0.305555555556,11,4.85061111111,122091.039305
4,19-2090,"Miscellaneous Physical Scientists",59.2457163228,64.1081449915,0.0847457627119,5,5.69194444444,75363.3116118
5,19-2099,"Physical Scientists, All Other",59.2457163228,64.1081449915,0.0847457627119,5,5.69194444444,75363.3116118
3,19-3000,"Social Scientists and Related Workers",321.279725465,561.092813473,0.747663551402,240,33.0864444444,73976.0452786
4,19-3010,Economists,4.45094506258,9.92687551991,1.5,6,0.668777777778,68919.3402348
5,19-3011,Economists,4.45094506258,9.92687551991,1.5,6,0.668777777778,68919.3402348
4,19-3020,"Survey Researchers",13.2921220978,6.55808394399,-0.461538461538,-6,1.69138888889,31388.1964214
5,19-3022,"Survey Researchers",13.2921220978,6.55808394399,-0.461538461538,-6,1.69138888889,31388.1964214
4,19-3030,Psychologists,269.726323622,493.473159344,0.825925925926,223,25.6246111111,75643.4429179
5,19-3031,"Clinical, Counseling, and School Psychologists",132.102829545,200.450144806,0.515151515152,68,11.4807777778,66412.2555381
5,19-3032,"Industrial-Organizational Psychologists",10.138723614,4.19618493767,-0.6,-6,0.632833333333,86601.241036
5,19-3039,"Psychologists, All Other",127.484770463,288.8268296,1.27559055118,162,13.511,85645.840981
4,19-3040,Sociologists,2.53556708001,2.33722708866,-0.333333333333,-1,0.342833333333,67142.2786238
5,19-3041,Sociologists,2.53556708001,2.33722708866,-0.333333333333,-1,0.342833333333,67142.2786238
4,19-3050,"Urban and Regional Planners",3.26214580934,1.51192110301,-0.333333333333,-1,0.298444444444,51416.1760633
5,19-3051,"Urban and Regional Planners",3.26214580934,1.51192110301,-0.333333333333,-1,0.298444444444,51416.1760633
4,19-3090,"Miscellaneous Social Scientists and Related Workers",28.0126217926,47.2855464729,0.678571428571,19,4.46038888889,70115.7359504
5,19-3091,"Anthropologists and Archaeologists",2.90719181438,4.06494006446,0.333333333333,1,0.422388888889,43816.8410935
5,19-3092,Geographers,0.0674552669603,0.0503292912694,0,0,0.0127222222222,72259.2
5,19-3093,Historians,0.318120106168,12.8661751634,,13,0.782277777778,38871.2704574
5,19-3094,"Political Scientists",2.99808842066,5.28145937799,0.666666666667,2,0.423666666667,101722.943599
5,19-3099,"Social Scientists and Related Workers, All Other",21.7217661845,25.0226425758,0.136363636364,3,2.81938888889,74303.095221
3,19-4000,"Life, Physical, and Social Science Technicians",428.156175931,694.677840299,0.623831775701,267,66.9807222222,39399.6583284
4,19-4010,"Agricultural and Food Science Technicians",171.246261635,287.508109525,0.684210526316,117,26.7053333333,37828.9901209
5,19-4011,"Agricultural and Food Science Technicians",171.246261635,287.508109525,0.684210526316,117,26.7053333333,37828.9901209
4,19-4020,"Biological Technicians",48.0481176805,52.3215585511,0.0833333333333,4,5.40888888889,23173.2997287
5,19-4021,"Biological Technicians",48.0481176805,52.3215585511,0.0833333333333,4,5.40888888889,23173.2997287
4,19-4030,"Chemical Technicians",68.3895977248,140.532447056,1.07352941176,73,12.3716111111,38035.7213242
5,19-4031,"Chemical Technicians",68.3895977248,140.532447056,1.07352941176,73,12.3716111111,38035.7213242
4,19-4040,"Geological and Petroleum Technicians",6.23696177049,9.5917757343,0.666666666667,4,1.11661111111,46671.116814
5,19-4041,"Geological and Petroleum Technicians",6.23696177049,9.5917757343,0.666666666667,4,1.11661111111,46671.116814
4,19-4050,"Nuclear Technicians",4.19115911589,0.154137380505,-1,-4,0.268666666667,71229.6
5,19-4051,"Nuclear Technicians",4.19115911589,0.154137380505,-1,-4,0.268666666667,71229.6
4,19-4060,"Social Science Research Assistants",2.31644123257,5.58364450984,2,4,0.957888888889,37740.1690618
5,19-4061,"Social Science Research Assistants",2.31644123257,5.58364450984,2,4,0.957888888889,37740.1690618
4,19-4090,"Miscellaneous Life, Physical, and Social Science Technicians",127.727636772,198.986167543,0.5546875,71,20.1516666667,49381.8708599
5,19-4091,"Environmental Science and Protection Technicians, Including Health",19.0801067387,16.8550504412,-0.105263157895,-2,2.12927777778,48159.992423
5,19-4092,"Forensic Science Technicians",0.867150174314,3.82236313115,3,3,0.169722222222,45786.0856288
5,19-4093,"Forest and Conservation Technicians",18.2549094929,29.2508066624,0.611111111111,11,3.18877777778,35719.0275349
5,19-4099,"Life, Physical, and Social Science Technicians, All Other",89.5254703663,149.057947308,0.655555555556,59,14.6638888889,52726.3700861
2,21-0000,"Community and Social Service Occupations",1973.82818609,3462.66188351,0.75430597771,1489,305.375277778,40156.1695157
3,21-1000,"Counselors, Social Workers, and Other Community and Social Service Specialists",1139.75371919,2058.09331175,0.805263157895,918,169.005611111,40550.4336798
4,21-1010,Counselors,419.152779992,845.420241776,1.01670644391,426,63.0247222222,50658.2040709
5,21-1012,"Educational, Guidance, School, and Vocational Counselors",221.781056266,478.051732093,1.15315315315,256,34.6743333333,56376.5324251
5,21-1013,"Marriage and Family Therapists",4.41583285891,20.8757592403,4.25,17,1.0695,53339.1676045
5,21-1015,"Rehabilitation Counselors",72.7144716078,70.7807577371,-0.027397260274,-2,7.58661111111,37380.8460821
5,21-1018,"Substance Abuse, Behavioral Disorder, and Mental Health Counselors",88.9065687601,203.28662428,1.2808988764,114,14.6598888889,43907.5783628
5,21-1019,"Counselors, All Other",31.334850499,72.425368426,1.32258064516,41,5.03433333333,47280.3801012
4,21-1020,"Social Workers",369.983304269,552.213856234,0.491891891892,182,47.4496111111,44217.7857731
5,21-1021,"Child, Family, and School Social Workers",130.595493227,213.754853255,0.63358778626,83,17.256,38646.6273075
5,21-1022,"Healthcare Social Workers",69.1909975591,138.200539943,1,69,10.3838888889,48866.9318568
5,21-1023,"Mental Health and Substance Abuse Social Workers",68.8035494451,75.4143955668,0.0869565217391,6,7.40516666667,49493.4911244
5,21-1029,"Social Workers, All Other",101.393264038,124.84406747,0.237623762376,24,12.4045555556,64282.8009206
4,21-1090,"Miscellaneous Community and Social Service Specialists",350.617634924,660.459213735,0.880341880342,309,58.5312777778,31947.8717532
5,21-1091,"Health Educators ",18.4961173062,25.246001246,0.388888888889,7,2.64894444444,45967.5777944
5,21-1092,"Probation Officers and Correctional Treatment Specialists",75.5211068719,91.2421388344,0.197368421053,15,7.40327777778,36893.4914984
5,21-1093,"Social and Human Service Assistants",221.100068601,449.955298017,1.03619909502,229,41.0161666667,26631.518086
5,21-1094,"Community Health Workers",15.7486236246,55.0563368503,2.4375,39,3.78216666667,40212.1333016
5,21-1099,"Community and Social Service Specialists, All Other",19.7517185205,38.9594387868,0.95,19,3.68072222222,35371.1038305
3,21-2000,"Religious Workers",834.074466904,1404.56857176,0.684652278177,571,136.369666667,39382.2539063
4,21-2010,Clergy,494.011276806,873.332383724,0.767206477733,379,81.1418333333,41778.4794467
5,21-2011,Clergy,494.011276806,873.332383724,0.767206477733,379,81.1418333333,41778.4794467
4,21-2020,"Directors, Religious Activities and Education",237.110429422,347.696421492,0.46835443038,111,35.4113888889,39898.5261538
5,21-2021,"Directors, Religious Activities and Education",237.110429422,347.696421492,0.46835443038,111,35.4113888889,39898.5261538
4,21-2090,"Miscellaneous Religious Workers",102.952760675,183.539766549,0.78640776699,81,19.8164444444,28391.4960528
5,21-2099,"Religious Workers, All Other",102.952760675,183.539766549,0.78640776699,81,19.8164444444,28391.4960528
2,23-0000,"Legal Occupations",1037.70453408,1865.29247857,0.796724470135,827,95.6906666667,60848.3030823
3,23-1000,"Lawyers, Judges, and Related Workers",701.393313097,1204.08447195,0.71754636234,503,44.2507222222,83073.1852702
4,23-1010,"Lawyers and Judicial Law Clerks",640.363410315,1118.74509751,0.7484375,479,40.6782777778,83940.4755112
5,23-1011,Lawyers,620.918421178,1110.61880453,0.789049919485,490,40.1171111111,84278.4034081
5,23-1012,"Judicial Law Clerks",19.4449891369,8.12629298492,-0.578947368421,-11,0.561166666667,63468.3731291
4,23-1020,"Judges, Magistrates, and Other Judicial Workers",61.0299027824,85.339374437,0.393442622951,24,3.57244444444,55612.9600085
5,23-1021,"Administrative Law Judges, Adjudicators, and Hearing Officers",34.5247406143,44.68646469,0.285714285714,10,1.91461111111,41092.8005729
5,23-1022,"Arbitrators, Mediators, and Conciliators",3.6138875502,9.26178424283,1.25,5,0.307833333333,43817.0915672
5,23-1023,"Judges, Magistrate Judges, and Magistrates",22.8912746179,31.3911255042,0.347826086957,8,1.35,165672.662878
3,23-2000,"Legal Support Workers",336.311220987,661.208006621,0.967261904762,325,51.4398888889,37300.6788288
4,23-2010,"Paralegals and Legal Assistants",215.103865156,525.822584949,1.44651162791,311,38.7503333333,36809.3154388
5,23-2011,"Paralegals and Legal Assistants",215.103865156,525.822584949,1.44651162791,311,38.7503333333,36809.3154388
4,23-2090,"Miscellaneous Legal Support Workers",121.207355832,135.385421672,0.115702479339,14,12.6895555556,41298.1653873
5,23-2091,"Court Reporters",18.4575075694,19.6251273503,0.111111111111,2,2.05694444444,42893.2694
5,23-2093,"Title Examiners, Abstractors, and Searchers",79.05713828,89.6954340523,0.139240506329,11,8.24716666667,39931.9918702
5,23-2099,"Legal Support Workers, All Other",23.6927099822,26.0648602698,0.0833333333333,2,2.38544444444,46383.2340621
2,25-0000,"Education, Training, and Library Occupations",10055.6417825,17238.4960676,0.714200477327,7182,1195.197,44644.2659265
3,25-1000,"Postsecondary Teachers",2328.92199053,3861.73555541,0.658222413053,1533,237.926888889,39231.5505
4,25-1090,"Postsecondary Teachers",2328.92199053,3861.73555541,0.658222413053,1533,237.926888889,39231.5505
5,25-1099,"Postsecondary Teachers",2328.92199053,3861.73555541,0.658222413053,1533,237.926888889,39231.5505
3,25-2000,"Preschool, Primary, Secondary, and Special Education School Teachers",4975.4352919,7973.26970718,0.602613065327,2998,516.016277778,52871.2715567
4,25-2010,"Preschool and Kindergarten Teachers",744.551177883,1408.51452529,0.891275167785,664,108.728833333,29058.7194837
5,25-2011,"Preschool Teachers, Except Special Education",548.644543911,1169.03438558,1.12932604736,620,83.9711666667,25237.5157158
5,25-2012,"Kindergarten Teachers, Except Special Education",195.906633972,239.480139703,0.219387755102,43,24.7576666667,48165.9410391
4,25-2020,"Elementary and Middle School Teachers",2269.32260221,3344.30110943,0.473776994271,1075,216.393777778,53288.9354999
5,25-2021,"Elementary School Teachers, Except Special Education",1619.22810637,2394.09947477,0.478690549722,775,153.485277778,54341.2546054
5,25-2022,"Middle School Teachers, Except Special and Career/Technical Education",635.638950989,932.259712738,0.465408805031,296,61.5306111111,50367.7156666
5,25-2023,"Career/Technical Education Teachers, Middle School",14.4555448538,17.9419219177,0.285714285714,4,1.37788888889,83391.923377
4,25-2030,"Secondary School Teachers",1549.6067799,2585.32323493,0.667741935484,1035,148.853444444,57195.9448316
5,25-2031,"Secondary School Teachers, Except Special and Career/Technical Education",1365.6803804,2332.97836433,0.707906295754,967,132.045,57140.8589643
5,25-2032,"Career/Technical Education Teachers, Secondary School",183.926399506,252.344870603,0.369565217391,68,16.8085,57699.0324146
4,25-2050,"Special Education Teachers",411.954731901,635.130837542,0.541262135922,223,42.0401666667,56813.2860516
5,25-2051,"Special Education Teachers, Preschool",32.6614984609,64.1622152355,0.939393939394,31,3.90744444444,65917.397087
5,25-2052,"Special Education Teachers, Kindergarten and Elementary School",135.831803089,186.140046969,0.367647058824,50,13.2603333333,57053.3775362
5,25-2053,"Special Education Teachers, Middle School",86.0543869762,103.535966289,0.209302325581,18,7.55561111111,50592.0296778
5,25-2054,"Special Education Teachers, Secondary School",144.760380409,240.677694525,0.662068965517,96,14.9210555556,58947.6191507
5,25-2059,"Special Education Teachers, All Other",12.6466629659,40.6149145223,2.15384615385,28,2.39572222222,25107.6881073
3,25-3000,"Other Teachers and Instructors",785.39752998,2305.28028806,1.93630573248,1520,171.181111111,32214.3902648
4,25-3010,"Adult Basic and Secondary Education and Literacy Teachers and Instructors ",155.18290335,187.266890415,0.206451612903,32,20.8021666667,36615.8763609
5,25-3011,"Adult Basic and Secondary Education and Literacy Teachers and Instructors ",155.18290335,187.266890415,0.206451612903,32,20.8021666667,36615.8763609
4,25-3020,"Self-Enrichment Education Teachers",292.801575635,1111.95914302,2.795221843,819,72.0343888889,35654.7014947
5,25-3021,"Self-Enrichment Education Teachers",292.801575635,1111.95914302,2.795221843,819,72.0343888889,35654.7014947
4,25-3090,"Miscellaneous Teachers and Instructors",337.413050994,1006.05425463,1.98516320475,669,78.3445555556,26287.5859014
5,25-3097,"Teachers and Instructors, All Other",195.621307436,619.456400428,2.15816326531,423,44.2518888889,38336.8128763
5,25-3098,"Substitute Teachers",141.791743559,386.597854197,1.72535211268,245,34.0926111111,23186.4666519
3,25-4000,"Librarians, Curators, and Archivists",409.446194636,515.206459287,0.259168704156,106,57.7749444444,41873.4936788
4,25-4010,"Archivists, Curators, and Museum Technicians",13.5803667187,65.6281336578,3.71428571429,52,3.33227777778,43459.0860161
5,25-4011,Archivists,5.61105305903,10.1619838413,0.666666666667,4,0.715055555556,47520.5553372
5,25-4012,Curators,6.07258806919,34.2185728204,4.66666666667,28,1.71111111111,47694.0103941
5,25-4013,"Museum Technicians and Conservators",1.89672559045,21.2475769962,9.5,19,0.906055555556,39274.1221455
4,25-4020,Librarians,208.835447941,252.19178597,0.205741626794,43,24.2882222222,58154.4838269
5,25-4021,Librarians,208.835447941,252.19178597,0.205741626794,43,24.2882222222,58154.4838269
4,25-4030,"Library Technicians",187.030379976,197.386539659,0.0534759358289,10,30.1544444444,27145.282543
5,25-4031,"Library Technicians",187.030379976,197.386539659,0.0534759358289,10,30.1544444444,27145.282543
3,25-9000,"Other Education, Training, and Library Occupations",1556.44077545,2583.00405764,0.660025706941,1027,212.297833333,20178.6083768
4,25-9010,"Audio-Visual and Multimedia Collections Specialists",4.97491732795,8.95701481426,0.8,4,0.634277777778,44860.209529
5,25-9011,"Audio-Visual and Multimedia Collections Specialists",4.97491732795,8.95701481426,0.8,4,0.634277777778,44860.209529
4,25-9020,"Farm and Home Management Advisors",7.03717714937,3.63522528772,-0.428571428571,-3,0.853333333333,53600.9152473
5,25-9021,"Farm and Home Management Advisors",7.03717714937,3.63522528772,-0.428571428571,-3,0.853333333333,53600.9152473
4,25-9030,"Instructional Coordinators",125.862534801,305.002183119,1.42063492063,179,19.4853333333,66180.5748365
5,25-9031,"Instructional Coordinators",125.862534801,305.002183119,1.42063492063,179,19.4853333333,66180.5748365
4,25-9040,"Teacher Assistants",1389.47277173,2223.94802111,0.601151907847,835,187.388222222,19678.4847079
5,25-9041,"Teacher Assistants",1389.47277173,2223.94802111,0.601151907847,835,187.388222222,19678.4847079
4,25-9090,"Miscellaneous Education, Training, and Library Workers",29.0933744364,41.4616133141,0.413793103448,12,3.93661111111,33085.5159772
5,25-9099,"Education, Training, and Library Workers, All Other",29.0933744364,41.4616133141,0.413793103448,12,3.93661111111,33085.5159772
2,27-0000,"Arts, Design, Entertainment, Sports, and Media Occupations",3985.90976263,8821.06168023,1.21299548419,4835,655.061055556,37013.6342676
3,27-1000,"Art and Design Workers",1181.66557292,2891.62564344,1.44670050761,1710,190.075111111,36465.5444691
4,27-1010,"Artists and Related Workers",419.517442425,886.134864974,1.10952380952,466,63.6518333333,18819.7979828
5,27-1011,"Art Directors",96.601837439,280.990675258,1.89690721649,184,16.5618333333,31457.4555226
5,27-1012,"Craft Artists",40.7460779377,129.783893409,2.17073170732,89,9.21677777778,8880.6225669
5,27-1013,"Fine Artists, Including Painters, Sculptors, and Illustrators",124.916559168,284.957933517,1.28,160,19.0333333333,14942.0941191
5,27-1014,"Multimedia Artists and Animators",74.1317221782,157.24833478,1.12162162162,83,13.0494444444,26725.6360394
5,27-1019,"Artists and Related Workers, All Other",83.1212457028,33.1540280095,-0.602409638554,-50,5.79044444444,16967.8934161
4,27-1020,Designers,762.148130499,2005.49077846,1.6312335958,1243,126.423222222,42002.8732127
5,27-1021,"Commercial and Industrial Designers",30.0308322065,58.5083308431,0.966666666667,29,5.11633333333,80346.727367
5,27-1022,"Fashion Designers",15.4833185519,43.5236121601,1.93333333333,29,2.88016666667,54208.7187324
5,27-1023,"Floral Designers",68.7020267706,82.9987717725,0.202898550725,14,7.45333333333,25928.0310251
5,27-1024,"Graphic Designers",279.668574443,658.782115669,1.35357142857,379,46.9583888889,38972.4825992
5,27-1025,"Interior Designers",101.933560795,213.35428937,1.08823529412,111,15.2831666667,80211.8068903
5,27-1026,"Merchandise Displayers and Window Trimmers",136.983261823,662.521818615,3.83941605839,526,28.3947777778,42719.5960289
5,27-1027,"Set and Exhibit Designers",33.2003350374,104.798274932,2.18181818182,72,6.25966666667,33005.4539619
5,27-1029,"Designers, All Other",96.1462208713,181.003565101,0.885416666667,85,14.0774444444,37769.1594625
3,27-2000,"Entertainers and Performers, Sports and Related Workers",839.832181197,2108.58650388,1.51071428571,1269,179.396388889,39625.1436007
4,27-2010,"Actors, Producers, and Directors",119.069461298,417.585932455,2.51260504202,299,25.7803888889,57800.6069315
5,27-2011,Actors,60.8633919889,175.937121579,1.88524590164,115,12.8722222222,52866.6076991
5,27-2012,"Producers and Directors",58.2060693088,241.648810876,3.1724137931,184,12.9081666667,61759.4758717
4,27-2020,"Athletes, Coaches, Umpires, and Related Workers",189.8544307,573.655552789,2.02105263158,384,54.9242222222,30820.3052716
5,27-2021,"Athletes and Sports Competitors",11.6832433275,35.7097888412,2,24,3.13422222222,32491.6390073
5,27-2022,"Coaches and Scouts",163.320399912,473.180954785,1.9018404908,310,46.5700555556,30461.8847132
5,27-2023,"Umpires, Referees, and Other Sports Officials",14.8507874606,64.764809163,3.33333333333,50,5.21994444444,34030.0714624
4,27-2030,"Dancers and Choreographers",49.7552980426,38.4410862748,-0.24,-12,7.20616666667,28399.6547031
5,27-2031,Dancers,29.2498339755,25.1668631275,-0.137931034483,-4,4.53544444444,25535.5152956
5,27-2032,Choreographers,20.5054640671,13.2742231473,-0.380952380952,-8,2.67077777778,34318.7039505
4,27-2040,"Musicians, Singers, and Related Workers",386.799143333,866.505057609,1.24031007752,480,77.1711666667,39733.9504906
5,27-2041,"Music Directors and Composers",92.3188899344,197.779217669,1.15217391304,106,20.3903888889,45198.6858749
5,27-2042,"Musicians and Singers",294.480253399,668.72583994,1.27551020408,375,56.7807777778,38197.5216882
4,27-2090,"Miscellaneous Entertainers and Performers, Sports and Related Workers",94.353847823,212.398874756,1.25531914894,118,14.3144444444,31236.2466128
5,27-2099,"Entertainers and Performers, Sports and Related Workers, All Other",94.353847823,212.398874756,1.25531914894,118,14.3144444444,31236.2466128
3,27-3000,"Media and Communication Workers",1152.35474633,2262.32720224,0.963541666667,1110,170.948111111,39963.2804265
4,27-3010,Announcers,186.879567534,200.307313137,0.0695187165775,13,22.8296666667,24293.3969701
5,27-3011,"Radio and Television Announcers",85.9233703261,62.1565301365,-0.279069767442,-24,8.97305555556,30328.5445692
5,27-3012,"Public Address System and Other Announcers",100.956197208,138.150783001,0.366336633663,37,13.8566111111,19214.4443511
4,27-3020,"News Analysts, Reporters and Correspondents",131.780232086,139.441096806,0.0530303030303,7,17.1748888889,32082.535189
5,27-3021,"Broadcast News Analysts",37.1344679176,39.3572916988,0.0540540540541,2,4.10911111111,65063.606539
5,27-3022,"Reporters and Correspondents",94.6457641679,100.083805108,0.0526315789474,5,13.0657777778,28042.7883185
4,27-3030,"Public Relations Specialists",184.676787403,401.224420849,1.16756756757,216,30.248,61144.011054
5,27-3031,"Public Relations Specialists",184.676787403,401.224420849,1.16756756757,216,30.248,61144.011054
4,27-3040,"Writers and Editors",411.575490792,975.717767483,1.36893203883,564,66.304,35569.0316886
5,27-3041,Editors,117.026826834,231.587780778,0.982905982906,115,18.4966666667,36649.1510007
5,27-3042,"Technical Writers",33.660345206,55.4345035904,0.617647058824,21,4.49755555556,72302.2435915
5,27-3043,"Writers and Authors",260.888318751,688.695483115,1.6398467433,428,43.3097777778,32691.4881038
4,27-3090,"Miscellaneous Media and Communication Workers",237.442668519,545.636603964,1.30379746835,309,34.3915555556,39466.7284491
5,27-3091,"Interpreters and Translators",120.341622949,348.364531965,1.9,228,20.2221666667,36694.6668958
5,27-3099,"Media and Communication Workers, All Other",117.101045569,197.272071998,0.683760683761,80,14.1693888889,45237.5834469
3,27-4000,"Media and Communication Equipment Workers",812.057262181,1558.52233067,0.919950738916,747,114.6415,32873.0993925
4,27-4010,"Broadcast and Sound Engineering Technicians and Radio Operators",132.238478522,269.667292887,1.04545454545,138,20.3250555556,36271.9707781
5,27-4011,"Audio and Video Equipment Technicians",46.655265821,149.518990202,2.1914893617,103,9.59516666667,35845.4665443
5,27-4012,"Broadcast Technicians",53.7883035416,78.7963623645,0.462962962963,25,6.7305,32471.5763408
5,27-4013,"Radio Operators",0.755353966863,0.41221149897,-1,-1,0.0760555555556,38001.6
5,27-4014,"Sound Engineering Technicians",31.0395551926,40.9397288216,0.322580645161,10,3.92338888889,50128.5761577
4,27-4020,Photographers,625.326756575,1161.53699266,0.8592,537,86.5883333333,29192.8878104
5,27-4021,Photographers,625.326756575,1161.53699266,0.8592,537,86.5883333333,29192.8878104
4,27-4030,"Television, Video, and Motion Picture Camera Operators and Editors",38.47703177,112.170867826,1.94736842105,74,6.14361111111,50479.4886627
5,27-4031,"Camera Operators, Television, Video, and Motion Picture",31.5840034255,64.784482338,1.03125,33,4.00572222222,56576.1570241
5,27-4032,"Film and Video Editors",6.89302834449,47.3863854882,5.71428571429,40,2.13783333333,40828.4694362
4,27-4090,"Miscellaneous Media and Communication Equipment Workers",16.0149953131,15.1471772934,-0.0625,-1,1.5845,69709.2411237
5,27-4099,"Media and Communication Equipment Workers, All Other",16.0149953131,15.1471772934,-0.0625,-1,1.5845,69709.2411237
2,29-0000,"Healthcare Practitioners and Technical Occupations",6656.31026565,13030.6254685,0.957782451923,6375,532.078666667,57486.0710436
3,29-1000,"Health Diagnosing and Treating Practitioners",3905.82951276,7833.91256861,1.00563236047,3928,263.116833333,72063.6174246
4,29-1010,Chiropractors,64.185143953,135.978424594,1.125,72,2.64555555556,79709.2776389
5,29-1011,Chiropractors,64.185143953,135.978424594,1.125,72,2.64555555556,79709.2776389
4,29-1020,Dentists,92.9382029349,197.406795848,1.11827956989,104,4.31305555556,136900.540346
5,29-1021,"Dentists, General",79.5798563147,174.78715918,1.1875,95,3.70561111111,132079.221932
5,29-1022,"Oral and Maxillofacial Surgeons",6.7282883728,9.34383862659,0.285714285714,2,0.259277777778,209877.220906
5,29-1023,Orthodontists,5.69996262922,11.2607844797,0.833333333333,5,0.28,207161.458696
5,29-1024,Prosthodontists,0.0508439478449,0.175155994146,0,0,0.00555555555556,190132.378814
5,29-1029,"Dentists, All Other Specialists",0.879251670336,1.83985756836,1,1,0.0626666666667,152045.451907
4,29-1030,"Dietitians and Nutritionists",49.4431140231,120.137173704,1.44897959184,71,5.17905555556,61001.7805045
5,29-1031,"Dietitians and Nutritionists",49.4431140231,120.137173704,1.44897959184,71,5.17905555556,61001.7805045
4,29-1040,Optometrists,53.825998999,121.228459044,1.24074074074,67,2.61116666667,86823.7141999
5,29-1041,Optometrists,53.825998999,121.228459044,1.24074074074,67,2.61116666667,86823.7141999
4,29-1050,Pharmacists,172.200392168,416.851077359,1.42441860465,245,13.4771111111,127712.453289
5,29-1051,Pharmacists,172.200392168,416.851077359,1.42441860465,245,13.4771111111,127712.453289
4,29-1060,"Physicians and Surgeons",568.763258591,1069.06582178,0.878734622144,500,23.0685555556,184404.372517
5,29-1061,Anesthesiologists,24.1620774685,18.0885198588,-0.25,-6,0.6755,227811.349198
5,29-1062,"Family and General Practitioners",171.968110508,207.957418149,0.209302325581,36,5.28172222222,184871.188903
5,29-1063,"Internists, General",47.056136416,25.1475574294,-0.468085106383,-22,1.1515,199556.900777
5,29-1064,"Obstetricians and Gynecologists",21.1758743029,9.05700069649,-0.571428571429,-12,0.446888888889,201497.886278
5,29-1065,"Pediatricians, General",22.0607770266,13.4765178971,-0.409090909091,-9,0.557444444444,156769.671087
5,29-1066,Psychiatrists,34.5257260348,61.0637473206,0.742857142857,26,1.26288888889,207135.232937
5,29-1067,Surgeons,68.9763209229,53.6243640854,-0.217391304348,-15,1.75083333333,491162.224981
5,29-1069,"Physicians and Surgeons, All Other",178.838235911,680.650696346,2.80446927374,502,11.9417777778,174780.342588
4,29-1070,"Physician Assistants",40.3000723747,101.925000133,1.55,62,3.67755555556,66847.9877463
5,29-1071,"Physician Assistants",40.3000723747,101.925000133,1.55,62,3.67755555556,66847.9877463
4,29-1080,Podiatrists,20.3525232831,15.0279320753,-0.25,-5,0.925777777778,133106.798705
5,29-1081,Podiatrists,20.3525232831,15.0279320753,-0.25,-5,0.925777777778,133106.798705
4,29-1120,Therapists,574.168910086,1368.84124487,1.3850174216,795,47.0281111111,77309.2949099
5,29-1122,"Occupational Therapists",100.376159174,217.765464992,1.18,118,8.61883333333,96082.8219451
5,29-1123,"Physical Therapists",159.773548214,340.451836919,1.125,180,10.3263888889,83434.1458413
5,29-1124,"Radiation Therapists",19.3655097657,52.1556869721,1.73684210526,33,1.56972222222,79397.3752741
5,29-1125,"Recreational Therapists",10.5170829139,15.2432777306,0.363636363636,4,0.637666666667,47225.0388658
5,29-1126,"Respiratory Therapists",74.4094556417,134.033865525,0.810810810811,60,5.29411111111,48549.4961826
5,29-1127,"Speech-Language Pathologists",161.927621281,458.396684343,1.82716049383,296,15.9213333333,89531.8502632
5,29-1128,"Exercise Physiologists",7.54902696868,28.4549782988,2.5,20,0.763166666667,47788.0860209
5,29-1129,"Therapists, All Other",40.2505061269,122.339450092,2.05,82,3.89688888889,43874.6656888
4,29-1130,Veterinarians,79.3663331331,167.686199942,1.12658227848,89,4.67477777778,105513.649153
5,29-1131,Veterinarians,79.3663331331,167.686199942,1.12658227848,89,4.67477777778,105513.649153
4,29-1140,"Registered Nurses",2019.84999213,3647.8838464,0.805940594059,1628,142.464777778,59269.9498204
5,29-1141,"Registered Nurses",2019.84999213,3647.8838464,0.805940594059,1628,142.464777778,59269.9498204
4,29-1150,"Nurse Anesthetists",7.9730293578,5.14971484348,-0.375,-3,0.329055555556,222329.968689
5,29-1151,"Nurse Anesthetists",7.9730293578,5.14971484348,-0.375,-3,0.329055555556,222329.968689
4,29-1160,"Nurse Midwives",2.46759045291,2.33327825174,0,0,0.148888888889,91581.8714275
5,29-1161,"Nurse Midwives",2.46759045291,2.33327825174,0,0,0.148888888889,91581.8714275
4,29-1170,"Nurse Practitioners",82.6066401294,306.103307495,2.68674698795,223,7.82088888889,103013.411069
5,29-1171,"Nurse Practitioners",82.6066401294,306.103307495,2.68674698795,223,7.82088888889,103013.411069
4,29-1180,Audiologists,15.1062233428,37.6437587871,1.53333333333,23,1.06505555556,73938.7084052
5,29-1181,Audiologists,15.1062233428,37.6437587871,1.53333333333,23,1.06505555556,73938.7084052
4,29-1190,"Miscellaneous Health Diagnosing and Treating Practitioners",62.2820878018,120.65053348,0.951612903226,59,3.6875,50860.4112143
5,29-1199,"Health Diagnosing and Treating Practitioners, All Other",62.2820878018,120.65053348,0.951612903226,59,3.6875,50860.4112143
3,29-2000,"Health Technologists and Technicians",2687.0032424,4869.55142754,0.812430219576,2183,260.410277778,40973.8866506
4,29-2010,"Clinical Laboratory Technologists and Technicians",275.144752876,356.690881553,0.298181818182,82,22.0678333333,47894.3979667
5,29-2018,"Clinical Laboratory Technologists and Technicians",275.144752876,356.690881553,0.298181818182,82,22.0678333333,47894.3979667
4,29-2020,"Dental Hygienists",145.976522312,300.695524177,1.06164383562,155,14.3532222222,72917.3329083
5,29-2021,"Dental Hygienists",145.976522312,300.695524177,1.06164383562,155,14.3532222222,72917.3329083
4,29-2030,"Diagnostic Related Technologists and Technicians",345.250583,684.527819187,0.985507246377,340,27.0268333333,57443.77895
5,29-2031,"Cardiovascular Technologists and Technicians",61.0561284631,118.136554343,0.934426229508,57,4.44383333333,47583.1266644
5,29-2032,"Diagnostic Medical Sonographers",23.7929043483,52.2495151014,1.16666666667,28,1.91411111111,67946.7568806
5,29-2033,"Nuclear Medicine Technologists",52.0613160494,90.5532569434,0.75,39,4.03688888889,76428.8780058
5,29-2034,"Radiologic Technologists ",190.080183467,376.738352795,0.984210526316,187,14.9881666667,54646.2439972
5,29-2035,"Magnetic Resonance Imaging Technologists",18.2600506724,46.8501400044,1.61111111111,29,1.64388888889,61058.1639846
4,29-2040,"Emergency Medical Technicians and Paramedics",204.863393976,283.567144302,0.385365853659,79,14.8131666667,28939.0851767
5,29-2041,"Emergency Medical Technicians and Paramedics",204.863393976,283.567144302,0.385365853659,79,14.8131666667,28939.0851767
4,29-2050,"Health Practitioner Support Technologists and Technicians",478.404340446,1190.02389958,1.48953974895,712,65.5768333333,29095.9174329
5,29-2051,"Dietetic Technicians",11.3182073097,20.1188106066,0.818181818182,9,1.21527777778,26677.6774347
5,29-2052,"Pharmacy Technicians",195.305359313,631.619399532,2.24102564103,437,32.8831666667,29412.4706047
5,29-2053,"Psychiatric Technicians",64.1715821086,216.486747817,2.375,152,11.2327777778,23259.8976319
5,29-2054,"Respiratory Therapy Technicians",56.5618678252,18.1111247451,-0.684210526316,-39,2.67705555556,98464.6451113
5,29-2055,"Surgical Technologists",86.4275846206,157.559054269,0.837209302326,72,9.0835,42413.7116643
5,29-2056,"Veterinary Technologists and Technicians",59.6108342296,143.161389125,1.38333333333,83,8.0875,29614.6731517
5,29-2057,"Ophthalmic Medical Technicians",5.00890503935,2.96737348612,-0.4,-2,0.397555555556,32226.2883433
4,29-2060,"Licensed Practical and Licensed Vocational Nurses",946.805351932,1369.90054165,0.446673706441,423,86.4291111111,44538.3707443
5,29-2061,"Licensed Practical and Licensed Vocational Nurses",946.805351932,1369.90054165,0.446673706441,423,86.4291111111,44538.3707443
4,29-2070,"Medical Records and Health Information Technicians",140.207695118,343.043844452,1.45,203,13.6567777778,31747.4647902
5,29-2071,"Medical Records and Health Information Technicians",140.207695118,343.043844452,1.45,203,13.6567777778,31747.4647902
4,29-2080,"Opticians, Dispensing",97.1155342127,134.766710989,0.39175257732,38,8.717,37012.3651904
5,29-2081,"Opticians, Dispensing",97.1155342127,134.766710989,0.39175257732,38,8.717,37012.3651904
4,29-2090,"Miscellaneous Health Technologists and Technicians",53.2350685281,206.335061646,2.88679245283,153,7.7695,39201.2294737
5,29-2091,"Orthotists and Prosthetists",12.4225713412,33.0242542725,1.75,21,1.521,61005.4348191
5,29-2092,"Hearing Aid Specialists",2.82153597065,5.76240252395,1,3,0.2965,38064.5793838
5,29-2099,"Health Technologists and Technicians, All Other",37.9909612162,167.54840485,3.42105263158,130,5.95194444444,36431.0075407
3,29-9000,"Other Healthcare Practitioners and Technical Occupations",63.4775104947,327.161472315,4.19047619048,264,8.55155555556,46742.8682237
4,29-9010,"Occupational Health and Safety Specialists and Technicians",27.087821305,221.843107935,7.22222222222,195,5.26016666667,52697.9665689
5,29-9011,"Occupational Health and Safety Specialists",13.2924824418,132.956988556,9.23076923077,120,3.15677777778,79817.4861339
5,29-9012,"Occupational Health and Safety Technicians",13.7953388633,88.8861193795,5.35714285714,75,2.10344444444,37301.5200738
4,29-9090,"Miscellaneous Health Practitioners and Technical Workers",36.3896891897,105.31836438,1.91666666667,69,3.29138888889,38977.2154178
5,29-9091,"Athletic Trainers",23.1624686526,79.907594017,2.47826086957,57,2.27594444444,37829.3120741
5,29-9092,"Genetic Counselors",0,1.49489242939,,1,0.0106111111111,72216.8460812
5,29-9099,"Healthcare Practitioners and Technical Workers, All Other",13.227220537,23.9158779338,0.846153846154,11,1.00483333333,42869.5801396
2,31-0000,"Healthcare Support Occupations",3509.62664161,6234.53362881,0.776353276353,2725,571.104666667,26529.2259995
3,31-1000,"Nursing, Psychiatric, and Home Health Aides",2159.94386453,3301.55713052,0.528703703704,1142,326.209555556,24481.899917
4,31-1010,"Nursing, Psychiatric, and Home Health Aides",2159.94386453,3301.55713052,0.528703703704,1142,326.209555556,24481.899917
5,31-1011,"Home Health Aides",432.635967277,612.336028018,0.413394919169,179,76.6034444444,23057.8262438
5,31-1013,"Psychiatric Aides",18.0184792602,52.0201444809,1.88888888889,34,5.62277777778,23486.8878568
5,31-1014,"Nursing Assistants",1664.51610188,2585.13917286,0.552552552553,920,238.393222222,24907.1738975
5,31-1015,Orderlies,44.7733161036,52.0617851606,0.155555555556,7,5.59011111111,23915.3254783
3,31-2000,"Occupational Therapy and Physical Therapist Assistants and Aides",106.446344766,244.045306698,1.30188679245,138,19.7746666667,56736.5082767
4,31-2010,"Occupational Therapy Assistants and Aides",14.4276514629,55.2320900029,2.92857142857,41,3.41088888889,66287.7704811
5,31-2011,"Occupational Therapy Assistants",14.361675871,52.6554046239,2.78571428571,39,3.17122222222,67133.6490545
5,31-2012,"Occupational Therapy Aides",0.0659755918525,2.57668537897,,3,0.239666666667,26613.5632083
4,31-2020,"Physical Therapist Assistants and Aides",92.0186933031,188.813216695,1.05434782609,97,16.3637222222,53398.615109
5,31-2021,"Physical Therapist Assistants",57.2485864827,133.478959214,1.33333333333,76,10.4947222222,60910.9022181
5,31-2022,"Physical Therapist Aides",34.7701068204,55.3342574807,0.571428571429,20,5.86905555556,33703.1727349
3,31-9000,"Other Healthcare Support Occupations",1243.23643231,2688.93119159,1.16331456154,1446,225.120444444,30440.6153789
4,31-9010,"Massage Therapists",120.042542866,409.25368982,2.40833333333,289,29.8328888889,35449.7178016
5,31-9011,"Massage Therapists",120.042542866,409.25368982,2.40833333333,289,29.8328888889,35449.7178016
4,31-9090,"Miscellaneous Healthcare Support Occupations",1123.19388945,2279.67750177,1.0302760463,1157,195.287555556,30155.9275655
5,31-9091,"Dental Assistants",317.081580932,666.200190046,1.10094637224,349,52.5161111111,33945.8561099
5,31-9092,"Medical Assistants",339.49321406,888.119946812,1.61946902655,549,65.3238333333,28849.6221758
5,31-9093,"Medical Equipment Preparers",17.8363463465,17.0005790343,-0.0555555555556,-1,2.14133333333,36017.7316221
5,31-9094,"Medical Transcriptionists",92.9850671818,118.842516271,0.279569892473,26,14.7481666667,29351.1740077
5,31-9095,"Pharmacy Aides",19.1793629716,5.39412413743,-0.736842105263,-14,1.48505555556,22530.2472491
5,31-9096,"Veterinary Assistants and Laboratory Animal Caretakers",154.077537387,348.996866961,1.26623376623,195,36.4356111111,25946.9940107
5,31-9097,Phlebotomists,84.062536664,134.275750248,0.595238095238,50,10.7901111111,28584.0393244
5,31-9099,"Healthcare Support Workers, All Other",98.4782439042,100.847528259,0.030612244898,3,11.8472777778,66048.2736756
2,33-0000,"Protective Service Occupations",2714.66379293,4243.01080196,0.562799263352,1528,319.488166667,36369.8441809
3,33-1000,"Supervisors of Protective Service Workers",393.572438251,767.567614044,0.94923857868,374,39.0409444444,53765.6003241
4,33-1010,"First-Line Supervisors of Law Enforcement Workers",198.969191202,373.299139666,0.874371859296,174,18.014,55019.6575072
5,33-1011,"First-Line Supervisors of Correctional Officers",65.1982779201,96.3304610022,0.476923076923,31,6.18116666667,49493.0856383
5,33-1012,"First-Line Supervisors of Police and Detectives",133.770913282,276.968678664,1.0671641791,143,11.8328333333,57595.1154588
4,33-1020,"First-Line Supervisors of Fire Fighting and Prevention Workers",93.1797635446,195.00581938,1.09677419355,102,8.48533333333,51183.0423034
5,33-1021,"First-Line Supervisors of Fire Fighting and Prevention Workers",93.1797635446,195.00581938,1.09677419355,102,8.48533333333,51183.0423034
4,33-1090,"Miscellaneous First-Line Supervisors, Protective Service Workers",101.423483504,199.262654998,0.970297029703,98,12.5415555556,53579.400292
5,33-1099,"First-Line Supervisors of Protective Service Workers, All Other",101.423483504,199.262654998,0.970297029703,98,12.5415555556,53579.400292
3,33-2000,"Fire Fighting and Prevention Workers",304.283879453,526.819864255,0.733552631579,223,28.4807222222,32939.5785904
4,33-2010,Firefighters,296.440872173,517.145186691,0.746621621622,221,27.7053888889,32821.8584389
5,33-2011,Firefighters,296.440872173,517.145186691,0.746621621622,221,27.7053888889,32821.8584389
4,33-2020,"Fire Inspectors",7.84300728019,9.67467756439,0.25,2,0.775333333333,49108.2717936
5,33-2021,"Fire Inspectors and Investigators",7.79465749112,9.62537431096,0.25,2,0.772444444444,49224.270482
5,33-2022,"Forest Fire Inspectors and Prevention Specialists",0.0483497890742,0.0493032534227,0,0,0.00294444444444,30906.72
3,33-3000,"Law Enforcement Workers",1076.85226691,1550.15523062,0.439182915506,473,102.054777778,37098.0126128
4,33-3010,"Bailiffs, Correctional Officers, and Jailers",369.146656802,473.662464805,0.284552845528,105,40.1989444444,33164.9542621
5,33-3011,Bailiffs,2.22632316301,0.104588094874,-1,-2,0.0866666666667,36120.269138
5,33-3012,"Correctional Officers and Jailers",366.920333639,473.55787671,0.291553133515,107,40.1122777778,33164.6118268
4,33-3020,"Detectives and Criminal Investigators",50.729527793,88.3971258767,0.725490196078,37,5.08627777778,47018.2330806
5,33-3021,"Detectives and Criminal Investigators",50.729527793,88.3971258767,0.725490196078,37,5.08627777778,47018.2330806
4,33-3030,"Fish and Game Wardens",0,0,0,0,0,0
5,33-3031,"Fish and Game Wardens",0,0,0,0,0,0
4,33-3040,"Parking Enforcement Workers",9.1801375278,12.1648761678,0.333333333333,3,0.7955,36498.4494366
5,33-3041,"Parking Enforcement Workers",9.1801375278,12.1648761678,0.333333333333,3,0.7955,36498.4494366
4,33-3050,"Police Officers",647.795944784,975.930763766,0.506172839506,328,55.9740555556,38760.0529541
5,33-3051,"Police and Sheriff's Patrol Officers",644.215599037,972.830960672,0.510869565217,329,55.7579444444,38728.5118616
5,33-3052,"Transit and Railroad Police",3.5803457471,3.09980309455,-0.25,-1,0.216111111111,66624.48
3,33-9000,"Other Protective Service Workers",939.955208323,1398.46809305,0.487234042553,458,149.911777778,25626.1925479
4,33-9010,"Animal Control Workers",21.0941672142,29.4709402369,0.380952380952,8,2.5305,59314.9620278
5,33-9011,"Animal Control Workers",21.0941672142,29.4709402369,0.380952380952,8,2.5305,59314.9620278
4,33-9020,"Private Detectives and Investigators",79.1323213203,139.090867597,0.759493670886,60,8.50083333333,42443.1238731
5,33-9021,"Private Detectives and Investigators",79.1323213203,139.090867597,0.759493670886,60,8.50083333333,42443.1238731
4,33-9030,"Security Guards and Gaming Surveillance Officers",721.083415099,1022.20606792,0.417475728155,301,106.227611111,24151.6484097
5,33-9031,"Gaming Surveillance Officers and Gaming Investigators",4.68064078792,5.73225042372,0.2,1,0.532166666667,29409.12
5,33-9032,"Security Guards",716.402774311,1016.47381749,0.418994413408,300,105.695444444,24120.942913
4,33-9090,"Miscellaneous Protective Service Workers",118.645304689,207.700217298,0.747899159664,89,32.6528888889,28586.4585612
5,33-9091,"Crossing Guards",9.79287855479,2.93760382323,-0.7,-7,1.295,19188.4124101
5,33-9092,"Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers",49.1023222159,76.9980999074,0.571428571429,28,15.7985555556,20722.9011427
5,33-9093,"Transportation Security Screeners",33.5320498349,61.6319703965,0.823529411765,28,4.94544444444,38733.788634
5,33-9099,"Protective Service Workers, All Other",26.2180540838,66.1325431704,1.53846153846,40,10.6138888889,33259.3646456
2,35-0000,"Food Preparation and Serving Related Occupations",11804.2010224,23768.0524901,1.01355472721,11964,2949.83,20127.3481694
3,35-1000,"Supervisors of Food Preparation and Serving Workers",1232.25022471,2772.53719374,1.25081168831,1541,283.2735,28984.0731098
4,35-1010,"Supervisors of Food Preparation and Serving Workers",1232.25022471,2772.53719374,1.25081168831,1541,283.2735,28984.0731098
5,35-1011,"Chefs and Head Cooks",118.165300511,214.418901082,0.813559322034,96,19.0120555556,38906.7667266
5,35-1012,"First-Line Supervisors of Food Preparation and Serving Workers",1114.0849242,2558.11829266,1.29622980251,1444,264.261444444,28752.464834
3,35-2000,"Cooks and Food Preparation Workers",3182.99452177,5679.86567394,0.784480050267,2497,621.265611111,21572.0286776
4,35-2010,Cooks,2534.96858953,4987.85063322,0.967652859961,2453,507.038944444,21848.7157917
5,35-2011,"Cooks, Fast Food",769.85093211,737.313593256,-0.0428571428571,-33,103.250222222,19420.6437984
5,35-2012,"Cooks, Institution and Cafeteria",648.683737681,1189.81846319,0.833590138675,541,127.272333333,22283.7317342
5,35-2013,"Cooks, Private Household",6.88931212684,14.2493097564,1,7,1.21272222222,48949.4539318
5,35-2014,"Cooks, Restaurant",975.820331596,2943.86123086,2.01639344262,1968,259.854,22705.0738989
5,35-2015,"Cooks, Short Order",124.537490111,72.6898285146,-0.416,-52,12.7616111111,22531.9316236
5,35-2019,"Cooks, All Other",9.18678590897,29.9182076515,2.33333333333,21,2.68805555556,19517.8812721
4,35-2020,"Food Preparation Workers",648.025932236,692.015040711,0.0679012345679,44,114.226722222,19947.4633459
5,35-2021,"Food Preparation Workers",648.025932236,692.015040711,0.0679012345679,44,114.226722222,19947.4633459
3,35-3000,"Food and Beverage Serving Workers",6191.97257587,13525.1032374,1.18427002584,7333,1767.23488889,19396.2683855
4,35-3010,Bartenders,436.250438909,939.693473536,1.15596330275,504,116.355666667,20104.8189901
5,35-3011,Bartenders,436.250438909,939.693473536,1.15596330275,504,116.355666667,20104.8189901
4,35-3020,"Fast Food and Counter Workers",2985.62549989,8080.93496691,1.70629604823,5095,939.439055556,19577.0832993
5,35-3021,"Combined Food Preparation and Serving Workers, Including Fast Food",2418.00562017,7325.33257668,2.02936311001,4907,798.808333333,19556.034277
5,35-3022,"Counter Attendants, Cafeteria, Food Concession, and Coffee Shop",567.61987972,755.602390229,0.330985915493,188,140.630722222,19799.0045176
4,35-3030,"Waiters and Waitresses",2691.8866939,4419.9798876,0.641901931649,1728,698.500277778,19032.3539529
5,35-3031,"Waiters and Waitresses",2691.8866939,4419.9798876,0.641901931649,1728,698.500277778,19032.3539529
4,35-3040,"Food Servers, Nonrestaurant",78.2099431665,84.4949093024,0.0769230769231,6,12.9398333333,19564.627341
5,35-3041,"Food Servers, Nonrestaurant",78.2099431665,84.4949093024,0.0769230769231,6,12.9398333333,19564.627341
3,35-9000,"Other Food Preparation and Serving Related Workers",1196.98370006,1790.54638506,0.496240601504,594,278.056,20374.6928483
4,35-9010,"Dining Room and Cafeteria Attendants and Bartender Helpers",327.26113436,479.673129493,0.467889908257,153,68.8858333333,20425.835997
5,35-9011,"Dining Room and Cafeteria Attendants and Bartender Helpers",327.26113436,479.673129493,0.467889908257,153,68.8858333333,20425.835997
4,35-9020,Dishwashers,444.420584401,585.13169902,0.317567567568,141,86.0067777778,20407.0666807
5,35-9021,Dishwashers,444.420584401,585.13169902,0.317567567568,141,86.0067777778,20407.0666807
4,35-9030,"Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop",347.864136465,611.42661268,0.755747126437,263,109.546388889,20019.9406538
5,35-9031,"Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop",347.864136465,611.42661268,0.755747126437,263,109.546388889,20019.9406538
4,35-9090,"Miscellaneous Food Preparation and Serving Related Workers",77.4378448319,114.314943865,0.480519480519,37,13.6169444444,22120.6508577
5,35-9099,"Food Preparation and Serving Related Workers, All Other",77.4378448319,114.314943865,0.480519480519,37,13.6169444444,22120.6508577
2,37-0000,"Building and Grounds Cleaning and Maintenance Occupations",6927.04314303,10796.5434743,0.558683412733,3870,1167.937,23220.1874038
3,37-1000,"Supervisors of Building and Grounds Cleaning and Maintenance Workers",583.454584585,830.265051681,0.423670668954,247,82.0873888889,32259.703902
4,37-1010,"First-Line Supervisors of Building and Grounds Cleaning and Maintenance Workers",583.454584585,830.265051681,0.423670668954,247,82.0873888889,32259.703902
5,37-1011,"First-Line Supervisors of Housekeeping and Janitorial Workers",402.872413109,540.711414603,0.342431761787,138,55.2545555556,30959.0664905
5,37-1012,"First-Line Supervisors of Landscaping, Lawn Service, and Groundskeeping Workers",180.582171475,289.553637078,0.602209944751,109,26.8328333333,35829.5343336
3,37-2000,"Building Cleaning and Pest Control Workers",4977.46695465,7414.02142152,0.489652401045,2437,829.832611111,22058.4041334
4,37-2010,"Building Cleaning Workers",4859.0202369,7212.39729982,0.484256019757,2353,809.135666667,21884.8803952
5,37-2011,"Janitors and Cleaners, Except Maids and Housekeeping Cleaners",3260.92786598,4664.63120211,0.430542778289,1404,523.373944444,23368.9081711
5,37-2012,"Maids and Housekeeping Cleaners",1567.42959225,2490.95100483,0.589661774091,924,279.872222222,20087.5192899
5,37-2019,"Building Cleaning Workers, All Other",30.6627786699,56.8150928694,0.838709677419,26,5.8895,21403.7645756
4,37-2020,"Pest Control Workers",118.446717753,201.624121702,0.71186440678,84,20.6969444444,31378.1841041
5,37-2021,"Pest Control Workers",118.446717753,201.624121702,0.71186440678,84,20.6969444444,31378.1841041
3,37-3000,"Grounds Maintenance Workers",1366.12160379,2552.25700112,0.8682284041,1186,256.017,24863.7615129
4,37-3010,"Grounds Maintenance Workers",1366.12160379,2552.25700112,0.8682284041,1186,256.017,24863.7615129
5,37-3011,"Landscaping and Groundskeeping Workers",1290.48449835,2394.72031716,0.856589147287,1105,240.065888889,24783.3967328
5,37-3012,"Pesticide Handlers, Sprayers, and Applicators, Vegetation",20.7670382116,38.0798864293,0.809523809524,17,3.94072222222,20913.6207881
5,37-3013,"Tree Trimmers and Pruners",38.7836353867,106.421573258,1.71794871795,67,10.2197777778,29670.0397587
5,37-3019,"Grounds Maintenance Workers, All Other",16.0864318437,13.035224268,-0.1875,-3,1.79061111111,29229.1464813
2,39-0000,"Personal Care and Service Occupations",5724.7448252,11792.9622637,1.05991266376,6068,1248.00761111,21979.9579459
3,39-1000,"Supervisors of Personal Care and Service Workers",297.222971289,439.530848253,0.481481481481,143,36.3367222222,29202.7585952
4,39-1010,"First-Line Supervisors of Gaming Workers",33.5367966675,17.3394659619,-0.5,-17,3.15077777778,42827.8324637
5,39-1018,"First-Line Supervisors of Gaming Workers",33.5367966675,17.3394659619,-0.5,-17,3.15077777778,42827.8324637
4,39-1020,"First-Line Supervisors of Personal Service Workers",263.686174621,422.191382291,0.598484848485,158,33.1859444444,28712.3009499
5,39-1021,"First-Line Supervisors of Personal Service Workers",263.686174621,422.191382291,0.598484848485,158,33.1859444444,28712.3009499
3,39-2000,"Animal Care and Service Workers",1028.65361298,2111.55817058,1.05247813411,1083,232.678888889,25986.586532
4,39-2010,"Animal Trainers",261.992448081,324.6629473,0.240458015267,63,42.2310555556,29318.459241
5,39-2011,"Animal Trainers",261.992448081,324.6629473,0.240458015267,63,42.2310555556,29318.459241
4,39-2020,"Nonfarm Animal Caretakers",766.661164899,1786.89522328,1.32985658409,1020,190.447833333,25583.0795346
5,39-2021,"Nonfarm Animal Caretakers",766.661164899,1786.89522328,1.32985658409,1020,190.447833333,25583.0795346
3,39-3000,"Entertainment Attendants and Related Workers",347.701831217,582.360038987,0.672413793103,234,101.494111111,19550.0688529
4,39-3010,"Gaming Services Workers",49.1724953886,62.3094722,0.265306122449,13,8.77538888889,27925.510623
5,39-3011,"Gaming Dealers",29.9134212094,55.2948089705,0.833333333333,25,6.86844444444,27947.1199004
5,39-3012,"Gaming and Sports Book Writers and Runners",8.62011458817,4.09997321318,-0.555555555556,-5,0.947666666667,24689.4716319
5,39-3019,"Gaming Service Workers, All Other",10.638959591,2.91469001634,-0.727272727273,-8,0.959222222222,28767.5744915
4,39-3020,"Motion Picture Projectionists",5.85762735227,2.75789905977,-0.5,-3,0.987611111111,26260.8229876
5,39-3021,"Motion Picture Projectionists",5.85762735227,2.75789905977,-0.5,-3,0.987611111111,26260.8229876
4,39-3030,"Ushers, Lobby Attendants, and Ticket Takers",77.1197380055,187.939539926,1.44155844156,111,28.6400555556,21591.3871072
5,39-3031,"Ushers, Lobby Attendants, and Ticket Takers",77.1197380055,187.939539926,1.44155844156,111,28.6400555556,21591.3871072
4,39-3090,"Miscellaneous Entertainment Attendants and Related Workers",215.55197047,329.353127802,0.523148148148,113,63.0910555556,19240.556835
5,39-3091,"Amusement and Recreation Attendants",145.455118929,310.021146849,1.13793103448,165,53.9347222222,19181.2032452
5,39-3092,"Costume Attendants",0.521507316684,2.9356423588,2,2,0.356833333333,38501.8562426
5,39-3093,"Locker Room, Coatroom, and Dressing Room Attendants",46.8158997621,15.8778763211,-0.659574468085,-31,5.58283333333,21735.2394775
5,39-3099,"Entertainment Attendants and Related Workers, All Other",22.7594444621,0.518462273198,-0.95652173913,-22,3.21666666667,24996.1791001
3,39-4000,"Funeral Service Workers",43.4701819095,56.3597375307,0.302325581395,13,6.59994444444,29416.1105449
4,39-4010,Embalmers,2.20815740848,0,-1,-2,0.192388888889,0
5,39-4011,Embalmers,2.20815740848,0,-1,-2,0.192388888889,0
4,39-4020,"Funeral Attendants",19.0058908314,25.5604911791,0.368421052632,7,3.44866666667,21735.1767493
5,39-4021,"Funeral Attendants",19.0058908314,25.5604911791,0.368421052632,7,3.44866666667,21735.1767493
4,39-4030,"Morticians, Undertakers, and Funeral Directors",22.2561336696,30.7992463517,0.409090909091,9,2.95888888889,42675.0142271
5,39-4031,"Morticians, Undertakers, and Funeral Directors",22.2561336696,30.7992463517,0.409090909091,9,2.95888888889,42675.0142271
3,39-5000,"Personal Appearance Workers",997.192708854,1920.57146355,0.926780341023,924,176.436944444,22124.0769855
4,39-5010,"Barbers, Hairdressers, Hairstylists and Cosmetologists",846.802279097,1471.53237096,0.737898465171,625,144.873277778,22908.1086112
5,39-5011,Barbers,83.2941106196,82.750020578,0,0,9.40822222222,18941.5256444
5,39-5012,"Hairdressers, Hairstylists, and Cosmetologists",763.508168478,1388.78235038,0.818062827225,625,135.465055556,23243.7485378
4,39-5090,"Miscellaneous Personal Appearance Workers",150.390429756,449.039092587,1.99333333333,299,31.5637222222,19485.1062003
5,39-5091,"Makeup Artists, Theatrical and Performance",3.12677820773,2.0022627433,-0.333333333333,-1,0.290722222222,46688.3451426
5,39-5092,"Manicurists and Pedicurists",96.0778704913,273.321682098,1.84375,177,19.6581666667,19356.553725
5,39-5093,Shampooers,11.106052302,35.1174378293,2.18181818182,24,2.333,15353.3708403
5,39-5094,"Skincare Specialists",40.0797287551,138.597709917,2.475,99,9.28188888889,20512.5739035
3,39-6000,"Baggage Porters, Bellhops, and Concierges",34.3008436734,58.8808123176,0.735294117647,25,6.437,22035.2730758
4,39-6010,"Baggage Porters, Bellhops, and Concierges",34.3008436734,58.8808123176,0.735294117647,25,6.437,22035.2730758
5,39-6011,"Baggage Porters and Bellhops",24.1360164583,25.8403487637,0.0833333333333,2,3.42972222222,18047.2671697
5,39-6012,Concierges,10.1648272151,33.0404635539,2.3,23,3.00733333333,26935.0142606
3,39-7000,"Tour and Travel Guides",36.9091674719,128.572798914,2.48648648649,92,10.6965,41243.0449119
4,39-7010,"Tour and Travel Guides",36.9091674719,128.572798914,2.48648648649,92,10.6965,41243.0449119
5,39-7018,"Tour and Travel Guides",36.9091674719,128.572798914,2.48648648649,92,10.6965,41243.0449119
3,39-9000,"Other Personal Care and Service Workers",2939.29350781,6495.12839357,1.20993535216,3556,677.3275,21417.3957617
4,39-9010,"Childcare Workers",1677.82778213,2289.62332561,0.364719904648,612,312.299888889,19375.7770634
5,39-9011,"Childcare Workers",1677.82778213,2289.62332561,0.364719904648,612,312.299888889,19375.7770634
4,39-9020,"Personal Care Aides",511.063462675,2604.97443132,4.09784735812,2094,184.299444444,22334.8524586
5,39-9021,"Personal Care Aides",511.063462675,2604.97443132,4.09784735812,2094,184.299444444,22334.8524586
4,39-9030,"Recreation and Fitness Workers",499.349258462,990.4198495,0.983967935872,491,118.771555556,29410.7743288
5,39-9031,"Fitness Trainers and Aerobics Instructors",268.499397054,589.65610919,1.20149253731,322,64.8225555556,40301.8730913
5,39-9032,"Recreation Workers",230.849861408,400.76374031,0.735930735931,170,53.949,20618.8965105
4,39-9040,"Residential Advisors",34.7757121089,107.450182931,2.05714285714,72,10.7775,38229.1728988
5,39-9041,"Residential Advisors",34.7757121089,107.450182931,2.05714285714,72,10.7775,38229.1728988
4,39-9090,"Miscellaneous Personal Care and Service Workers",216.277292433,502.660604203,1.3287037037,287,51.1791111111,30840.5059246
5,39-9099,"Personal Care and Service Workers, All Other",216.277292433,502.660604203,1.3287037037,287,51.1791111111,30840.5059246
2,41-0000,"Sales and Related Occupations",24114.4240372,39569.2524048,0.640913991872,15455,4100.77866667,27226.1732341
3,41-1000,"Supervisors of Sales Workers",3523.1889616,5385.46119813,0.52852682373,1862,472.118888889,34351.068191
4,41-1010,"First-Line Supervisors of Sales Workers",3523.1889616,5385.46119813,0.52852682373,1862,472.118888889,34351.068191
5,41-1011,"First-Line Supervisors of Retail Sales Workers",2499.93402422,3946.70886213,0.5788,1447,357.098722222,32424.6766582
5,41-1012,"First-Line Supervisors of Non-Retail Sales Workers",1023.25493738,1438.752336,0.406647116325,416,115.020222222,48818.8620146
3,41-2000,"Retail Sales Workers",11398.8221771,16500.7402454,0.447583121326,5102,2159.28594444,21093.3111175
4,41-2010,Cashiers,3610.23864346,5303.49171498,0.468975069252,1693,784.974666667,20407.7104303
5,41-2011,Cashiers,3556.67226853,4949.7135589,0.3916221535,1393,757.053055556,20559.5727428
5,41-2012,"Gaming Change Persons and Booth Cashiers",53.5663749293,353.778156079,5.55555555556,300,27.9215555556,18022.2647738
4,41-2020,"Counter and Rental Clerks and Parts Salespersons",1048.82812513,1342.00282377,0.279313632031,293,146.678388889,26932.9004721
5,41-2021,"Counter and Rental Clerks",688.654486053,804.915296597,0.168359941945,116,96.0293888889,24428.7786328
5,41-2022,"Parts Salespersons",360.173639081,537.087527168,0.491666666667,177,50.649,30179.088446
4,41-2030,"Retail Salespersons",6739.75540855,9855.24570668,0.462166172107,3115,1227.63294444,21307.6877573
5,41-2031,"Retail Salespersons",6739.75540855,9855.24570668,0.462166172107,3115,1227.63294444,21307.6877573
3,41-3000,"Sales Representatives, Services",1669.58776797,4055.0042323,1.42814371257,2385,320.27,42959.5901559
4,41-3010,"Advertising Sales Agents",221.908967035,352.11664643,0.585585585586,130,40.6656111111,46079.9039923
5,41-3011,"Advertising Sales Agents",221.908967035,352.11664643,0.585585585586,130,40.6656111111,46079.9039923
4,41-3020,"Insurance Sales Agents",620.756848929,1404.94719418,1.26247987118,784,99.4671111111,46400.3740399
5,41-3021,"Insurance Sales Agents",620.756848929,1404.94719418,1.26247987118,784,99.4671111111,46400.3740399
4,41-3030,"Securities, Commodities, and Financial Services Sales Agents",326.239054416,788.056236607,1.41717791411,462,56.4856666667,33786.5243298
5,41-3031,"Securities, Commodities, and Financial Services Sales Agents",326.239054416,788.056236607,1.41717791411,462,56.4856666667,33786.5243298
4,41-3040,"Travel Agents",85.163102956,72.5498209958,-0.141176470588,-12,8.29133333333,35200.2361405
5,41-3041,"Travel Agents",85.163102956,72.5498209958,-0.141176470588,-12,8.29133333333,35200.2361405
4,41-3090,"Miscellaneous Sales Representatives, Services",415.519794631,1437.33433409,2.45432692308,1021,115.360277778,43604.7932063
5,41-3099,"Sales Representatives, Services, All Other",415.519794631,1437.33433409,2.45432692308,1021,115.360277778,43604.7932063
3,41-4000,"Sales Representatives, Wholesale and Manufacturing",2675.15728202,3604.23385225,0.347289719626,929,356.466222222,58219.7583423
4,41-4010,"Sales Representatives, Wholesale and Manufacturing",2675.15728202,3604.23385225,0.347289719626,929,356.466222222,58219.7583423
5,41-4011,"Sales Representatives, Wholesale and Manufacturing, Technical and Scientific Products",298.54768591,300.261554653,0.00334448160535,1,40.351,63093.100286
5,41-4012,"Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products",2376.60959611,3303.9722976,0.389987379049,927,316.115222222,57803.064909
3,41-9000,"Other Sales and Related Workers",4847.66784848,10023.8128767,1.06765676568,5176,792.6375,39141.2842066
4,41-9010,"Models, Demonstrators, and Product Promoters",234.059826457,376.924179033,0.611111111111,143,47.6936111111,24786.9253377
5,41-9011,"Demonstrators and Product Promoters",232.49274119,351.744275788,0.51724137931,120,45.9077777778,24681.8563404
5,41-9012,Models,1.56708526695,25.1799032446,11.5,23,1.78583333333,29909.4463467
4,41-9020,"Real Estate Brokers and Sales Agents",2657.79558264,7179.42511985,1.70090293454,4521,470.034611111,48531.2127781
5,41-9021,"Real Estate Brokers",642.390503579,1648.39095085,1.56697819315,1006,110.208166667,56377.5214473
5,41-9022,"Real Estate Sales Agents",2015.40507906,5531.03416899,1.74491315136,3516,359.826444444,46424.2932709
4,41-9030,"Sales Engineers",73.4910331668,67.6411724354,-0.0684931506849,-5,8.18227777778,107097.942122
5,41-9031,"Sales Engineers",73.4910331668,67.6411724354,-0.0684931506849,-5,8.18227777778,107097.942122
4,41-9040,Telemarketers,410.035381601,181.876845515,-0.556097560976,-228,46.2716111111,25716.4407648
5,41-9041,Telemarketers,410.035381601,181.876845515,-0.556097560976,-228,46.2716111111,25716.4407648
4,41-9090,"Miscellaneous Sales and Related Workers",1472.28602462,2217.9455599,0.506793478261,746,220.455444444,22317.2108645
5,41-9091,"Door-to-Door Sales Workers, News and Street Vendors, and Related Workers",1128.59198231,1785.24838888,0.581045172719,656,169.804722222,19495.3214642
5,41-9099,"Sales and Related Workers, All Other",343.694042311,432.697171015,0.258720930233,89,50.6507222222,26682.0734672
2,43-0000,"Office and Administrative Support Occupations",28414.6716975,36241.8113512,0.275453105754,7827,3759.62711111,32086.4059249
3,43-1000,"Supervisors of Office and Administrative Support Workers",1957.63929673,3052.95237455,0.55924412666,1095,262.971055556,48373.5385022
4,43-1010,"First-Line Supervisors of Office and Administrative Support Workers",1957.63929673,3052.95237455,0.55924412666,1095,262.971055556,48373.5385022
5,43-1011,"First-Line Supervisors of Office and Administrative Support Workers",1957.63929673,3052.95237455,0.55924412666,1095,262.971055556,48373.5385022
3,43-2000,"Communications Equipment Operators",207.641797997,69.2219885964,-0.668269230769,-139,16.6768888889,26518.9124225
4,43-2010,"Switchboard Operators, Including Answering Service",187.492714793,60.4189127813,-0.679144385027,-127,15.2249444444,26008.9163301
5,43-2011,"Switchboard Operators, Including Answering Service",187.492714793,60.4189127813,-0.679144385027,-127,15.2249444444,26008.9163301
4,43-2020,"Telephone Operators",16.7023379622,0.362702291979,-1,-17,0.926777777778,33578.8979564
5,43-2021,"Telephone Operators",16.7023379622,0.362702291979,-1,-17,0.926777777778,33578.8979564
4,43-2090,"Miscellaneous Communications Equipment Operators",3.44674524194,8.44037352319,1.66666666667,5,0.525166666667,36953.28
5,43-2099,"Communications Equipment Operators, All Other",3.44674524194,8.44037352319,1.66666666667,5,0.525166666667,36953.28
3,43-3000,"Financial Clerks",5078.92645577,5336.26807163,0.0506005119118,257,624.699166667,33890.7600243
4,43-3010,"Bill and Account Collectors",389.703605746,286.4346662,-0.266666666667,-104,46.7076666667,35871.982124
5,43-3011,"Bill and Account Collectors",389.703605746,286.4346662,-0.266666666667,-104,46.7076666667,35871.982124
4,43-3020,"Billing and Posting Clerks",618.782344339,693.022379429,0.119547657512,74,74.409,35110.4920969
5,43-3021,"Billing and Posting Clerks",618.782344339,693.022379429,0.119547657512,74,74.409,35110.4920969
4,43-3030,"Bookkeeping, Accounting, and Auditing Clerks",2637.44529363,3098.69858144,0.175199089875,462,347.125888889,36035.338934
5,43-3031,"Bookkeeping, Accounting, and Auditing Clerks",2637.44529363,3098.69858144,0.175199089875,462,347.125888889,36035.338934
4,43-3040,"Gaming Cage Workers",3.8778414814,6.24178247055,0.5,2,0.707111111111,22739.9191893
5,43-3041,"Gaming Cage Workers",3.8778414814,6.24178247055,0.5,2,0.707111111111,22739.9191893
4,43-3050,"Payroll and Timekeeping Clerks",224.37374596,156.949866058,-0.299107142857,-67,22.2409444444,48756.6571291
5,43-3051,"Payroll and Timekeeping Clerks",224.37374596,156.949866058,-0.299107142857,-67,22.2409444444,48756.6571291
4,43-3060,"Procurement Clerks",114.070748919,167.881670761,0.473684210526,54,15.3920555556,39397.1485766
5,43-3061,"Procurement Clerks",114.070748919,167.881670761,0.473684210526,54,15.3920555556,39397.1485766
4,43-3070,Tellers,1044.08747083,884.610910372,-0.152298850575,-159,113.851722222,25166.3243791
5,43-3071,Tellers,1044.08747083,884.610910372,-0.152298850575,-159,113.851722222,25166.3243791
4,43-3090,"Miscellaneous Financial Clerks",46.5854048713,42.428214895,-0.106382978723,-5,4.26466666667,41190.7683742
5,43-3099,"Financial Clerks, All Other",46.5854048713,42.428214895,-0.106382978723,-5,4.26466666667,41190.7683742
3,43-4000,"Information and Record Clerks",5843.72907501,8153.18797324,0.395106091718,2309,881.726888889,29501.3052855
4,43-4010,"Brokerage Clerks",47.8088209966,28.7656508589,-0.395833333333,-19,3.69233333333,33536.49461
5,43-4011,"Brokerage Clerks",47.8088209966,28.7656508589,-0.395833333333,-19,3.69233333333,33536.49461
4,43-4020,"Correspondence Clerks",17.9803796051,4.68147692804,-0.722222222222,-13,1.25661111111,58739.4205259
5,43-4021,"Correspondence Clerks",17.9803796051,4.68147692804,-0.722222222222,-13,1.25661111111,58739.4205259
4,43-4030,"Court, Municipal, and License Clerks",64.118328395,130.433539076,1.03125,66,9.09505555556,30103.4827673
5,43-4031,"Court, Municipal, and License Clerks",64.118328395,130.433539076,1.03125,66,9.09505555556,30103.4827673
4,43-4040,"Credit Authorizers, Checkers, and Clerks",85.0293514868,56.3031711232,-0.341176470588,-29,8.21711111111,29179.4074111
5,43-4041,"Credit Authorizers, Checkers, and Clerks",85.0293514868,56.3031711232,-0.341176470588,-29,8.21711111111,29179.4074111
4,43-4050,"Customer Service Representatives",1940.20253001,3657.11511023,0.885051546392,1717,360.286111111,31236.1156992
5,43-4051,"Customer Service Representatives",1940.20253001,3657.11511023,0.885051546392,1717,360.286111111,31236.1156992
4,43-4060,"Eligibility Interviewers, Government Programs",79.0720701249,262.122902617,2.3164556962,183,16.5667222222,26063.4123876
5,43-4061,"Eligibility Interviewers, Government Programs",79.0720701249,262.122902617,2.3164556962,183,16.5667222222,26063.4123876
4,43-4070,"File Clerks",221.25750765,102.40455591,-0.538461538462,-119,20.9296111111,27631.4794961
5,43-4071,"File Clerks",221.25750765,102.40455591,-0.538461538462,-119,20.9296111111,27631.4794961
4,43-4080,"Hotel, Motel, and Resort Desk Clerks",182.531124901,312.874469886,0.710382513661,130,44.4735,20201.7085755
5,43-4081,"Hotel, Motel, and Resort Desk Clerks",182.531124901,312.874469886,0.710382513661,130,44.4735,20201.7085755
4,43-4110,"Interviewers, Except Eligibility and Loan",261.562177059,370.533716194,0.416030534351,109,37.6880555556,27235.9396108
5,43-4111,"Interviewers, Except Eligibility and Loan",261.562177059,370.533716194,0.416030534351,109,37.6880555556,27235.9396108
4,43-4120,"Library Assistants, Clerical",161.88348482,195.309232536,0.203703703704,33,29.4483888889,25211.2062195
5,43-4121,"Library Assistants, Clerical",161.88348482,195.309232536,0.203703703704,33,29.4483888889,25211.2062195
4,43-4130,"Loan Interviewers and Clerks",269.195409015,425.141548723,0.579925650558,156,35.0056666667,37692.078151
5,43-4131,"Loan Interviewers and Clerks",269.195409015,425.141548723,0.579925650558,156,35.0056666667,37692.078151
4,43-4140,"New Accounts Clerks",153.12640268,97.004678081,-0.366013071895,-56,13.8422777778,29612.5146759
5,43-4141,"New Accounts Clerks",153.12640268,97.004678081,-0.366013071895,-56,13.8422777778,29612.5146759
4,43-4150,"Order Clerks",347.941814008,256.735014704,-0.261494252874,-91,35.0230555556,37118.1356101
5,43-4151,"Order Clerks",347.941814008,256.735014704,-0.261494252874,-91,35.0230555556,37118.1356101
4,43-4160,"Human Resources Assistants, Except Payroll and Timekeeping",253.155330833,217.251020397,-0.142292490119,-36,27.7363333333,36761.7988277
5,43-4161,"Human Resources Assistants, Except Payroll and Timekeeping",253.155330833,217.251020397,-0.142292490119,-36,27.7363333333,36761.7988277
4,43-4170,"Receptionists and Information Clerks",1353.51268771,1761.32930623,0.30059084195,407,201.502666667,26520.7444605
5,43-4171,"Receptionists and Information Clerks",1353.51268771,1761.32930623,0.30059084195,407,201.502666667,26520.7444605
4,43-4180,"Reservation and Transportation Ticket Agents and Travel Clerks",79.3442838059,66.5517214996,-0.151898734177,-12,6.93505555556,43376.4083483
5,43-4181,"Reservation and Transportation Ticket Agents and Travel Clerks",79.3442838059,66.5517214996,-0.151898734177,-12,6.93505555556,43376.4083483
4,43-4190,"Miscellaneous Information and Record Clerks",326.007371916,208.630858246,-0.358895705521,-117,30.0282777778,33678.7938724
5,43-4199,"Information and Record Clerks, All Other",326.007371916,208.630858246,-0.358895705521,-117,30.0282777778,33678.7938724
3,43-5000,"Material Recording, Scheduling, Dispatching, and Distributing Workers",5819.1972949,8365.91093671,0.437704072865,2547,760.392333333,31111.4293667
4,43-5010,"Cargo and Freight Agents",144.227019437,339.803084635,1.36111111111,196,22.0631111111,43803.8447758
5,43-5011,"Cargo and Freight Agents",144.227019437,339.803084635,1.36111111111,196,22.0631111111,43803.8447758
4,43-5020,"Couriers and Messengers",274.315356551,375.137000594,0.368613138686,101,29.3175555556,24524.3760181
5,43-5021,"Couriers and Messengers",274.315356551,375.137000594,0.368613138686,101,29.3175555556,24524.3760181
4,43-5030,Dispatchers,477.526519133,797.345063972,0.667364016736,319,62.587,31087.9171947
5,43-5031,"Police, Fire, and Ambulance Dispatchers",98.0758717076,145.755362622,0.489795918367,48,11.5407777778,32488.7484469
5,43-5032,"Dispatchers, Except Police, Fire, and Ambulance",379.450647426,651.589701349,0.720316622691,273,51.0462222222,30500.7173968
4,43-5040,"Meter Readers, Utilities",68.439354379,33.8902966063,-0.5,-34,5.591,31040.2525302
5,43-5041,"Meter Readers, Utilities",68.439354379,33.8902966063,-0.5,-34,5.591,31040.2525302
4,43-5050,"Postal Service Workers",653.964666471,749.202087427,0.145259938838,95,49.6215555556,46705.5371977
5,43-5051,"Postal Service Clerks",80.7710500052,112.684176567,0.395061728395,32,6.70811111111,46138.3865204
5,43-5052,"Postal Service Mail Carriers",363.657377381,508.698283097,0.398351648352,145,29.5801666667,46264.2564508
5,43-5053,"Postal Service Mail Sorters, Processors, and Processing Machine Operators",209.536239085,127.819627763,-0.390476190476,-82,13.3332777778,47037.0773089
4,43-5060,"Production, Planning, and Expediting Clerks",581.023314469,976.768058087,0.681583476764,396,72.1540555556,38130.4457382
5,43-5061,"Production, Planning, and Expediting Clerks",581.023314469,976.768058087,0.681583476764,396,72.1540555556,38130.4457382
4,43-5070,"Shipping, Receiving, and Traffic Clerks",1140.53671238,1116.69796043,-0.0210341805434,-24,109.280444444,32667.944504
5,43-5071,"Shipping, Receiving, and Traffic Clerks",1140.53671238,1116.69796043,-0.0210341805434,-24,109.280444444,32667.944504
4,43-5080,"Stock Clerks and Order Fillers",1987.18263899,3485.94534206,0.754403623553,1499,355.016777778,24881.415866
5,43-5081,"Stock Clerks and Order Fillers",1987.18263899,3485.94534206,0.754403623553,1499,355.016777778,24881.415866
4,43-5110,"Weighers, Measurers, Checkers, and Samplers, Recordkeeping",491.981713101,491.122042894,-0.0020325203252,-1,54.7608333333,34820.1855051
5,43-5111,"Weighers, Measurers, Checkers, and Samplers, Recordkeeping",491.981713101,491.122042894,-0.0020325203252,-1,54.7608333333,34820.1855051
3,43-6000,"Secretaries and Administrative Assistants",3702.79593915,4428.26221671,0.195787199568,725,469.236055556,33314.6273201
4,43-6010,"Secretaries and Administrative Assistants",3702.79593915,4428.26221671,0.195787199568,725,469.236055556,33314.6273201
5,43-6011,"Executive Secretaries and Executive Administrative Assistants",1422.70881572,470.895821983,-0.669009135629,-952,103.377722222,51077.0236966
5,43-6012,"Legal Secretaries",128.780269861,88.3594168902,-0.317829457364,-41,13.3481666667,36806.9670057
5,43-6013,"Medical Secretaries",251.219519482,526.377859695,1.09561752988,275,44.0070555556,32288.7848585
5,43-6014,"Secretaries and Administrative Assistants, Except Legal, Medical, and Executive",1900.08733409,3342.62911814,0.759473684211,1443,308.503111111,31774.732819
3,43-9000,"Other Office and Administrative Support Workers",5804.74183792,6836.0077898,0.177605512489,1031,743.924777778,30030.9293412
4,43-9010,"Computer Operators",252.476085654,82.5619918964,-0.670634920635,-169,20.221,30541.4908178
5,43-9011,"Computer Operators",252.476085654,82.5619918964,-0.670634920635,-169,20.221,30541.4908178
4,43-9020,"Data Entry and Information Processing Workers",513.567157957,374.406135449,-0.272373540856,-140,52.9023333333,28684.996055
5,43-9021,"Data Entry Keyers",349.875664503,276.519745364,-0.208571428571,-73,37.673,28942.5112862
5,43-9022,"Word Processors and Typists",163.691493455,97.8863900851,-0.40243902439,-66,15.2293333333,26372.0340751
4,43-9030,"Desktop Publishers",39.0150448671,11.0400150628,-0.717948717949,-28,3.06744444444,42189.0342104
5,43-9031,"Desktop Publishers",39.0150448671,11.0400150628,-0.717948717949,-28,3.06744444444,42189.0342104
4,43-9040,"Insurance Claims and Policy Processing Clerks",146.469404625,151.273934898,0.0342465753425,5,14.5551111111,33808.4258689
5,43-9041,"Insurance Claims and Policy Processing Clerks",146.469404625,151.273934898,0.0342465753425,5,14.5551111111,33808.4258689
4,43-9050,"Mail Clerks and Mail Machine Operators, Except Postal Service",162.442096323,112.22122433,-0.308641975309,-50,15.3817222222,29815.4426288
5,43-9051,"Mail Clerks and Mail Machine Operators, Except Postal Service",162.442096323,112.22122433,-0.308641975309,-50,15.3817222222,29815.4426288
4,43-9060,"Office Clerks, General",4173.61286175,5595.4135045,0.340440824149,1421,576.949166667,29891.4745692
5,43-9061,"Office Clerks, General",4173.61286175,5595.4135045,0.340440824149,1421,576.949166667,29891.4745692
4,43-9070,"Office Machine Operators, Except Computer",137.377773149,74.5795272543,-0.452554744526,-62,13.2805555556,31834.9993703
5,43-9071,"Office Machine Operators, Except Computer",137.377773149,74.5795272543,-0.452554744526,-62,13.2805555556,31834.9993703
4,43-9080,"Proofreaders and Copy Markers",34.7635166287,21.9528374067,-0.371428571429,-13,3.71622222222,30225.7722105
5,43-9081,"Proofreaders and Copy Markers",34.7635166287,21.9528374067,-0.371428571429,-13,3.71622222222,30225.7722105
4,43-9110,"Statistical Assistants",15.4984451118,3.40324187089,-0.8,-12,1.16972222222,51811.8681919
5,43-9111,"Statistical Assistants",15.4984451118,3.40324187089,-0.8,-12,1.16972222222,51811.8681919
4,43-9190,"Miscellaneous Office and Administrative Support Workers",329.519451854,409.15537714,0.239393939394,79,42.6814444444,30741.9505379
5,43-9199,"Office and Administrative Support Workers, All Other",329.519451854,409.15537714,0.239393939394,79,42.6814444444,30741.9505379
2,45-0000,"Farming, Fishing, and Forestry Occupations",1795.0820945,2291.74414766,0.276880222841,497,287.782888889,26694.8605518
3,45-1000,"Supervisors of Farming, Fishing, and Forestry Workers",90.7830368707,107.889697462,0.186813186813,17,13.2394444444,51153.6089366
4,45-1010,"First-Line Supervisors of Farming, Fishing, and Forestry Workers",90.7830368707,107.889697462,0.186813186813,17,13.2394444444,51153.6089366
5,45-1011,"First-Line Supervisors of Farming, Fishing, and Forestry Workers",90.7830368707,107.889697462,0.186813186813,17,13.2394444444,51153.6089366
3,45-2000,"Agricultural Workers",1541.95570008,1996.73894332,0.295071335927,455,242.811055556,26022.0261104
4,45-2010,"Agricultural Inspectors",71.8006610987,85.5468119258,0.194444444444,14,13.3202777778,39246.8848341
5,45-2011,"Agricultural Inspectors",71.8006610987,85.5468119258,0.194444444444,14,13.3202777778,39246.8848341
4,45-2020,"Animal Breeders",25.5077059941,6.2282322889,-0.769230769231,-20,2.13722222222,41170.6355285
5,45-2021,"Animal Breeders",25.5077059941,6.2282322889,-0.769230769231,-20,2.13722222222,41170.6355285
4,45-2040,"Graders and Sorters, Agricultural Products",147.833665907,295.916615666,1,148,29.1702222222,24673.6413368
5,45-2041,"Graders and Sorters, Agricultural Products",147.833665907,295.916615666,1,148,29.1702222222,24673.6413368
4,45-2090,"Miscellaneous Agricultural Workers",1296.81366708,1609.04728344,0.240555127217,312,198.183333333,26065.378894
5,45-2091,"Agricultural Equipment Operators",65.3164287765,197.598441229,2.04615384615,133,21.2762777778,28227.0654919
5,45-2092,"Farmworkers and Laborers, Crop, Nursery, and Greenhouse",579.885973812,578.070544534,-0.00344827586207,-2,90.7995555556,25487.2123259
5,45-2093,"Farmworkers, Farm, Ranch, and Aquacultural Animals",277.258008421,654.964090383,1.36462093863,378,52.4038333333,28035.5339383
5,45-2099,"Agricultural Workers, All Other",374.353256075,178.414207297,-0.524064171123,-196,33.7036111111,21271.4075438
3,45-3000,"Fishing and Hunting Workers",48.2389117675,76.8078677499,0.604166666667,29,11.0689444444,26190.4840704
4,45-3010,"Fishers and Related Fishing Workers",46.8403851499,53.5620976307,0.148936170213,7,9.69677777778,25999.1232631
5,45-3011,"Fishers and Related Fishing Workers",46.8403851499,53.5620976307,0.148936170213,7,9.69677777778,25999.1232631
4,45-3020,"Hunters and Trappers",1.39852661762,23.2457701192,22,22,1.37216666667,26639.1301889
5,45-3021,"Hunters and Trappers",1.39852661762,23.2457701192,22,22,1.37216666667,26639.1301889
3,45-4000,"Forest, Conservation, and Logging Workers",114.10444578,110.307639126,-0.0350877192982,-4,20.6634444444,36310.8245108
4,45-4010,"Forest and Conservation Workers",3.03547597834,5.52860016211,1,3,0.636277777778,22776.581566
5,45-4011,"Forest and Conservation Workers",3.03547597834,5.52860016211,1,3,0.636277777778,22776.581566
4,45-4020,"Logging Workers",111.068969801,104.779038964,-0.0540540540541,-6,20.0271666667,37385.636833
5,45-4021,Fallers,22.6366930047,11.2045580356,-0.521739130435,-12,3.14927777778,45407.617911
5,45-4022,"Logging Equipment Operators",66.6783296413,82.543126388,0.238805970149,16,13.9596666667,37229.2445556
5,45-4023,"Log Graders and Scalers",9.03588308062,3.63651781869,-0.555555555556,-5,0.854333333333,31809.1967881
5,45-4029,"Logging Workers, All Other",12.7180640746,7.39483672196,-0.461538461538,-6,2.06388888889,34817.5440059
2,47-0000,"Construction and Extraction Occupations",10542.7141456,14704.7784955,0.394764298587,4162,1353.93261111,34831.6877074
3,47-1000,"Supervisors of Construction and Extraction Workers",1212.96894832,1580.61337112,0.303380049464,368,138.713333333,50047.4543975
4,47-1010,"First-Line Supervisors of Construction Trades and Extraction Workers",1212.96894832,1580.61337112,0.303380049464,368,138.713333333,50047.4543975
5,47-1011,"First-Line Supervisors of Construction Trades and Extraction Workers",1212.96894832,1580.61337112,0.303380049464,368,138.713333333,50047.4543975
3,47-2000,"Construction Trades Workers",7801.33779177,11554.2099736,0.481092167671,3753,1015.54572222,33970.974579
4,47-2010,Boilermakers,8.50014828757,8.24233903612,-0.111111111111,-1,1.08083333333,47543.0481374
5,47-2011,Boilermakers,8.50014828757,8.24233903612,-0.111111111111,-1,1.08083333333,47543.0481374
4,47-2020,"Brickmasons, Blockmasons, and Stonemasons",200.116557244,164.981900399,-0.175,-35,19.0741111111,37941.3879934
5,47-2021,"Brickmasons and Blockmasons",182.708214364,145.742957843,-0.20218579235,-37,17.1734444444,39771.96307
5,47-2022,Stonemasons,17.4083428794,19.2389425561,0.117647058824,2,1.90072222222,25104.9618638
4,47-2030,Carpenters,1886.60356885,2394.24260533,0.268680445151,507,220.429944444,33839.7952729
5,47-2031,Carpenters,1886.60356885,2394.24260533,0.268680445151,507,220.429944444,33839.7952729
4,47-2040,"Carpet, Floor, and Tile Installers and Finishers",308.118204555,341.738754475,0.11038961039,34,35.5925,28403.0361667
5,47-2041,"Carpet Installers",87.6943121773,84.4059457922,-0.0454545454545,-4,9.39972222222,26651.989801
5,47-2042,"Floor Layers, Except Carpet, Wood, and Hard Tiles",35.8693926874,50.3339821852,0.388888888889,14,4.0925,30036.6190783
5,47-2043,"Floor Sanders and Finishers",33.8124368163,47.2592063469,0.382352941176,13,5.26144444444,23455.4213047
5,47-2044,"Tile and Marble Setters",150.742062874,159.73962015,0.0596026490066,9,16.8388333333,31453.2838231
4,47-2050,"Cement Masons, Concrete Finishers, and Terrazzo Workers",292.907440627,470.307475007,0.60409556314,177,37.0266111111,36296.2743001
5,47-2051,"Cement Masons and Concrete Finishers",292.253600552,469.724860792,0.609589041096,178,36.9392777778,36296.9472147
5,47-2053,"Terrazzo Workers and Finishers",0.653840075283,0.582614214708,0,0,0.0873333333333,33829.3172573
4,47-2060,"Construction Laborers",1290.24909403,2884.14757286,1.23565891473,1594,213.953888889,27685.9800628
5,47-2061,"Construction Laborers",1290.24909403,2884.14757286,1.23565891473,1594,213.953888889,27685.9800628
4,47-2070,"Construction Equipment Operators",550.856298841,726.948559641,0.31941923775,176,76.3466666667,35208.7504714
5,47-2071,"Paving, Surfacing, and Tamping Equipment Operators",134.393324595,165.115530842,0.231343283582,31,19.5228333333,31183.6366866
5,47-2072,"Pile-Driver Operators",0.869333630906,0.795821817206,0,0,0.256222222222,46206.6332565
5,47-2073,"Operating Engineers and Other Construction Equipment Operators",415.593640615,561.037206982,0.348557692308,145,56.5676111111,36628.4348708
4,47-2080,"Drywall Installers, Ceiling Tile Installers, and Tapers",284.007418479,266.350779833,-0.0633802816901,-18,27.9225555556,34640.628967
5,47-2081,"Drywall and Ceiling Tile Installers",165.031793085,191.656428696,0.163636363636,27,18.4575,36539.0660205
5,47-2082,Tapers,118.975625394,74.6943511378,-0.36974789916,-44,9.46511111111,31186.7330143
4,47-2110,Electricians,838.496836404,1407.30028443,0.678997613365,569,121.5665,40883.9422844
5,47-2111,Electricians,838.496836404,1407.30028443,0.678997613365,569,121.5665,40883.9422844
4,47-2120,Glaziers,61.3009150732,93.1901288611,0.524590163934,32,8.88788888889,35634.8283342
5,47-2121,Glaziers,61.3009150732,93.1901288611,0.524590163934,32,8.88788888889,35634.8283342
4,47-2130,"Insulation Workers",43.6289827176,52.9806061565,0.204545454545,9,5.79005555556,53176.8364394
5,47-2131,"Insulation Workers, Floor, Ceiling, and Wall",35.9914756893,42.1117805575,0.166666666667,6,4.24561111111,55086.6935261
5,47-2132,"Insulation Workers, Mechanical",7.63750702829,10.868825599,0.375,3,1.54444444444,26084.0310878
4,47-2140,"Painters and Paperhangers",746.521170153,1125.34111245,0.506024096386,378,93.158,31052.0555685
5,47-2141,"Painters, Construction and Maintenance",727.006229774,1118.85095749,0.539202200825,392,91.7927777778,31076.2430561
5,47-2142,Paperhangers,19.5149403798,6.4901549638,-0.7,-14,1.36522222222,26158.2780411
4,47-2150,"Pipelayers, Plumbers, Pipefitters, and Steamfitters",773.82955121,967.121208357,0.249354005168,193,94.1133333333,42591.0420177
5,47-2151,Pipelayers,110.599268195,78.3941437778,-0.297297297297,-33,11.2529444444,34462.1651417
5,47-2152,"Plumbers, Pipefitters, and Steamfitters",663.230283015,888.727064579,0.340874811463,226,82.8603888889,43674.5637369
4,47-2160,"Plasterers and Stucco Masons",21.3156554233,21.7855603081,0.047619047619,1,2.33661111111,30712.5854777
5,47-2161,"Plasterers and Stucco Masons",21.3156554233,21.7855603081,0.047619047619,1,2.33661111111,30712.5854777
4,47-2170,"Reinforcing Iron and Rebar Workers",30.2190575718,27.2175300331,-0.1,-3,3.47283333333,34029.2407029
5,47-2171,"Reinforcing Iron and Rebar Workers",30.2190575718,27.2175300331,-0.1,-3,3.47283333333,34029.2407029
4,47-2180,Roofers,166.234695243,288.584746477,0.740963855422,123,23.0385555556,34293.0103285
5,47-2181,Roofers,166.234695243,288.584746477,0.740963855422,123,23.0385555556,34293.0103285
4,47-2210,"Sheet Metal Workers",190.802626943,148.562339339,-0.219895287958,-42,16.8968888889,33804.5971499
5,47-2211,"Sheet Metal Workers",190.802626943,148.562339339,-0.219895287958,-42,16.8968888889,33804.5971499
4,47-2220,"Structural Iron and Steel Workers",97.5093964835,157.027608338,0.602040816327,59,13.6465555556,33864.5187273
5,47-2221,"Structural Iron and Steel Workers",97.5093964835,157.027608338,0.602040816327,59,13.6465555556,33864.5187273
4,47-2230,"Solar Photovoltaic Installers",10.1201736324,8.13886225568,-0.2,-2,1.21133333333,35776.5879108
5,47-2231,"Solar Photovoltaic Installers",10.1201736324,8.13886225568,-0.2,-2,1.21133333333,35776.5879108
3,47-3000,"Helpers, Construction Trades",1095.49911063,855.547759061,-0.218264840183,-239,129.038166667,26729.2708527
4,47-3010,"Helpers, Construction Trades",1095.49911063,855.547759061,-0.218264840183,-239,129.038166667,26729.2708527
5,47-3011,"Helpers--Brickmasons, Blockmasons, Stonemasons, and Tile and Marble Setters",266.312858043,166.039737057,-0.375939849624,-100,30.8095,24790.4235478
5,47-3012,Helpers--Carpenters,290.803055752,138.113980307,-0.525773195876,-153,27.0901111111,30662.0520073
5,47-3013,Helpers--Electricians,164.857348629,237.98099801,0.442424242424,73,26.2665555556,19437.9024316
5,47-3014,"Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons",77.4032482888,52.0628386905,-0.324675324675,-25,8.375,25150.2217158
5,47-3015,"Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters",242.265578215,216.973348128,-0.103305785124,-25,31.2267777778,33671.879427
5,47-3016,Helpers--Roofers,6.75213269196,0.427290376734,-1,-7,0.607055555556,31440.4876456
5,47-3019,"Helpers, Construction Trades, All Other",47.1048890075,43.949566492,-0.063829787234,-3,4.66316666667,30584.7337788
3,47-4000,"Other Construction and Related Workers",368.769098045,567.724407545,0.539295392954,199,54.2387777778,35986.351783
4,47-4010,"Construction and Building Inspectors",113.184169964,239.156192494,1.11504424779,126,21.5723333333,42049.341317
5,47-4011,"Construction and Building Inspectors",113.184169964,239.156192494,1.11504424779,126,21.5723333333,42049.341317
4,47-4020,"Elevator Installers and Repairers",10.1638167117,2.67028154417,-0.7,-7,0.966,62640.0312141
5,47-4021,"Elevator Installers and Repairers",10.1638167117,2.67028154417,-0.7,-7,0.966,62640.0312141
4,47-4030,"Fence Erectors",22.9021434732,27.4371011431,0.173913043478,4,3.266,33962.804975
5,47-4031,"Fence Erectors",22.9021434732,27.4371011431,0.173913043478,4,3.266,33962.804975
4,47-4040,"Hazardous Materials Removal Workers",22.2446549894,10.1784413793,-0.545454545455,-12,1.83622222222,30830.6514616
5,47-4041,"Hazardous Materials Removal Workers",22.2446549894,10.1784413793,-0.545454545455,-12,1.83622222222,30830.6514616
4,47-4050,"Highway Maintenance Workers",158.317428078,235.798438459,0.493670886076,78,21.1335,32983.9221161
5,47-4051,"Highway Maintenance Workers",158.317428078,235.798438459,0.493670886076,78,21.1335,32983.9221161
4,47-4060,"Rail-Track Laying and Maintenance Equipment Operators",3.47820335305,5.91456033672,1,3,0.4915,55306.0472799
5,47-4061,"Rail-Track Laying and Maintenance Equipment Operators",3.47820335305,5.91456033672,1,3,0.4915,55306.0472799
4,47-4070,"Septic Tank Servicers and Sewer Pipe Cleaners",7.50907398924,9.26137205197,0.125,1,1.24438888889,28472.4080271
5,47-4071,"Septic Tank Servicers and Sewer Pipe Cleaners",7.50907398924,9.26137205197,0.125,1,1.24438888889,28472.4080271
4,47-4090,"Miscellaneous Construction and Related Workers",30.9696074862,37.308020137,0.193548387097,6,3.72877777778,34553.1442111
5,47-4098,"Miscellaneous Construction and Related Workers",30.9696074862,37.308020137,0.193548387097,6,3.72877777778,34553.1442111
3,47-5000,"Extraction Workers",64.1391968382,146.682984168,1.296875,83,16.3966111111,51177.3342581
4,47-5010,"Derrick, Rotary Drill, and Service Unit Operators, Oil, Gas, and Mining",1.36078008093,12.7739443738,12,12,0.634277777778,50867.8910057
5,47-5011,"Derrick Operators, Oil and Gas",0.0384115169168,0,0,0,0.0555,0
5,47-5012,"Rotary Drill Operators, Oil and Gas",0.0383508314225,0.0838537941596,0,0,0.0948888888889,54146.9510066
5,47-5013,"Service Unit Operators, Oil, Gas, and Mining",1.28401773259,12.6900905796,12,12,0.483888888889,50864.8256051
4,47-5020,"Earth Drillers, Except Oil and Gas",10.5332310504,8.21819832358,-0.272727272727,-3,1.54494444444,43577.7697301
5,47-5021,"Earth Drillers, Except Oil and Gas",10.5332310504,8.21819832358,-0.272727272727,-3,1.54494444444,43577.7697301
4,47-5030,"Explosives Workers, Ordnance Handling Experts, and Blasters",14.9860630578,36.7147882043,1.46666666667,22,3.4175,71308.5746975
5,47-5031,"Explosives Workers, Ordnance Handling Experts, and Blasters",14.9860630578,36.7147882043,1.46666666667,22,3.4175,71308.5746975
4,47-5040,"Mining Machine Operators",6.92423907702,24.1512948735,2.42857142857,17,2.58361111111,34808.3023893
5,47-5041,"Continuous Mining Machine Operators",3.34237115499,8.68095278195,2,6,0.968388888889,44577.519821
5,47-5042,"Mine Cutting and Channeling Machine Operators",1.23772351912,3.94448061963,3,3,0.669444444444,40048.0168809
5,47-5049,"Mining Machine Operators, All Other",2.34414440291,11.5258614719,5,10,0.945777777778,27821.1050801
4,47-5050,"Rock Splitters, Quarry",2.35641993421,15.8195587594,7,14,0.692722222222,54697.4026031
5,47-5051,"Rock Splitters, Quarry",2.35641993421,15.8195587594,7,14,0.692722222222,54697.4026031
4,47-5060,"Roof Bolters, Mining",0.266549087799,0.735593539687,,1,0.500222222222,41806.9822078
5,47-5061,"Roof Bolters, Mining",0.266549087799,0.735593539687,,1,0.500222222222,41806.9822078
4,47-5070,"Roustabouts, Oil and Gas",0.184218970471,11.5836671784,,12,1.06511111111,34731.9806121
5,47-5071,"Roustabouts, Oil and Gas",0.184218970471,11.5836671784,,12,1.06511111111,34731.9806121
4,47-5080,"Helpers--Extraction Workers",26.1474498561,36.0889639452,0.384615384615,10,5.61611111111,31344.502256
5,47-5081,"Helpers--Extraction Workers",26.1474498561,36.0889639452,0.384615384615,10,5.61611111111,31344.502256
4,47-5090,"Miscellaneous Extraction Workers",1.38024572352,0.596974969925,0,0,0.342055555556,63900.1788221
5,47-5099,"Extraction Workers, All Other",1.38024572352,0.596974969925,0,0,0.342055555556,63900.1788221
2,49-0000,"Installation, Maintenance, and Repair Occupations",8039.05677567,10888.0319175,0.354397313099,2849,901.212055556,38921.58229
3,49-1000,"Supervisors of Installation, Maintenance, and Repair Workers",719.906666868,895.344411374,0.243055555556,175,70.9292222222,54714.4794555
4,49-1010,"First-Line Supervisors of Mechanics, Installers, and Repairers",719.906666868,895.344411374,0.243055555556,175,70.9292222222,54714.4794555
5,49-1011,"First-Line Supervisors of Mechanics, Installers, and Repairers",719.906666868,895.344411374,0.243055555556,175,70.9292222222,54714.4794555
3,49-2000,"Electrical and Electronic Equipment Mechanics, Installers, and Repairers",726.23403549,946.768461145,0.304407713499,221,88.8216666667,39790.515496
4,49-2010,"Computer, Automated Teller, and Office Machine Repairers",194.505758191,210.595952101,0.0820512820513,16,22.2004444444,30310.6292469
5,49-2011,"Computer, Automated Teller, and Office Machine Repairers",194.505758191,210.595952101,0.0820512820513,16,22.2004444444,30310.6292469
4,49-2020,"Radio and Telecommunications Equipment Installers and Repairers",206.555217355,297.155511939,0.434782608696,90,26.7468333333,44393.4378073
5,49-2021,"Radio, Cellular, and Tower Equipment Installers and Repairs",3.85159368613,8.56787969583,1.25,5,0.658555555556,71313.7676111
5,49-2022,"Telecommunications Equipment Installers and Repairers, Except Line Installers",202.703623669,288.587632243,0.423645320197,86,26.0882222222,44080.8173259
4,49-2090,"Miscellaneous Electrical and Electronic Equipment Mechanics, Installers, and Repairers",325.173059943,439.016997105,0.350769230769,114,39.8744444444,40716.2502663
5,49-2091,"Avionics Technicians",17.7896084241,19.075304185,0.0555555555556,1,1.29822222222,57901.5636753
5,49-2092,"Electric Motor, Power Tool, and Related Repairers",25.5563600026,12.8250967137,-0.5,-13,1.74255555556,41581.8742644
5,49-2093,"Electrical and Electronics Installers and Repairers, Transportation Equipment",34.4659503473,23.2615585032,-0.323529411765,-11,2.44477777778,63075.8868034
5,49-2094,"Electrical and Electronics Repairers, Commercial and Industrial Equipment",89.1035234479,123.156009762,0.38202247191,34,9.44688888889,38467.8064883
5,49-2095,"Electrical and Electronics Repairers, Powerhouse, Substation, and Relay",33.0611681765,45.6860495677,0.393939393939,13,4.19372222222,60158.6570025
5,49-2096,"Electronic Equipment Installers and Repairers, Motor Vehicles",18.173274746,6.18844212576,-0.666666666667,-12,1.35394444444,31838.1728889
5,49-2097,"Electronic Home Entertainment Equipment Installers and Repairers",56.1063404533,68.7483382169,0.232142857143,13,6.92327777778,20418.9995327
5,49-2098,"Security and Fire Alarm Systems Installers",50.9168343454,140.07619803,1.74509803922,89,12.4709444444,40564.2794364
3,49-3000,"Vehicle and Mobile Equipment Mechanics, Installers, and Repairers",2587.19865822,3324.3497527,0.284885968303,737,279.8755,35869.4681558
4,49-3010,"Aircraft Mechanics and Service Technicians",147.828875811,139.534064874,-0.0540540540541,-8,10.7842777778,37120.8913951
5,49-3011,"Aircraft Mechanics and Service Technicians",147.828875811,139.534064874,-0.0540540540541,-8,10.7842777778,37120.8913951
4,49-3020,"Automotive Technicians and Repairers",1416.21355557,1704.79193533,0.204096045198,289,150.541777778,35154.8352518
5,49-3021,"Automotive Body and Related Repairers",309.531111431,398.435316223,0.283870967742,88,33.0437777778,40618.6077934
5,49-3022,"Automotive Glass Installers and Repairers",17.2635766387,32.4597867317,0.882352941176,15,2.27088888889,37749.5187317
5,49-3023,"Automotive Service Technicians and Mechanics",1089.4188675,1273.89683237,0.169880624426,185,115.227111111,33576.0350921
4,49-3030,"Bus and Truck Mechanics and Diesel Engine Specialists",593.495568743,844.075376514,0.423271500843,251,64.6084444444,40772.5896316
5,49-3031,"Bus and Truck Mechanics and Diesel Engine Specialists",593.495568743,844.075376514,0.423271500843,251,64.6084444444,40772.5896316
4,49-3040,"Heavy Vehicle and Mobile Equipment Service Technicians and Mechanics",127.16764014,184.828526584,0.456692913386,58,14.2822777778,40880.6683258
5,49-3041,"Farm Equipment Mechanics and Service Technicians",44.9449412712,48.6537740304,0.0888888888889,4,4.59138888889,33157.2842069
5,49-3042,"Mobile Heavy Equipment Mechanics, Except Engines",69.6729745166,126.104674333,0.8,56,8.70716666667,44377.0882947
5,49-3043,"Rail Car Repairers",12.5497243525,10.0700782204,-0.230769230769,-3,0.983722222222,49937.9144784
4,49-3050,"Small Engine Mechanics",73.4445155697,125.000363144,0.712328767123,52,10.4423333333,34480.1897928
5,49-3051,"Motorboat Mechanics and Service Technicians",30.2096906484,46.6929764587,0.566666666667,17,4.11922222222,39621.6398314
5,49-3052,"Motorcycle Mechanics",8.66169340751,9.12613216037,0,0,1.08527777778,25293.4026329
5,49-3053,"Outdoor Power Equipment and Other Small Engine Mechanics",34.5731315137,69.1812545246,0.971428571429,34,5.23777777778,33287.4694984
4,49-3090,"Miscellaneous Vehicle and Mobile Equipment Mechanics, Installers, and Repairers",229.048502391,326.119486257,0.423580786026,97,29.2164444444,24674.0863035
5,49-3091,"Bicycle Repairers",5.82030029465,2.29672177461,-0.666666666667,-4,0.472555555556,24035.7826069
5,49-3092,"Recreational Vehicle Service Technicians",5.40550846413,2.68971752363,-0.4,-2,0.448111111111,35509.6976659
5,49-3093,"Tire Repairers and Changers",217.822693632,321.133046958,0.47247706422,103,28.2957777778,24658.4736455
3,49-9000,"Other Installation, Maintenance, and Repair Occupations",4005.71741509,5721.56929229,0.428357463804,1716,461.585666667,38876.3144853
4,49-9010,"Control and Valve Installers and Repairers",70.1308117619,202.055320161,1.88571428571,132,10.1138888889,37030.2776982
5,49-9011,"Mechanical Door Repairers",25.2664186537,139.041826549,4.56,114,5.487,33236.0630777
5,49-9012,"Control and Valve Installers and Repairers, Except Mechanical Door",44.8643931082,63.0134936122,0.4,18,4.62688888889,51014.937863
4,49-9020,"Heating, Air Conditioning, and Refrigeration Mechanics and Installers",371.257152281,701.223538031,0.88948787062,330,49.1279444444,44139.7779755
5,49-9021,"Heating, Air Conditioning, and Refrigeration Mechanics and Installers",371.257152281,701.223538031,0.88948787062,330,49.1279444444,44139.7779755
4,49-9030,"Home Appliance Repairers",80.2620683465,74.2494062793,-0.075,-6,7.76327777778,32982.9424714
5,49-9031,"Home Appliance Repairers",80.2620683465,74.2494062793,-0.075,-6,7.76327777778,32982.9424714
4,49-9040,"Industrial Machinery Installation, Repair, and Maintenance Workers",650.436963956,1140.2676983,0.753846153846,490,77.2894444444,46469.863252
5,49-9041,"Industrial Machinery Mechanics",477.229350755,976.668054871,1.04821802935,500,62.2521111111,47401.132804
5,49-9043,"Maintenance Workers, Machinery",123.867165503,143.367127892,0.153225806452,19,12.3393333333,40410.3308445
5,49-9044,Millwrights,45.208968392,20.2325155413,-0.555555555556,-25,2.53772222222,41816.7054197
5,49-9045,"Refractory Materials Repairers, Except Brickmasons",4.13147930657,0,-1,-4,0.160333333333,0
4,49-9050,"Line Installers and Repairers",252.55587451,250.709224603,-0.00790513833992,-2,24.3352777778,49153.6906724
5,49-9051,"Electrical Power-Line Installers and Repairers",188.652903766,173.814353274,-0.0793650793651,-15,15.9143888889,47508.4236083
5,49-9052,"Telecommunications Line Installers and Repairers",63.9029707441,76.8948713294,0.203125,13,8.42088888889,54558.2233898
4,49-9060,"Precision Instrument and Equipment Repairers",73.1608491197,179.676775454,1.46575342466,107,13.1263333333,34821.2653178
5,49-9061,"Camera and Photographic Equipment Repairers",2.49744430402,0.901832476913,-0.5,-1,0.230888888889,35858.6235525
5,49-9062,"Medical Equipment Repairers",11.5789716387,71.9864758179,5,60,4.34277777778,35315.8604641
5,49-9063,"Musical Instrument Repairers and Tuners",17.3586927114,30.4677471104,0.764705882353,13,2.4785,34910.0181979
5,49-9064,"Watch Repairers",19.468224353,28.2515634557,0.473684210526,9,2.11122222222,39458.9226328
5,49-9069,"Precision Instrument and Equipment Repairers, All Other",22.2575161126,48.0691565935,1.18181818182,26,3.96294444444,32575.1552652
4,49-9070,"Maintenance and Repair Workers, General",1973.32956231,2467.22549632,0.250380131779,494,206.684611111,36547.6725739
5,49-9071,"Maintenance and Repair Workers, General",1973.32956231,2467.22549632,0.250380131779,494,206.684611111,36547.6725739
4,49-9080,"Wind Turbine Service Technicians",2.49891517948,4.6758829113,1.5,3,0.354722222222,33252.5729699
5,49-9081,"Wind Turbine Service Technicians",2.49891517948,4.6758829113,1.5,3,0.354722222222,33252.5729699
4,49-9090,"Miscellaneous Installation, Maintenance, and Repair Workers",532.085217628,701.485950233,0.317669172932,169,72.7901666667,30164.958971
5,49-9091,"Coin, Vending, and Amusement Machine Servicers and Repairers",61.8545741436,34.5099322454,-0.435483870968,-27,6.79877777778,30975.307475
5,49-9092,"Commercial Divers",5.07514146884,8.27624868146,0.6,3,0.8945,36522.5268617
5,49-9093,"Fabric Menders, Except Garment",2.2204681367,1.66847103413,0,0,0.139277777778,28859.1497827
5,49-9094,"Locksmiths and Safe Repairers",44.4582418518,120.617167301,1.75,77,10.766,41126.5124901
5,49-9095,"Manufactured Building and Mobile Home Installers",21.1362958678,6.573028528,-0.666666666667,-14,0.990888888889,26669.893056
5,49-9096,Riggers,21.6404368272,63.0449631672,1.86363636364,41,3.23938888889,21690.5272819
5,49-9097,"Signal and Track Switch Repairers",4.24818354463,4.39391456003,0,0,0.464277777778,63361.1514119
5,49-9098,"Helpers--Installation, Maintenance, and Repair Workers",175.03708328,126.006220297,-0.28,-49,19.7953888889,25375.4267111
5,49-9099,"Installation, Maintenance, and Repair Workers, All Other",196.414792508,336.396004419,0.714285714286,140,29.7017222222,32360.7304482
2,51-0000,"Production Occupations",24465.285141,21620.8391988,-0.116247700797,-2844,2573.8895,28198.691534
3,51-1000,"Supervisors of Production Workers",1667.07168588,1611.81901608,-0.0329934013197,-55,153.243611111,50287.1241655
4,51-1010,"First-Line Supervisors of Production and Operating Workers",1667.07168588,1611.81901608,-0.0329934013197,-55,153.243611111,50287.1241655
5,51-1011,"First-Line Supervisors of Production and Operating Workers",1667.07168588,1611.81901608,-0.0329934013197,-55,153.243611111,50287.1241655
3,51-2000,"Assemblers and Fabricators",3491.84782678,2450.27755914,-0.298396334479,-1042,320.354333333,29792.5748628
4,51-2010,"Aircraft Structure, Surfaces, Rigging, and Systems Assemblers",171.959443861,238.866037435,0.389534883721,67,13.6746111111,49672.8780786
5,51-2011,"Aircraft Structure, Surfaces, Rigging, and Systems Assemblers",171.959443861,238.866037435,0.389534883721,67,13.6746111111,49672.8780786
4,51-2020,"Electrical, Electronics, and Electromechanical Assemblers",785.674686346,579.665535715,-0.262086513995,-206,71.9931111111,35621.2304937
5,51-2021,"Coil Winders, Tapers, and Finishers",107.558996152,27.8377354341,-0.740740740741,-80,7.02727777778,39708.1372941
5,51-2028,"Electrical, Electronic, and Electromechanical Assemblers, Except Coil Winders, Tapers, and Finishers",678.115690195,551.827800281,-0.185840707965,-126,64.9658333333,35219.9051977
4,51-2030,"Engine and Other Machine Assemblers",39.289631864,15.0962047632,-0.615384615385,-24,3.04711111111,25381.7780278
5,51-2031,"Engine and Other Machine Assemblers",39.289631864,15.0962047632,-0.615384615385,-24,3.04711111111,25381.7780278
4,51-2040,"Structural Metal Fabricators and Fitters",163.972184061,107.303567914,-0.34756097561,-57,13.8196111111,39885.8012507
5,51-2041,"Structural Metal Fabricators and Fitters",163.972184061,107.303567914,-0.34756097561,-57,13.8196111111,39885.8012507
4,51-2090,"Miscellaneous Assemblers and Fabricators",2330.95188064,1509.34621331,-0.352638352638,-822,217.819833333,25845.9661683
5,51-2091,"Fiberglass Laminators and Fabricators",48.2369302912,6.78094967747,-0.854166666667,-41,2.01905555556,30033.9744109
5,51-2093,"Timing Device Assemblers and Adjusters",2.27003982488,0,-1,-2,0.088,0
5,51-2098,"Assemblers and Fabricators, All Other, Including Team Assemblers",2280.44491053,1502.56526363,-0.340789473684,-777,215.712833333,25830.1744454
3,51-3000,"Food Processing Workers",3938.20200344,4488.43337199,0.139664804469,550,512.019166667,24290.5455139
4,51-3010,Bakers,225.254231242,351.736760507,0.564444444444,127,36.4046666667,24954.8162078
5,51-3011,Bakers,225.254231242,351.736760507,0.564444444444,127,36.4046666667,24954.8162078
4,51-3020,"Butchers and Other Meat, Poultry, and Fish Processing Workers",2970.23901357,3235.8641143,0.0895622895623,266,378.856055556,24406.8089313
5,51-3021,"Butchers and Meat Cutters",205.126949715,164.212028598,-0.2,-41,21.8472777778,30849.5550025
5,51-3022,"Meat, Poultry, and Fish Cutters and Trimmers",1545.97977041,2538.75836916,0.642302716688,993,260.731888889,24411.3252
5,51-3023,"Slaughterers and Meat Packers",1219.13229344,532.89371654,-0.56275635767,-686,96.2769444444,23255.0399036
4,51-3090,"Miscellaneous Food Processing Workers",742.708758628,900.832497183,0.21265141319,158,96.7584444444,23458.1640059
5,51-3091,"Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders",43.4093510362,49.0890167451,0.139534883721,6,6.30111111111,31567.4478099
5,51-3092,"Food Batchmakers",248.316909852,408.081715221,0.645161290323,160,44.4341666667,20774.452488
5,51-3093,"Food Cooking Machine Operators and Tenders",63.8457843485,16.3863708569,-0.75,-48,8.05038888889,25811.5127392
5,51-3099,"Food Processing Workers, All Other",387.136713391,427.27539436,0.103359173127,40,37.9727777778,24652.9653267
3,51-4000,"Metal Workers and Plastic Workers",4453.50254586,2995.02502702,-0.327570722946,-1459,375.599833333,34901.1582197
4,51-4010,"Computer Control Programmers and Operators",274.977031625,224.120477462,-0.185454545455,-51,25.0236666667,39827.2112222
5,51-4011,"Computer-Controlled Machine Tool Operators, Metal and Plastic",251.857078344,199.347701965,-0.210317460317,-53,22.5343888889,38452.5671985
5,51-4012,"Computer Numerically Controlled Machine Tool Programmers, Metal and Plastic",23.1199532809,24.7727754972,0.0869565217391,2,2.48927777778,56105.2317003
4,51-4020,"Forming Machine Setters, Operators, and Tenders, Metal and Plastic",343.278182725,215.706942364,-0.370262390671,-127,30.4377222222,28824.52643
5,51-4021,"Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic",213.568892619,184.110635135,-0.140186915888,-30,23.3290555556,28545.5979688
5,51-4022,"Forging Machine Setters, Operators, and Tenders, Metal and Plastic",63.3702327981,8.10198300119,-0.873015873016,-55,3.08238888889,40813.5775063
5,51-4023,"Rolling Machine Setters, Operators, and Tenders, Metal and Plastic",66.3390573077,23.4943242272,-0.651515151515,-43,4.02622222222,29423.0749076
4,51-4030,"Machine Tool Cutting Setters, Operators, and Tenders, Metal and Plastic",1454.93276082,711.730959136,-0.510652920962,-743,107.552111111,35334.5637459
5,51-4031,"Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic",936.010501749,537.866578954,-0.425213675214,-398,73.8370555556,39516.3862702
5,51-4032,"Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic",77.929303153,2.76638297825,-0.961538461538,-75,2.93866666667,36901.2859809
5,51-4033,"Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic",248.852060949,131.404448366,-0.473895582329,-118,20.0072222222,21443.1164164
5,51-4034,"Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic",150.471325853,27.4288604986,-0.82,-123,8.27005555556,22121.2427725
5,51-4035,"Milling and Planing Machine Setters, Operators, and Tenders, Metal and Plastic",41.6695691204,12.2646883388,-0.714285714286,-30,2.49905555556,45853.092621
4,51-4040,Machinists,506.291702588,408.46222861,-0.193675889328,-98,45.5821666667,35665.7629062
5,51-4041,Machinists,506.291702588,408.46222861,-0.193675889328,-98,45.5821666667,35665.7629062
4,51-4050,"Metal Furnace Operators, Tenders, Pourers, and Casters",23.1020625349,63.5014745907,1.78260869565,41,4.89505555556,25700.5823804
5,51-4051,"Metal-Refining Furnace Operators and Tenders",12.7880976938,26.7735167046,1.07692307692,14,2.18394444444,27583.1645015
5,51-4052,"Pourers and Casters, Metal",10.3139648411,36.7279578861,2.7,27,2.71111111111,24117.1763549
4,51-4060,"Model Makers and Patternmakers, Metal and Plastic",22.2071805857,1.16173956838,-0.954545454545,-21,1.17688888889,41258.88
5,51-4061,"Model Makers, Metal and Plastic",8.42862297481,0,-1,-8,0.561388888889,0
5,51-4062,"Patternmakers, Metal and Plastic",13.7785576109,1.16173956838,-0.928571428571,-13,0.615555555556,41258.88
4,51-4070,"Molders and Molding Machine Setters, Operators, and Tenders, Metal and Plastic",292.614915163,274.252520594,-0.0648464163823,-19,26.7283333333,34375.6711004
5,51-4071,"Foundry Mold and Coremakers",9.05724349923,3.34725839699,-0.666666666667,-6,0.798166666667,35429.3297904
5,51-4072,"Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic",283.557671664,270.905262197,-0.0457746478873,-13,25.9301666667,34365.3676225
4,51-4080,"Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic",123.498083982,70.8768814274,-0.422764227642,-52,8.36977777778,29583.4267669
5,51-4081,"Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic",123.498083982,70.8768814274,-0.422764227642,-52,8.36977777778,29583.4267669
4,51-4110,"Tool and Die Makers",226.920973989,76.7009166498,-0.660792951542,-150,13.1578888889,44072.3395522
5,51-4111,"Tool and Die Makers",226.920973989,76.7009166498,-0.660792951542,-150,13.1578888889,44072.3395522
4,51-4120,"Welding, Soldering, and Brazing Workers",827.598575169,749.176603883,-0.0954106280193,-79,84.7767222222,40816.4014742
5,51-4121,"Welders, Cutters, Solderers, and Brazers",577.768334019,552.05467218,-0.0449826989619,-26,56.5717777778,36164.2583799
5,51-4122,"Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders",249.83024115,197.121931704,-0.212,-53,28.2049444444,46645.7764706
4,51-4190,"Miscellaneous Metal Workers and Plastic Workers",358.08107667,199.334282739,-0.444134078212,-159,27.8996111111,23272.4706263
5,51-4191,"Heat Treating Equipment Setters, Operators, and Tenders, Metal and Plastic",16.385733025,7.86897291118,-0.5,-8,1.71611111111,40078.1095225
5,51-4192,"Layout Workers, Metal and Plastic",21.4635104161,4.13787591118,-0.809523809524,-17,0.968277777778,50333.4539942
5,51-4193,"Plating and Coating Machine Setters, Operators, and Tenders, Metal and Plastic",58.142520251,73.2869225536,0.258620689655,15,7.72727777778,29230.6419821
5,51-4194,"Tool Grinders, Filers, and Sharpeners",47.6045779184,26.0229296359,-0.458333333333,-22,5.19211111111,18724.9535644
5,51-4199,"Metal Workers and Plastic Workers, All Other",214.484735059,88.0175817276,-0.588785046729,-126,12.2958888889,19391.4918196
3,51-5100,"Printing Workers",670.491966773,548.43784395,-0.182089552239,-122,65.5803888889,29754.0367684
4,51-5110,"Printing Workers",670.491966773,548.43784395,-0.182089552239,-122,65.5803888889,29754.0367684
5,51-5111,"Prepress Technicians and Workers",100.028153297,47.6368447962,-0.52,-52,8.93177777778,35082.8221168
5,51-5112,"Printing Press Operators",485.443940846,455.850385504,-0.059793814433,-29,47.6752777778,28880.0135804
5,51-5113,"Print Binding and Finishing Workers",85.0198726299,44.9506136496,-0.470588235294,-40,8.97333333333,34083.9422189
3,51-6000,"Textile, Apparel, and Furnishings Workers",1260.87305035,1030.14217177,-0.183187946075,-231,137.030777778,21397.4426216
4,51-6010,"Laundry and Dry-Cleaning Workers",293.10545823,290.177479368,-0.0102389078498,-3,41.3275,22136.2759464
5,51-6011,"Laundry and Dry-Cleaning Workers",293.10545823,290.177479368,-0.0102389078498,-3,41.3275,22136.2759464
4,51-6020,"Pressers, Textile, Garment, and Related Materials",189.781039377,82.6298231965,-0.563157894737,-107,15.9383888889,21671.2575999
5,51-6021,"Pressers, Textile, Garment, and Related Materials",189.781039377,82.6298231965,-0.563157894737,-107,15.9383888889,21671.2575999
4,51-6030,"Sewing Machine Operators",223.049470769,208.366031179,-0.067264573991,-15,23.1891666667,20968.0283046
5,51-6031,"Sewing Machine Operators",223.049470769,208.366031179,-0.067264573991,-15,23.1891666667,20968.0283046
4,51-6040,"Shoe and Leather Workers",24.9119609195,23.7837384439,-0.04,-1,3.15572222222,24035.2543714
5,51-6041,"Shoe and Leather Workers and Repairers",23.131279815,23.3560321038,0,0,2.89044444444,23964.4506564
5,51-6042,"Shoe Machine Operators and Tenders",1.78068110444,0.427706340078,-1,-2,0.265277777778,28777.7926055
4,51-6050,"Tailors, Dressmakers, and Sewers",162.565494535,187.060434374,0.147239263804,24,23.5565,23160.3820453
5,51-6051,"Sewers, Hand",25.171890198,32.4373707551,0.28,7,3.16994444444,23325.4208179
5,51-6052,"Tailors, Dressmakers, and Custom Sewers",137.393604337,154.623063619,0.131386861314,18,20.3865,23120.023605
4,51-6060,"Textile Machine Setters, Operators, and Tenders",183.462439361,72.1219833882,-0.606557377049,-111,10.5693888889,18439.3347714
5,51-6061,"Textile Bleaching and Dyeing Machine Operators and Tenders",23.4115442901,0.267163899804,-1,-23,1.37605555556,19045.6832659
5,51-6062,"Textile Cutting Machine Setters, Operators, and Tenders",16.4182060646,6.89279832383,-0.5625,-9,0.955222222222,23034.6832003
5,51-6063,"Textile Knitting and Weaving Machine Setters, Operators, and Tenders",69.4499975823,1.63870564926,-0.971014492754,-67,3.18505555556,19435.4715749
5,51-6064,"Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders",74.1826914242,63.3233155153,-0.148648648649,-11,5.05305555556,18355.7028722
4,51-6090,"Miscellaneous Textile, Apparel, and Furnishings Workers",183.997187156,166.002681826,-0.0978260869565,-18,19.2941111111,20528.9812344
5,51-6091,"Extruding and Forming Machine Setters, Operators, and Tenders, Synthetic and Glass Fibers",35.3253012031,20.9964476926,-0.4,-14,3.47138888889,18368.2260844
5,51-6092,"Fabric and Apparel Patternmakers",11.3002573004,6.82205014904,-0.363636363636,-4,1.09944444444,40775.3514391
5,51-6093,Upholsterers,105.56670661,119.42682185,0.122641509434,13,11.7113333333,19312.0833143
5,51-6099,"Textile, Apparel, and Furnishings Workers, All Other",31.8049220424,18.7573621347,-0.40625,-13,3.01194444444,48197.8495155
3,51-7000,Woodworkers,607.055242554,619.318885584,0.0197693574959,12,64.4245555556,27007.2255416
4,51-7010,"Cabinetmakers and Bench Carpenters",344.544440962,379.848016184,0.101449275362,35,34.7783888889,28853.6542145
5,51-7011,"Cabinetmakers and Bench Carpenters",344.544440962,379.848016184,0.101449275362,35,34.7783888889,28853.6542145
4,51-7020,"Furniture Finishers",31.0720169529,20.9048509635,-0.322580645161,-10,2.93838888889,28528.7248105
5,51-7021,"Furniture Finishers",31.0720169529,20.9048509635,-0.322580645161,-10,2.93838888889,28528.7248105
4,51-7030,"Model Makers and Patternmakers, Wood",9.19362495194,4.12620786129,-0.555555555556,-5,0.823611111111,38050.93041
5,51-7031,"Model Makers, Wood",3.53336578421,2.77586562004,-0.25,-1,0.503944444444,39601.6881224
5,51-7032,"Patternmakers, Wood",5.66025916773,1.35034224125,-0.833333333333,-5,0.319666666667,35430.1836008
4,51-7040,"Woodworking Machine Setters, Operators, and Tenders",192.284826845,184.423287565,-0.0416666666667,-8,22.1510555556,24079.3825747
5,51-7041,"Sawing Machine Setters, Operators, and Tenders, Wood",64.535752619,61.7568630849,-0.0461538461538,-3,6.28288888889,25112.5645329
5,51-7042,"Woodworking Machine Setters, Operators, and Tenders, Except Sawing",127.749074226,122.666424481,-0.0390625,-5,15.8681666667,23814.3524272
4,51-7090,"Miscellaneous Woodworkers",29.9603328417,30.0165230102,0,0,3.73316666667,21843.553852
5,51-7099,"Woodworkers, All Other",29.9603328417,30.0165230102,0,0,3.73316666667,21843.553852
3,51-8000,"Plant and System Operators",271.781144057,461.716134709,0.698529411765,190,34.715,44398.4578928
4,51-8010,"Power Plant Operators, Distributors, and Dispatchers",23.005089928,69.9581890066,2.04347826087,47,4.42022222222,44199.95648
5,51-8011,"Nuclear Power Reactor Operators",0.0609784520197,0.0955890422607,0,0,0.155833333333,84913.92
5,51-8012,"Power Distributors and Dispatchers",8.6861264004,38.9468793117,3.33333333333,30,2.07433333333,35066.6817974
5,51-8013,"Power Plant Operators",14.2579850756,30.9157206526,1.21428571429,17,2.19005555556,76368.630866
4,51-8020,"Stationary Engineers and Boiler Operators",47.840879369,63.8354393874,0.333333333333,16,5.80466666667,49254.4494694
5,51-8021,"Stationary Engineers and Boiler Operators",47.840879369,63.8354393874,0.333333333333,16,5.80466666667,49254.4494694
4,51-8030,"Water and Wastewater Treatment Plant and System Operators",136.916540934,282.076052064,1.05839416058,145,18.5325555556,40792.6205429
5,51-8031,"Water and Wastewater Treatment Plant and System Operators",136.916540934,282.076052064,1.05839416058,145,18.5325555556,40792.6205429
4,51-8090,"Miscellaneous Plant and System Operators",64.0186338258,45.8464542514,-0.28125,-18,5.9575,55794.2263989
5,51-8091,"Chemical Plant and System Operators",19.7054380293,7.49716748128,-0.65,-13,1.17216666667,40467.3289226
5,51-8092,"Gas Plant Operators",20.8728343091,24.1162960434,0.142857142857,3,3.05366666667,56866.8032079
5,51-8093,"Petroleum Pump System Operators, Refinery Operators, and Gaugers",1.74462778343,9.16589528098,3.5,7,0.616166666667,58716.0259302
5,51-8099,"Plant and System Operators, All Other",21.6957337039,5.06709544564,-0.772727272727,-17,1.1155,58296.3000258
3,51-9000,"Other Production Occupations",8104.45967534,7415.66918853,-0.0848963474827,-688,910.921888889,28266.8887228
4,51-9010,"Chemical Processing Machine Setters, Operators, and Tenders",155.957376811,169.955904999,0.0897435897436,14,15.8378888889,30767.6377515
5,51-9011,"Chemical Equipment Operators and Tenders",64.5690790594,75.9473678413,0.169230769231,11,5.94827777778,29626.3020784
5,51-9012,"Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders",91.3882977513,94.008537158,0.032967032967,3,9.88961111111,33356.2094645
4,51-9020,"Crushing, Grinding, Polishing, Mixing, and Blending Workers",393.350273806,313.219123868,-0.203562340967,-80,38.5618888889,31157.7613981
5,51-9021,"Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders",94.8861435585,56.4646731636,-0.410526315789,-39,8.22322222222,31511.5410331
5,51-9022,"Grinding and Polishing Workers, Hand",118.69123173,63.9195136904,-0.46218487395,-55,9.16727777778,25956.9059931
5,51-9023,"Mixing and Blending Machine Setters, Operators, and Tenders",179.772898518,192.834937014,0.0722222222222,13,21.1713888889,33036.1707904
4,51-9030,"Cutting Workers",244.929430034,105.764983894,-0.567346938776,-139,23.1259444444,50711.427725
5,51-9031,"Cutters and Trimmers, Hand",158.451217851,47.9237647335,-0.696202531646,-110,12.8586666667,56361.9528191
5,51-9032,"Cutting and Slicing Machine Setters, Operators, and Tenders",86.4782121838,57.8412191604,-0.325581395349,-28,10.2672777778,37791.710902
4,51-9040,"Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders",205.573953914,229.223975763,0.111650485437,23,21.6686666667,42137.2008687
5,51-9041,"Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders",205.573953914,229.223975763,0.111650485437,23,21.6686666667,42137.2008687
4,51-9050,"Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders",86.1132475955,50.7112961675,-0.406976744186,-35,5.97577777778,76525.1317652
5,51-9051,"Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders",86.1132475955,50.7112961675,-0.406976744186,-35,5.97577777778,76525.1317652
4,51-9060,"Inspectors, Testers, Sorters, Samplers, and Weighers",1100.64039867,1397.53069361,0.269754768392,297,139.899333333,30296.6467941
5,51-9061,"Inspectors, Testers, Sorters, Samplers, and Weighers",1100.64039867,1397.53069361,0.269754768392,297,139.899333333,30296.6467941
4,51-9070,"Jewelers and Precious Stone and Metal Workers",150.39865837,203.568971151,0.36,54,20.2841666667,34506.4346276
5,51-9071,"Jewelers and Precious Stone and Metal Workers",150.39865837,203.568971151,0.36,54,20.2841666667,34506.4346276
4,51-9080,"Medical, Dental, and Ophthalmic Laboratory Technicians",183.583519664,120.475879166,-0.347826086957,-64,22.7906666667,39329.7113527
5,51-9081,"Dental Laboratory Technicians",97.7012537071,20.6956033608,-0.785714285714,-77,8.76461111111,75305.8650611
5,51-9082,"Medical Appliance Technicians",14.7781148823,2.54564386453,-0.8,-12,1.58844444444,34212.3648513
5,51-9083,"Ophthalmic Laboratory Technicians",71.1041510741,97.234631941,0.366197183099,26,12.4376111111,37867.5438452
4,51-9110,"Packaging and Filling Machine Operators and Tenders",886.313643893,829.359154961,-0.0643340857788,-57,90.0906111111,28974.3987709
5,51-9111,"Packaging and Filling Machine Operators and Tenders",886.313643893,829.359154961,-0.0643340857788,-57,90.0906111111,28974.3987709
4,51-9120,"Painting Workers",332.034798619,263.661785947,-0.204819277108,-68,31.2191666667,32801.8414409
5,51-9121,"Coating, Painting, and Spraying Machine Setters, Operators, and Tenders",205.840885861,178.155008666,-0.135922330097,-28,20.0073888889,31992.7641586
5,51-9122,"Painters, Transportation Equipment",36.4455163086,33.7864299919,-0.0555555555556,-2,3.67994444444,34963.7532169
5,51-9123,"Painting, Coating, and Decorating Workers",89.7483964492,51.7203472887,-0.422222222222,-38,7.53183333333,35669.539852
4,51-9140,"Semiconductor Processors",12.3371981771,2.65365538706,-0.75,-9,0.650888888889,39657.6925726
5,51-9141,"Semiconductor Processors",12.3371981771,2.65365538706,-0.75,-9,0.650888888889,39657.6925726
4,51-9150,"Photographic Process Workers and Processing Machine Operators",46.0612392355,14.8916472789,-0.673913043478,-31,4.50305555556,37627.5621964
5,51-9151,"Photographic Process Workers and Processing Machine Operators",46.0612392355,14.8916472789,-0.673913043478,-31,4.50305555556,37627.5621964
4,51-9190,"Miscellaneous Production Workers",4307.16593655,3714.65211633,-0.137450661713,-592,496.313833333,25915.300131
5,51-9191,"Adhesive Bonding Machine Operators and Tenders",510.452668533,308.966167943,-0.394117647059,-201,43.0688333333,31959.5936626
5,51-9192,"Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders",57.1217075291,41.4290684299,-0.280701754386,-16,8.51783333333,27774.6676387
5,51-9193,"Cooling and Freezing Equipment Operators and Tenders",132.744303503,109.897769611,-0.172932330827,-23,13.3346111111,27870.5021628
5,51-9194,"Etchers and Engravers",35.6892581949,47.6553596333,0.333333333333,12,4.91494444444,22888.9084568
5,51-9195,"Molders, Shapers, and Casters, Except Metal and Plastic",240.265665084,296.727025279,0.2375,57,25.9148333333,37814.893284
5,51-9196,"Paper Goods Machine Setters, Operators, and Tenders",129.863409078,212.0029157,0.630769230769,82,18.0101666667,30707.3716027
5,51-9197,"Tire Builders",3.33745488019,2.57481002872,0,0,0.547722222222,41970.24
5,51-9198,"Helpers--Production Workers",2978.57103115,2427.44430393,-0.185297079557,-552,353.052222222,24681.0469895
5,51-9199,"Production Workers, All Other",219.120438599,267.954695775,0.223744292237,49,28.9526666667,25820.1409725
2,53-0000,"Transportation and Material Moving Occupations",20181.5007754,26976.3088272,0.336636606877,6794,2752.97944444,34467.2107832
3,53-1000,"Supervisors of Transportation and Material Moving Workers",800.79866797,972.176021146,0.213483146067,171,92.1145555556,56646.2087665
4,53-1010,"Aircraft Cargo Handling Supervisors",5.34882073643,6.60212026734,0.4,2,0.428222222222,53302.4974152
5,53-1011,"Aircraft Cargo Handling Supervisors",5.34882073643,6.60212026734,0.4,2,0.428222222222,53302.4974152
4,53-1040,"First-line Supervisors of Transportation and Material Moving Workers, Except Aircraft Cargo Handling Supervisors",795.449847233,965.573900878,0.215094339623,171,91.6863333333,56672.3386567
5,53-1048,"First-line Supervisors of Transportation and Material Moving Workers, Except Aircraft Cargo Handling Supervisors",795.449847233,965.573900878,0.215094339623,171,91.6863333333,56672.3386567
3,53-2000,"Air Transportation Workers",184.591389786,338.804649733,0.832432432432,154,25.592,80611.8207041
4,53-2010,"Aircraft Pilots and Flight Engineers",120.331569867,211.518374843,0.766666666667,92,15.5349444444,82928.8343299
5,53-2011,"Airline Pilots, Copilots, and Flight Engineers",78.9256994028,140.949454436,0.784810126582,62,10.2751111111,90270.5919807
5,53-2012,"Commercial Pilots",41.4058704644,70.5689204069,0.731707317073,30,5.25983333333,57428.0163998
4,53-2020,"Air Traffic Controllers and Airfield Operations Specialists",23.7800692546,37.1444012178,0.541666666667,13,2.9405,84073.8961104
5,53-2021,"Air Traffic Controllers",21.7289532305,25.9161124083,0.181818181818,4,2.5195,93693.2181895
5,53-2022,"Airfield Operations Specialists",2.05111602406,11.2282888095,4.5,9,0.421,46987.2
4,53-2030,"Flight Attendants",40.479750664,90.1418736714,1.25,50,7.11655555556,77292.9723138
5,53-2031,"Flight Attendants",40.479750664,90.1418736714,1.25,50,7.11655555556,77292.9723138
3,53-3000,"Motor Vehicle Operators",11181.0783344,16411.958776,0.467847240855,5231,1552.85288889,39699.9073295
4,53-3010,"Ambulance Drivers and Attendants, Except Emergency Medical Technicians",11.9777555583,10.7612626373,-0.0833333333333,-1,1.83705555556,25372.625783
5,53-3011,"Ambulance Drivers and Attendants, Except Emergency Medical Technicians",11.9777555583,10.7612626373,-0.0833333333333,-1,1.83705555556,25372.625783
4,53-3020,"Bus Drivers",533.045232905,897.852390953,0.684803001876,365,86.8404444444,22895.3556401
5,53-3021,"Bus Drivers, Transit and Intercity ",142.107031983,199.503315321,0.408450704225,58,18.9656666667,30292.8617203
5,53-3022,"Bus Drivers, School or Special Client ",390.938200922,698.349075633,0.785166240409,307,67.8747777778,22665.0353124
4,53-3030,"Driver/Sales Workers and Truck Drivers",10438.5308228,14035.0486357,0.344477440368,3596,1408.81222222,42061.3931699
5,53-3031,"Driver/Sales Workers",585.713698488,828.183274568,0.412969283276,242,75.0829444444,21627.666252
5,53-3032,"Heavy and Tractor-Trailer Truck Drivers",8658.91282996,11851.6715095,0.368749278208,3193,1196.95633333,44525.5270109
5,53-3033,"Light Truck or Delivery Services Drivers",1193.90429436,1355.19385161,0.134840871022,161,136.772944444,27842.7307383
4,53-3040,"Taxi Drivers and Chauffeurs",97.9625190659,1308.2915115,12.3469387755,1210,36.3986666667,18234.0358017
5,53-3041,"Taxi Drivers and Chauffeurs",97.9625190659,1308.2915115,12.3469387755,1210,36.3986666667,18234.0358017
4,53-3090,"Miscellaneous Motor Vehicle Operators",99.5620040878,160.004975224,0.6,60,18.9645,27533.8657854
5,53-3099,"Motor Vehicle Operators, All Other",99.5620040878,160.004975224,0.6,60,18.9645,27533.8657854
3,53-4000,"Rail Transportation Workers",53.1724903357,43.8775377343,-0.169811320755,-9,4.59594444444,60091.8320053
4,53-4010,"Locomotive Engineers and Operators",29.8172860645,14.0295806929,-0.533333333333,-16,1.77977777778,62412.6599791
5,53-4011,"Locomotive Engineers",10.4406582873,12.7992365074,0.3,3,1.19644444444,64355.2
5,53-4012,"Locomotive Firers",0.0566132921623,0.168244734727,0,0,0.0398333333333,57451.68
5,53-4013,"Rail Yard Engineers, Dinkey Operators, and Hostlers",19.320014485,1.06209945084,-0.947368421053,-18,0.5435,35776
4,53-4020,"Railroad Brake, Signal, and Switch Operators",5.61091700326,4.52142439925,-0.166666666667,-1,0.639944444444,43139.2
5,53-4021,"Railroad Brake, Signal, and Switch Operators",5.61091700326,4.52142439925,-0.166666666667,-1,0.639944444444,43139.2
4,53-4030,"Railroad Conductors and Yardmasters",11.0173528464,17.9986037846,0.636363636364,7,1.41138888889,62161.7925209
5,53-4031,"Railroad Conductors and Yardmasters",11.0173528464,17.9986037846,0.636363636364,7,1.41138888889,62161.7925209
4,53-4040,"Subway and Streetcar Operators",3.94882462007,6.58759724097,0.75,3,0.582,61364.16
5,53-4041,"Subway and Streetcar Operators",3.94882462007,6.58759724097,0.75,3,0.582,61364.16
4,53-4090,"Miscellaneous Rail Transportation Workers",2.77810980149,0.740331616566,-0.666666666667,-2,0.182833333333,49870.08
5,53-4099,"Rail Transportation Workers, All Other",2.77810980149,0.740331616566,-0.666666666667,-2,0.182833333333,49870.08
3,53-5000,"Water Transportation Workers",32.1742834633,48.4517985788,0.5,16,3.93916666667,46259.0555815
4,53-5010,"Sailors and Marine Oilers",11.2564322852,14.7935314882,0.363636363636,4,1.46238888889,29384.8069639
5,53-5011,"Sailors and Marine Oilers",11.2564322852,14.7935314882,0.363636363636,4,1.46238888889,29384.8069639
4,53-5020,"Ship and Boat Captains and Operators",17.9980349326,27.1435402856,0.5,9,1.97788888889,67100.7795658
5,53-5021,"Captains, Mates, and Pilots of Water Vessels",15.0312749721,24.8978966101,0.666666666667,10,1.70494444444,78328.7500846
5,53-5022,"Motorboat Operators",2.96675996054,2.24564367545,-0.333333333333,-1,0.272888888889,41150.4642006
4,53-5030,"Ship Engineers",2.91981624545,6.51472680509,1.33333333333,4,0.498888888889,56651.6205874
5,53-5031,"Ship Engineers",2.91981624545,6.51472680509,1.33333333333,4,0.498888888889,56651.6205874
3,53-6000,"Other Transportation Workers",300.433813756,369.748738598,0.233333333333,70,43.8401111111,25551.9542734
4,53-6010,"Bridge and Lock Tenders",0.369970621224,0.518597017054,,1,0.0826666666667,44703.36
5,53-6011,"Bridge and Lock Tenders",0.369970621224,0.518597017054,,1,0.0826666666667,44703.36
4,53-6020,"Parking Lot Attendants",132.549773103,145.158050394,0.0902255639098,12,19.6776111111,22507.6948907
5,53-6021,"Parking Lot Attendants",132.549773103,145.158050394,0.0902255639098,12,19.6776111111,22507.6948907
4,53-6030,"Automotive and Watercraft Service Attendants",99.9695791187,154.486614098,0.54,54,17.2943333333,25666.7917817
5,53-6031,"Automotive and Watercraft Service Attendants",99.9695791187,154.486614098,0.54,54,17.2943333333,25666.7917817
4,53-6040,"Traffic Technicians",3.35386790252,5.30366478203,0.666666666667,2,0.423055555556,42903.6954153
5,53-6041,"Traffic Technicians",3.35386790252,5.30366478203,0.666666666667,2,0.423055555556,42903.6954153
4,53-6050,"Transportation Inspectors",24.7029473522,32.1671934155,0.28,7,2.4435,74930.7046004
5,53-6051,"Transportation Inspectors",24.7029473522,32.1671934155,0.28,7,2.4435,74930.7046004
4,53-6060,"Transportation Attendants, Except Flight Attendants",8.51313981817,13.6657350027,0.555555555556,5,1.336,22801.5193458
5,53-6061,"Transportation Attendants, Except Flight Attendants ",8.51313981817,13.6657350027,0.555555555556,5,1.336,22801.5193458
4,53-6090,"Miscellaneous Transportation Workers",30.9745358405,18.4488838893,-0.41935483871,-13,2.58294444444,29304.3732515
5,53-6099,"Transportation Workers, All Other",30.9745358405,18.4488838893,-0.41935483871,-13,2.58294444444,29304.3732515
3,53-7000,"Material Moving Workers",7629.25179568,8791.29130539,0.152313540438,1162,1030.04477778,26494.6136157
4,53-7010,"Conveyor Operators and Tenders",100.194527476,37.8744955727,-0.62,-62,8.268,28548.2267919
5,53-7011,"Conveyor Operators and Tenders",100.194527476,37.8744955727,-0.62,-62,8.268,28548.2267919
4,53-7020,"Crane and Tower Operators",37.7244460297,39.3722778548,0.0263157894737,1,4.09327777778,38805.7419552
5,53-7021,"Crane and Tower Operators",37.7244460297,39.3722778548,0.0263157894737,1,4.09327777778,38805.7419552
4,53-7030,"Dredge, Excavating, and Loading Machine Operators",81.4855403922,53.4819338669,-0.345679012346,-28,8.38872222222,40851.8044695
5,53-7031,"Dredge Operators",5.30677664225,3.56265146923,-0.2,-1,0.810166666667,49980.7751302
5,53-7032,"Excavating and Loading Machine and Dragline Operators",74.8198056164,48.0607415232,-0.36,-27,7.47427777778,40632.0767233
5,53-7033,"Loading Machine Operators, Underground Mining",1.35895813349,1.85854087444,1,1,0.104277777778,34573.6508871
4,53-7040,"Hoist and Winch Operators",2.94068276,1.92601704802,-0.333333333333,-1,0.264666666667,59324.8167153
5,53-7041,"Hoist and Winch Operators",2.94068276,1.92601704802,-0.333333333333,-1,0.264666666667,59324.8167153
4,53-7050,"Industrial Truck and Tractor Operators",1517.49834536,1512.19289518,-0.00329597890574,-5,155.852111111,34457.2965089
5,53-7051,"Industrial Truck and Tractor Operators",1517.49834536,1512.19289518,-0.00329597890574,-5,155.852111111,34457.2965089
4,53-7060,"Laborers and Material Movers, Hand",5577.21517569,6891.32988055,0.235610543303,1314,819.313833333,24933.0748873
5,53-7061,"Cleaners of Vehicles and Equipment",836.962311425,1126.65799331,0.346475507766,290,134.245555556,23171.1004469
5,53-7062,"Laborers and Freight, Stock, and Material Movers, Hand",3369.73449076,4775.88797759,0.417210682493,1406,520.540166667,25208.2298328
5,53-7063,"Machine Feeders and Offbearers",578.92455522,443.27874733,-0.234887737478,-136,69.5458888889,26131.6229895
5,53-7064,"Packers and Packagers, Hand",791.593818289,545.505162318,-0.310606060606,-246,94.9822222222,24603.280182
4,53-7070,"Pumping Station Operators",52.6451972019,121.346130482,1.28301886792,68,11.6856666667,37713.7475865
5,53-7071,"Gas Compressor and Gas Pumping Station Operators",5.80972541293,1.43621686042,-0.833333333333,-5,0.6895,58687.2
5,53-7072,"Pump Operators, Except Wellhead Pumpers",17.5317922365,7.84655235619,-0.555555555556,-10,2.26322222222,37048.4428474
5,53-7073,"Wellhead Pumpers",29.3036795525,112.063361266,2.86206896552,83,8.733,37503.5332888
4,53-7080,"Refuse and Recyclable Material Collectors",151.583565404,87.847513612,-0.421052631579,-64,14.3332777778,29503.2408914
5,53-7081,"Refuse and Recyclable Material Collectors",151.583565404,87.847513612,-0.421052631579,-64,14.3332777778,29503.2408914
4,53-7110,"Mine Shuttle Car Operators",0,0.134630664261,0,0,0.0107777777778,37733.2324078
5,53-7111,"Mine Shuttle Car Operators",0,0.134630664261,0,0,0.0107777777778,37733.2324078
4,53-7120,"Tank Car, Truck, and Ship Loaders",54.6238024313,9.43141894424,-0.836363636364,-46,3.67427777778,34909.424188
5,53-7121,"Tank Car, Truck, and Ship Loaders",54.6238024313,9.43141894424,-0.836363636364,-46,3.67427777778,34909.424188
4,53-7190,"Miscellaneous Material Moving Workers",53.3405129355,36.354111619,-0.320754716981,-17,4.16011111111,25933.3974211
5,53-7199,"Material Moving Workers, All Other",53.3405129355,36.354111619,-0.320754716981,-17,4.16011111111,25933.3974211
2,55-0000,"Military-only occupations",816.218270733,1033.63794731,0.267156862745,218,117.983055556,35300.3240859
3,55-9000,"Military-only occupations",816.218270733,1033.63794731,0.267156862745,218,117.983055556,35300.3240859
4,55-9990,"Military-only occupations",816.218270733,1033.63794731,0.267156862745,218,117.983055556,35300.3240859
5,55-9999,"Military-only occupations",816.218270733,1033.63794731,0.267156862745,218,117.983055556,35300.3240859
2,99-0000,"Unclassified Occupation",375.413144746,1224.02146196,2.264,849,78.1515,29791.9523296
3,99-9000,"Unclassified Occupation",375.413144746,1224.02146196,2.264,849,78.1515,29791.9523296
4,99-9990,"Unclassified Occupation",375.413144746,1224.02146196,2.264,849,78.1515,29791.9523296
5,99-9999,"Unclassified Occupation",375.413144746,1224.02146196,2.264,849,78.1515,29791.9523296
`
}
