import { Link } from "gatsby"
import React from "react"
import { inject, observer } from "mobx-react"

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore ts doesn't like variable names for objects
import Logo from "../../images/invest_nwa_full_logo_color.png"
import ImpactNWA from "../../images/nwa_council_family-05.png"
import Diversity from "../../images/Diversity_logo.png"
import FareFlight from "../../images/nwa_council_family-03.png"
import StartUpNWA from "../../images/nwa_council_family-02.png"
import Finding from "../../images/nwa_council_family-04.png"
import EngageNWA from "../../images/nwa_council_family-01.png"
import CareersNWA from "../../images/nwa_council_family-03c.png"
import LogoWhite from "../../images/nwa_council_family-06.png"

import "./header.scss"

import { DataStore } from "../../types/types"

interface HeaderProps {
  siteTitle: string
  dataStore?: DataStore
}

const BASE_URL = "https://investnwa.org"

@inject("dataStore")
@observer
export default class Header extends React.Component<HeaderProps> {
  render() {
    const { dataStore } = this.props
    const data = dataStore ? dataStore.data : ""
    return (
      <header className="header">
        <div className="header-upper">
          <div className="container">
            <div className="header-upper__logos">
              <a href="https://www.impactnwa.org/" target="_blank">
                <img src={ImpactNWA} />
              </a>
              <a href="http://diversitynwa.com/" target="_blank">
                <img src={Diversity} />
              </a>
              <a href="https://fareflightnwa.org/" target="_blank">
                <img src={FareFlight} />
              </a>
              <a href="https://startupnwa.com/" target="_blank">
                <img src={StartUpNWA} />
              </a>
              <a href="https://findingnwa.com/" target="_blank">
                <img src={Finding} />
              </a>
              <a href="https://engagenwa.org/" target="_blank">
                <img src={EngageNWA} />
              </a>
              <a href="https://careersnwa.com/" target="_blank">
                <img src={CareersNWA} />
              </a>
              <a href="https://investnwa.org/" target="_blank">
                <img src={LogoWhite} />
              </a>
            </div>
          </div>
        </div>
        <div className="header-content">
          <h1 style={{ margin: 0 }}>
            <a
              href="http://investnwa.org/"
              target="_blank"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <img src={Logo} />
            </a>
          </h1>
          <div className="header-links">
            <a className="header-link" href={BASE_URL}>
              Home
            </a>
            <a className="header-link" href={`${BASE_URL}/location`}>
              Location
            </a>
            <a className="header-link" href={`${BASE_URL}/economy`}>
              Economy
            </a>
            <a className="header-link active" href={`${BASE_URL}/data`} target="_blank">
              Data
            </a>
            <a className="header-link" href={`${BASE_URL}/site-search`}>
              Site Search
            </a>
            <a className="header-link" href={`${BASE_URL}/business-costs`}>
              Business Costs
            </a>
            <a
              className="header-link"
              href={`https://findingnwa.com`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Lifestyle
            </a>
          </div>
        </div>
      </header>
    )
  }
}
