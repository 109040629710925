import DemographicsTable from "../pages/demographics-table"

export const OCCUPATION_TABLE = "OCCUPATION_TABLE"
export const INDUSTRY_TABLE = "INDUSTRY_TABLE"
export const PROGRAM_TABLE = "PROGRAM_TABLE"
export const DEMOGRAPHICS_TABLE = "DEMOGRAPHICS_TABLE"

export const EMSI_ACCESS_TOKEN_EXPIRATION_TIME = 3600

export interface OccupationData {
  soc: number
  description: string
  jobs2019: number
  change2019And2022: number
  annualReplacementJobs: number
  medianHourlyEarnings: number
}

export interface IndustryData {
  naics: number
  description: string
  jobs2019: number
  jobs2022: number
  change2019And2022: number
  changePct2019And2022: number
  currentWagesSalariesProprietorEarnings: number
}

export interface ProgramData {
  cipCode: number
  description: string
  toCompletions: number
  fromCompletions: number
}

export interface DemographicsData {
  demographic: string
  populationFrom: number
  populationTo: number
  pctPopulationFrom: number
  pctPopulationTo: number
  change: number
  changePct: number
}

export type TableData = OccupationData | IndustryData | DemographicsData

export interface DataStore {
  data: string
  oauthToken: string
  oauthTokenTime: number
  oauthLoading: boolean
  message: string
  errorMessage: string
  aMessageExists: boolean
  industryTable: IndustryData[]
  occupationTable: OccupationData[]
  demographicsTable: DemographicsData[]
  programTable: ProgramData[]
  updateOauthToken(newToken: string): void
  updateOauthTokenTime(newTokenTime: number): void
  updateMessage(newMessage: string): void
  updateErrorMessage(newErrorMessage: string): void
  clearMessages(): void
  updateIndustryTable(newIndustryTable: object): void
  updateOauthLoading(loading: boolean): void
  updateOccupationTable(newOccupationTable: object): void
  updateDemographicsTable(newDemographicsTable: DemographicsData[]): void
  updateProgramTable(newProgramTable: ProgramData[]): void
}
