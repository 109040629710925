import axios from "axios"
import { DataStore } from "../../types/types"
import { transform } from "../utils"
import csvParser from "papaparse"

import { csv } from "../tableCsvExports/Occupation_Table_021420"
const parsedData = csvParser.parse(csv(), {
  delimiter: ",",
})

function csvToMap(parsedData: object[]) {
  const map = new Map();
  for (let x = 2; x < parsedData.length; x++) {
    if (parsedData[x][2] !== undefined) {
      map[`${parsedData[x][2]}`] = [parsedData[x][1]]
    }
  }
  map["Military Only"] = ["55-0000"]
  map["Unclassified"] = ["99-0000"]

  return map
}

const CSV_DATA = csvToMap(parsedData.data)

const DIMENSION_NAME = "Occupation" // Same as table name
const API_URL = "https://agnitio.emsicloud.com/emsi.us.occupation/latest"

const AREA_CONSTRAINT = {
  dimensionName: "Area",
  map: {
    "Fayetteville-Springdale-Rogers, AR": ["5007", "5087", "5143"],
  },
}
export function getMetrics(fromYear: number, toYear: number) {
  return [
    {
      name: `Jobs.${fromYear}`,
      as: `${fromYear} Jobs`,
    },
    {
      name: `HistoricalEarnings.Median.Annual.2018`,
      as: "medianAnnualEarnings",
    },
    {
      name: `HistoricalEarnings.Average.2018`,
      as: "medianHourlyEarnings",
    },
    {
      name: `Jobs.${toYear}`,
      as: `${toYear} Jobs`,
    },
    {
      name: "Earnings.Percentile10.Annual",
      as: "Pct.10 Annual Earnings",
    },
    {
      name: "Earnings.Percentile25.Annual",
      as: "Pct.25 Annual Earnings",
    },
    {
      name: "Earnings.Median.Annual",
      as: "Annual Median Earnings",
    },
    {
      name: "Earnings.Percentile75.Annual",
      as: "Pct.50 Annual Earnings",
    },
    {
      name: "Earnings.Percentile90.Annual",
      as: "Pct.90 Annual Earnings",
    },
    {
      name: "Jobs.2019",
      as: "2019 Locations Quotient",
      operation: {
        name: "LocationQuotient",
        geoparent: "0",
        along: "Occupation",
      },
    },
  ]
}

const DIMENSION_CONSTRAINT = {
  dimensionName: DIMENSION_NAME,
  map: CSV_DATA,
}

export function postData(fromYear: number, toYear: number) {
  return {
    metrics: getMetrics(fromYear, toYear),
    constraints: [AREA_CONSTRAINT, DIMENSION_CONSTRAINT],
  }
}

export function transformName(name: string, fromYear: number, toYear: number) {
  switch (name) {
    case "Occupation":
      return "description"
    case `${fromYear} Jobs`:
      return `jobs${fromYear}`
    case `${toYear} Jobs`:
      return `jobs${toYear}`
    case "2019 Earnings":
      return "currentWagesSalariesProprietorEarnings"
    default:
      return name
  }
}

function dynamicSort(property) {
  var sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function occupationApiCall(
  store: DataStore,
  token: string,
  toYear: number,
  fromYear: number
) {
  return axios({
    method: "POST",
    url: API_URL,
    data: postData(fromYear, toYear),
    headers: {
      "content-type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      const transformedData = transform({
        data: response.data.data,
        fromYear,
        toYear,
        transformName,
      })
      const finalizedData = transformedData.map(el => {
        el["soc"] = CSV_DATA[el.description]
        return el
      })
      const sortedData = finalizedData.sort(dynamicSort("soc"))
      store.updateOccupationTable(sortedData)
    })
    .catch(function(error) {
      console.log("err", error)
    })
}
