/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header/header"
import Notifications from "../Notifications/notifications"
import "./layout.css"
import "./layout.scss"
import "../../styles/index.scss"

import { Provider } from "mobx-react"
import dataStore from "../../stores/dataStore"

const stores = {
  dataStore,
}

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Provider {...stores}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Notifications />
      <div className="body">
        <main className="main">{children}</main>
        <footer>
          <div className="footer">
            <p>&copy; InvestNWA {new Date().getFullYear()}</p>
          </div>
        </footer>
      </div>
    </Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
