export function industryColumns(fromYear: number, toYear: number): object[] {
  return [
    {
      Header: "Industry Table",
      columns: [
        {
          Header: "NAICS",
          accessor: "naics",
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: `${fromYear} Jobs`,
          accessor: `jobs${fromYear}`,
        },
        {
          Header: `${toYear} Jobs`,
          accessor: `jobs${toYear}`,
        },
        {
          Header: `${fromYear}-${toYear} Change`,
          accessor: `change`,
        },
        {
          Header: `${fromYear}-${toYear} % Change`,
          accessor: `changePct`,
        },
        {
          Header: "Current Wages, Salaries, & Proprietor Earnings",
          accessor: "currentWagesSalariesProprietorEarnings",
        },
        {
          Header: "2019 Locations Quotient",
          accessor: "locationsQuotient2019",
        },
      ],
    },
  ]
}

export function occupationColumns(fromYear: number, toYear: number) {
  return [
    {
      Header: "Occupation Table",
      columns: [
        {
          Header: "SOC",
          accessor: "soc",
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: `${fromYear} Jobs`,
          accessor: `jobs${fromYear}`,
        },
        {
          Header: `${toYear} Jobs`,
          accessor: `jobs${toYear}`,
        },
        {
          Header: `${fromYear}-${toYear} Change`,
          accessor: "change",
        },
        // {
        //   Header: "Annual Replacement Jobs",
        //   accessor: "annualReplacementJobs",
        // },
        {
          Header: "Median Hourly Earnings",
          accessor: "medianHourlyEarnings",
        },
        {
          Header: "Median Annual Earnings",
          accessor: "medianAnnualEarnings",
        },
      ],
    },
  ]
}

export function programColumns(fromYear: number, toYear: number) {
  return [
    {
      Header: "Program Table",
      columns: [
        {
          Header: "CIP Code",
          accessor: "cipCode",
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: `All Completions > All Program Types > ${toYear} Completions`,
          accessor: "toCompletions",
        },
        {
          Header: `All Completions > All Program Types > ${fromYear} Completions`,
          accessor: "fromCompletions",
        },
      ],
    },
  ]
}

export function demographicsColumns(fromYear: number, toYear: number) {
  return [
    {
      Header: "Demographics Table",
      columns: [
        {
          Header: "Demographic",
          accessor: "demographic",
        },
        {
          Header: `${fromYear} Population`,
          accessor: `population${fromYear}`,
        },
        {
          Header: `${toYear} Population`,
          accessor: `population${toYear}`,
        },
        {
          Header: `${fromYear} % of Population`,
          accessor: `pctPopulation${fromYear}`,
        },
        {
          Header: `${toYear} % of Population`,
          accessor: `pctPopulation${toYear}`,
        },
        {
          Header: `${fromYear}-${toYear} Change`,
          accessor: `change`,
        },
        {
          Header: `${fromYear}-${toYear} % Change`,
          accessor: `changePct`,
        },
      ],
    },
  ]
}
