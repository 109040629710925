import React from "react"
import styled from "styled-components"
import EMSILogo from "../../images/emsi-logo.png"
const Styles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  background-color: white;
  margin: -17px 30px 30px 2px;
  padding: 20px;
  width: 300px;
  border-radius: 3px;
  border: 1px solid #ddd;
`

const Header = styled.span`
  display: flex;
  margin-bottom: -24px;
  font-size: 14px;
`

const Image = styled.img`
  height: 14px;
  width: 56px;
  margin-left: 8px;
  margin-top: 4px;
`

const EMSINotice = () => {
  return (
    <Styles>
      <Header>
        Data Courtesy of{" "}
        <a
          href="https://www.economicmodeling.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={EMSILogo} />
        </a>
      </Header>
    </Styles>
  )
}

export default EMSINotice
