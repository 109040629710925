import React from "react"
import styled from "styled-components"

const Styles = styled.div`
  .container {
    display: flex;
    align-items: end;
  }
  .one {
    width: 4px;
    height: 10px;
    background-color: #3452ff;
    margin-right: 3px;
    border-radius: 25px;
    margin-top: 10px;
    -webkit-animation: bounce-one 0.8s infinite;
    -moz-animation: bounce-one 0.8s infinite;
    -o-animation: bounce-one 0.8s infinite;
  }
  .two {
    width: 4px;
    height: 14px;
    background-color: #3452ff;
    margin-right: 3px;
    border-radius: 25px;
    margin-top: 6px;
    -webkit-animation: bounce-two 0.8s infinite;
    -moz-animation: bounce-two 0.8s infinite;
    -o-animation: bounce-two 0.8s infinite;
  }
  .three {
    width: 4px;
    height: 20px;
    background-color: #3452ff;
    margin-right: 3px;
    border-radius: 25px;
    -webkit-animation: bounce-three 0.8s infinite;
    -moz-animation: bounce-three 0.8s infinite;
    -o-animation: bounce-three 0.8s infinite;
  }
  .four {
    width: 4px;
    height: 14px;
    background-color: #3452ff;
    margin-right: 3px;
    border-radius: 25px;
    margin-top: 6px;
    -webkit-animation: bounce-four 0.8s infinite;
    -moz-animation: bounce-four 0.8s infinite;
    -o-animation: bounce-four 0.8s infinite;
  }
  @-webkit-keyframes bounce-one {
    20% {
      -webkit-transform: translateY(-11px);
    }
  }
  @keyframes bounce-one {
    20% {
      -webkit-transform: translateY(-11px);
    }
  }
  @-webkit-keyframes bounce-two {
    25% {
      -webkit-transform: translateY(-15px);
    }
  }
  @keyframes bounce-two {
    25% {
      -webkit-transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounce-three {
    50% {
      -webkit-transform: translateY(-15px);
    }
  }
  @keyframes bounce-three {
    50% {
      -webkit-transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounce-four {
    75% {
      -webkit-transform: translateY(-15px);
    }
  }
  @keyframes bounce-four {
    75% {
      -webkit-transform: translateY(-15px);
    }
  }
`

const Loading: React.FC = () => {
  return (
    <Styles>
      <div className="container">
        <div className="one"></div>
        <div className="two"></div>
        <div className="three"></div>
        <div className="four"></div>
      </div>
    </Styles>
  )
}

export default Loading
