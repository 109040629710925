import namor from "namor"
import { OCCUPATION_TABLE, INDUSTRY_TABLE } from "../../types/types"

export const NUMBER_OF_ROWS = 20

export function generateOccupationRowData(test = false) {
  return {
    soc: test ? 1 : Math.floor(Math.random() * 100),
    description: test ? "desc" : namor.generate({ words: 3, numbers: 0 }),
    jobs2019: test ? 2 : Math.floor(Math.random() * 100),
    change2019And2022: test ? 3 : Math.floor(Math.random() * 100),
    annualReplacementJobs: test ? 4 : Math.floor(Math.random() * 100),
    medianHourlyEarnings: test ? 5 : Math.floor(Math.random() * 100),
  }
}

export function generateIndustryRowData(test = false) {
  return {
    naics: test ? 1 : Math.floor(Math.random() * 100),
    description: test ? "desc" : namor.generate({ words: 3, numbers: 0 }),
    jobs2019: test ? 2 : Math.floor(Math.random() * 100),
    jobs2022: test ? 3 : Math.floor(Math.random() * 100),
    change2019And2022: test ? 4 : Math.floor(Math.random() * 100),
    changePct2019And2022: test ? 5 : Math.floor(Math.random() * 100),
    currentWagesSalariesProprietorEarnings: test
      ? 6
      : Math.floor(Math.random() * 100),
    locationQuotient2019: test ? 1.5 : Math.floor(Math.random() * 100),
    jobsMultiplier2019: test ? 2.5 : Math.floor(Math.random() * 100),
  }
}

export function setRowGenerationByTableType(tableType: string) {
  if (tableType === OCCUPATION_TABLE) return generateOccupationRowData
  if (tableType === INDUSTRY_TABLE) return generateIndustryRowData
  return null
}

export function generateData({
  tableType,
  test = false,
}: {
  tableType: string
  test?: boolean
}) {
  const gen = setRowGenerationByTableType(tableType)
  const data = []
  if (gen) {
    for (let x = 0; x < NUMBER_OF_ROWS; x++) {
      data.push(gen(test))
    }
  }
  return data
}
