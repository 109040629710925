import React from "react"
import styled from "styled-components"
import { IoMdArrowDropdown } from "react-icons/io"
import { OCCUPATION_TABLE, INDUSTRY_TABLE } from "../../types/types"
interface Event {
  preventDefault(): void
}

interface DigitFilterState {
  showMenu: boolean
  currentSelection: string
}

interface DigitFilterProps {
  onSelect(numDigits: number): void
  tableType: string
}

const StyledButton = styled.button`
  margin-top: -10px;
  background-color: #eee;
  min-width: 85px;
  height: 30px;
  font-size: 14px;
  margin-bottom: 16px;
  border: none;
  border-radius: 3px;
  margin-left: 95px;
`

const Container = styled.div`
  padding-left: 156px;
  margin-bottom: -47px;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  min-width: 140px;
  border-radius: 3px;
  position: absolute;
  background-color: white;
  margin-top: -8px;
  margin-left: -3px;
  box-shadow: 0 2px 10px -5px #555;
  padding-bottom: 14px;
  min-height: 100px;
`

const StyledSelection = styled.span`
  margin: 2px 8px;
  padding-left: 10px;
  border-radius: 3px;
  &:hover {
    background-color: #eee;
  }
  font-size: 14px;
  color: #3a82c3;
`

const FirstSelection = styled.span`
  margin: 12px 8px 2px 8px;
  padding-left: 10px;
  border-radius: 3px;
  &:hover {
    background-color: #eee;
  }
  font-size: 14px;
  color: #3a82c3;
`
export default class DigitFilter extends React.Component<
  DigitFilterProps,
  DigitFilterState
> {
  constructor(props: DigitFilterProps) {
    super(props)

    this.state = {
      showMenu: false,
      currentSelection: "5-Digit",
    }

    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  componentDidMount() {
    const { tableType } = this.props
    if (tableType === OCCUPATION_TABLE) {
      this.setSelection("5-Digit")
    }
    if (tableType === INDUSTRY_TABLE) {
      this.setSelection("6-Digit")
    }
  }

  showMenu(event: Event) {
    event.preventDefault()

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu)
    })
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu)
    })
  }

  setSelection(currentSelection: string) {
    this.setState({ currentSelection })
    this.props.onSelect(currentSelection)
  }

  isOccupationTable(tableType: string) {
    return tableType === OCCUPATION_TABLE
  }

  render() {
    return (
      <Container>
        <StyledButton onClick={this.showMenu}>
          {this.state.currentSelection}
          <IoMdArrowDropdown
            style={{
              marginRight: -4,
              marginBottom: -5,
              height: 20,
              width: 20,
            }}
          />
        </StyledButton>

        {this.state.showMenu ? (
          <MenuContainer>
            <FirstSelection onClick={() => this.setSelection("All Levels")}>
              All Levels
            </FirstSelection>
            <StyledSelection onClick={() => this.setSelection("2-Digit")}>
              2-Digit
            </StyledSelection>
            <StyledSelection onClick={() => this.setSelection("3-Digit")}>
              3-Digit
            </StyledSelection>
            <StyledSelection onClick={() => this.setSelection("4-Digit")}>
              4-Digit
            </StyledSelection>
            <StyledSelection onClick={() => this.setSelection("5-Digit")}>
              5-Digit
            </StyledSelection>
            {!this.isOccupationTable(this.props.tableType) && (
              <StyledSelection onClick={() => this.setSelection("6-Digit")}>
                6-Digit
              </StyledSelection>
            )}
          </MenuContainer>
        ) : null}
      </Container>
    )
  }
}
