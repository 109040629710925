import { transformFromAst } from "@babel/core"
import { DataStore } from "../types/types"

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function getFromArray(arr: object[], firstLevel: number, secondLevel: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore ts doesn't like variable names for objects
  return arr[firstLevel][secondLevel]
}

function writeToArray(
  arr: object[],
  firstLevel: number,
  secondLevel: string,
  value: string | number
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore ts doesn't like variable names for objects
  arr[firstLevel][secondLevel] = value
}

export function deltas(arr: object[], fromYear: number, toYear: number) {
  for (let x = 0; x < arr.length; x++) {
    if (arr[x]["locationsQuotient2019"] !== undefined) {
      arr[x]["locationsQuotient2019"] = arr[x]["locationsQuotient2019"].toFixed(
        2
      )
    }

    let population = false
    let from = getFromArray(arr, x, `jobs${fromYear}`)
    let to = getFromArray(arr, x, `jobs${toYear}`)
    if (getFromArray(arr, x, "medianHourlyEarnings") !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      arr[x]["medianHourlyEarnings"] = `$${arr[x][
        "medianHourlyEarnings"
      ].toFixed(0)}`
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      arr[x]["medianAnnualEarnings"] = numberWithCommas(
        `$${arr[x]["medianAnnualEarnings"].toFixed(0)}`
      )
    }

    if (
      getFromArray(arr, x, "currentWagesSalariesProprietorEarnings") !==
      undefined
    ) {
      const numberWithAppliedDivision =
        arr[x]["currentWagesSalariesProprietorEarnings"] /
        getFromArray(arr, x, `jobs${fromYear}`)
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      arr[x]["currentWagesSalariesProprietorEarnings"] = numberWithCommas(
        `$${numberWithAppliedDivision.toFixed(0)}`
      )
    }

    if (from === undefined) {
      from = getFromArray(arr, x, `population${fromYear}`)
      to = getFromArray(arr, x, `population${toYear}`)
      population = true
    }
    const fromRounded = Math.round(from)
    const toRounded = Math.round(to)
    const change = fromRounded - toRounded
    const changePct = change / toRounded
    writeToArray(arr, x, "change", numberWithCommas(change))
    writeToArray(arr, x, "changePct", `${Math.round(100 * changePct)}%`)
    if (population) {
      writeToArray(
        arr,
        x,
        `population${fromYear}`,
        numberWithCommas(fromRounded)
      )
      writeToArray(
        arr,
        x,
        `population${toYear}`,
        numberWithCommas(Math.round(toRounded))
      )
    } else {
      writeToArray(arr, x, `jobs${fromYear}`, numberWithCommas(fromRounded))
      writeToArray(
        arr,
        x,
        `jobs${toYear}`,
        numberWithCommas(Math.round(toRounded))
      )
    }
  }
}

interface DataObject {
  name: string
  type: string
  rows: string[]
}

interface Transform {
  data: DataObject[]
  fromYear: number
  toYear: number
  transformName(name: string, fromYear: number, toYear: number): string
  programTable?: boolean
}

export function transform(params: Transform) {
  const dataLen = params.data.length
  const rowsLen = params.data[0].rows.length
  const finalArray = []

  for (let i = 0; i < rowsLen; i++) {
    const row = {}
    const startNumber = params.programTable ? 0 : 1
    for (let j = startNumber; j < dataLen; j++) {
      const name = params.transformName(
        params.data[j].name,
        params.fromYear,
        params.toYear
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      row[name] = params.data[j].rows[i]
    }
    finalArray.push(row)
  }
  if (!params.programTable) {
    deltas(finalArray, params.fromYear, params.toYear)
  }
  return finalArray
}
