import React from "react"
import styled from "styled-components"
import { IoMdArrowDropdown } from "react-icons/io"
import { FiFileText } from "react-icons/fi"
import { CSVLink } from "react-csv"
import {
  OCCUPATION_TABLE,
  INDUSTRY_TABLE,
  PROGRAM_TABLE,
  DEMOGRAPHICS_TABLE,
} from "../../types/types"

interface Event {
  preventDefault(): void
}

interface DataExportState {
  showMenu: boolean
}

interface DataExportProps {
  filteredData: object[]
  tableName: string
  pluralTablename: string
}

const StyledButton = styled.button`
  position: absolute;
  right: 80px;
  margin-top: 9px;
  background-color: #3452ff;
  min-width: 85px;
  height: 30px;
  font-size: 14px;
  margin-bottom: 16px;
  border: none;
  border-radius: 3px;
  color: white;
`

const Container = styled.div`
  padding-top: 30px;
  @media (max-width: 768px) {
    display: none;
  }
`

const MenuContainer = styled.div`
  position: absolute;
  right: 80px;
  margin-top: 44px;
  margin-right: -4px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  min-width: 140px;
  border-radius: 3px;
  position: absolute;
  background-color: white;
  margin-left: -3px;
  box-shadow: 0 2px 10px -5px #555;
  padding-bottom: 14px;
  min-height: 30px;
  padding-right: 10px;
`

const StyledSelection = styled.span`
  margin: 2px 8px;
  padding-left: 10px;
  border-radius: 3px;
  &:hover {
    background-color: #eee;
  }
  font-size: 14px;
  color: #3a82c3;
`

const FirstSelection = styled.span`
  margin: 12px 8px 2px 8px;
  padding-left: 10px;
  border-radius: 3px;
  &:hover {
    background-color: #eee;
  }
  font-size: 14px;
  color: #3a82c3;
`

const Description = styled.span`
  font-size: 14px;
  padding-left: 12px;
  padding-top: 12px;
  margin-bottom: -8px;
`

const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"],
]

function generateFilename(tableName: string, pluralTablename: string) {
  const dateObj = new Date()
  const date = dateObj.getDate()
  let month = dateObj.getMonth() + 1
  if (month < 10) {
    month = "0" + month.toString()
  }
  const year = dateObj.getYear() - 100
  const filename = `${tableName.replace(
    " ",
    "_"
  )}_All_${pluralTablename}_In_Fayetteville_Springdale_Rogders_AR_${month}${date}${year}.csv`
  return filename
}

export default class DigitFilter extends React.Component<
  DataExportProps,
  DataExportState
> {
  constructor(props: DataExportProps) {
    super(props)

    this.state = {
      showMenu: false,
    }

    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  showMenu(event: Event) {
    event.preventDefault()

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu)
    })
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu)
    })
  }

  render() {
    const { tableName, pluralTablename } = this.props
    const fileName = generateFilename(tableName, pluralTablename)
    return (
      <Container>
        <StyledButton onClick={this.showMenu}>
          Export
          <IoMdArrowDropdown
            style={{
              marginRight: -4,
              marginBottom: -5,
              height: 20,
              width: 20,
            }}
          />
        </StyledButton>

        {this.state.showMenu ? (
          <MenuContainer>
            <Description>Export to:</Description>
            <FirstSelection>
              <CSVLink
                data={this.props.filteredData}
                filename={fileName}
                style={{ color: "#3452ff" }}
              >
                ;
                <FiFileText
                  style={{
                    width: 14,
                    height: 14,
                    marginLeft: -4,
                    marginRight: 4,
                    marginBottom: -1,
                  }}
                />
                CSV (Tableau, Domo, etc.)
              </CSVLink>
            </FirstSelection>
          </MenuContainer>
        ) : null}
      </Container>
    )
  }
}
