import axios from "axios"
import { EMSI_ACCESS_TOKEN_EXPIRATION_TIME, DataStore } from "../types/types"

const OAUTH_ERROR_STRING = "Oauth Error! Please contact the development team."
const OAUTH_SUCCESS = "Oauth Success!"
const OAUTH_URL = "https://auth.emsicloud.com/connect/token"
const OAUTH_URL_PARAMS =
  "grant_type=client_credentials&client_id=northwest-arkansas&client_secret=aIO2C6Fw&scope=emsiauth"

function getOauth(store: DataStore) {
  store.updateOauthLoading(true)
  axios({
    method: "POST",
    url: OAUTH_URL,
    data: OAUTH_URL_PARAMS,
    headers: { "content-type": "application/x-www-form-urlencoded" },
  })
    .then(function(response) {
      store.updateOauthToken(response.data.access_token)
      store.updateOauthTokenTime(Date.now())
      // store.updateMessage(OAUTH_SUCCESS)
      store.updateOauthLoading(false)
    })
    .catch(function(error) {
      console.log({ error })
      store.updateOauthLoading(false)
      store.updateErrorMessage(OAUTH_ERROR_STRING)
    })
}

export function setOauth(store: DataStore) {
  let timeDifference = 0
  const existingAccessToken = store.oauthToken
  const existingAccessTokenTimestamp = store.oauthTokenTime
  const currentTime = Date.now()
  if (existingAccessTokenTimestamp > 0) {
    timeDifference = currentTime - existingAccessTokenTimestamp
  }
  if (
    !existingAccessToken ||
    timeDifference > EMSI_ACCESS_TOKEN_EXPIRATION_TIME
  ) {
    getOauth(store)
  }
}
