import React, { useState, useRef } from "react"
import { useTable, Column, useRowSelect } from "react-table"
import debounce from "debounce"
import { inject, observer } from "mobx-react"
import { toJS } from "mobx"
import TimeframeFilter from "../TimeframeFilter/timeframeFilter"
import EMSINotice from "../EMSINotice/emsiNotice"
import DescriptionFilter from "../DescriptionFilter/descriptionFilter"
import DigitFilter from "../DigitFilter/digitFilter"
import DataExport from "../DataExport/dataExport"
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md"

import { generateData } from "./makeData"
import { setOauth } from "../../emsi/oauth"
import {
  occupationColumns,
  industryColumns,
  programColumns,
  demographicsColumns,
} from "./tableColumns"
import { industryApiCall } from "../../emsi/industry/industryApiCall"
import { occupationApiCall } from "../../emsi/occupation/occupationApiCall"
import { demographicsApiCall } from "../../emsi/demographics/demographicsApiCall"
import { programApiCall } from "../../emsi/program/programApiCall"

import Loading from "../Loading/loading"

import {
  TableData,
  OCCUPATION_TABLE,
  INDUSTRY_TABLE,
  PROGRAM_TABLE,
  DEMOGRAPHICS_TABLE,
  DataStore,
  IndustryData,
  OccupationData,
  DemographicsData,
  ProgramData,
} from "../../types/types"

import { LOW_TO_HIGH, HIGH_TO_LOW } from "./utils"

import {
  Styles,
  TableHeader,
  SubHeader,
  TableDiv,
  Checkbox,
  LoadingStyles,
  HeaderContainer,
  ToggleRowsButton,
  ToggleColumnsButton,
  ToggleRowButtonAlt,
  ColumnCard,
  RightAlignDiv,
} from "./styled"

interface TableProps {
  columns: Column[]
  data: TableData[]
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate
      }
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

function isNegative(value: string) {
  if (value === undefined) {
    return false
  }
  try {
    const stripped = value
      .toString()
      .replace(",", "")
      .replace("%", "")
    if (!isNaN(stripped) && parseInt(stripped) < 0) {
      return true
    }
    return false
  } catch (err) {
    console.log({ err })
    return false
  }
}

function digitFilterToNumber(digitFilter: string) {
  switch (digitFilter) {
    case "All Levels":
      return 0
    case "1-Digit":
      return 1
    case "2-Digit":
      return 2
    case "3-Digit":
      return 3
    case "4-Digit":
      return 4
    case "5-Digit":
      return 5
    case "6-Digit":
      return 6
    default:
      return 0
  }
}

function toggleOrder(order: string) {
  if (order === LOW_TO_HIGH) return HIGH_TO_LOW
  return LOW_TO_HIGH
}

function dynamicSort(property) {
  var sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a["original"][property] < b["original"][property]
        ? -1
        : a["original"][property] > b["original"][property]
        ? 1
        : 0
    return result * sortOrder
  }
}

function reverseSort(property) {
  let sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function(b: object[], a: object[]) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a["original"][property] < b["original"][property]
        ? -1
        : a["original"][property] > b["original"][property]
        ? 1
        : 0
    return result * sortOrder
  }
}

interface SetAndToggleOrder {
  columnId: string
  order: string
  setOrder(order: string): void
  setOrderHeader(order: string): void
  rows: object[]
}

function setAndToggleOrder(params: SetAndToggleOrder) {
  const defaultHeaders = ["cipCode", "demographic", "soc", "naics"]
  const { columnId, order, setOrder, setOrderHeader, rows } = params
  setOrder(toggleOrder(order))
  setOrderHeader(columnId)
  let sorted = []
  if (order === LOW_TO_HIGH) {
    sorted = rows.sort(reverseSort(columnId))
  } else {
    sorted = rows.sort(dynamicSort(columnId))
  }
  return sorted
}

function showDownArray(orderHeader: string, columnId: string, order: string) {
  if (orderHeader === columnId && order === HIGH_TO_LOW) {
    return true
  }
  return false
}

function isRightAlignColumn(column: string) {
  const columns = ["CIP Code", "SOC", "NAICS", "Demographic", "Description"]
  return !columns.includes(column)
}

const Table: React.FC<TableProps> = ({ columns, data, tableType }) => {
  // Use the state and functions returned from useTable to build your UI
  let buttonTypeAbsolute = false
  if (tableType === OCCUPATION_TABLE || tableType === INDUSTRY_TABLE) {
    buttonTypeAbsolute = true
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    getToggleHideAllColumnsProps,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    hooks => {
      hooks.flatColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  headerGroups.shift() // remove unnecessary header
  const [hideRows, setHideRows] = useState(false)
  const [order, setOrder] = useState(LOW_TO_HIGH)
  const [orderHeader, setOrderHeader] = useState("")

  const inputEl = useRef(null)

  let useRows = []
  if (hideRows) {
    const checkedArray = []
    for (const key in selectedRowIds) {
      checkedArray.push(parseInt(key))
    }
    useRows = rows.filter((el, index) => {
      if (checkedArray.indexOf(index) !== -1) {
        return el
      }
    })
  } else {
    useRows = rows
  }

  function toggle() {
    if (inputEl) {
      inputEl.current.click()
    }
  }

  function showArrow(column: object) {
    if (typeof column.render("Header") === "string") return true
    return false
  }

  const disableToggleRows = Object.keys(selectedRowIds).length === 0
  return (
    <React.Fragment>
      {buttonTypeAbsolute ? (
        <ToggleRowsButton
          disabled={disableToggleRows}
          onClick={() => setHideRows(!hideRows)}
        >
          Toggle Rows
        </ToggleRowsButton>
      ) : (
        <ToggleRowButtonAlt
          disabled={disableToggleRows}
          onClick={() => setHideRows(!hideRows)}
        >
          Toggle Rows
        </ToggleRowButtonAlt>
      )}
      <IndeterminateCheckbox
        {...getToggleHideAllColumnsProps()}
        ref={inputEl}
        style={{ display: "none" }}
      />{" "}
      <ToggleColumnsButton
        disabled={getToggleHideAllColumnsProps().checked}
        onClick={toggle}
      >
        Toggle Columns
      </ToggleColumnsButton>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column} {...column.getHeaderProps()}>
                  <ColumnCard>
                    <div>{column.render("Header")}</div>
                    {showArrow(column) &&
                      showDownArray(orderHeader, column.id, order) && (
                        <MdKeyboardArrowDown
                          style={{ minHeight: 20, minWidth: 20 }}
                          onClick={() =>
                            setAndToggleOrder({
                              columnId: column.id,
                              order,
                              setOrder,
                              setOrderHeader,
                              rows,
                            })
                          }
                        />
                      )}
                    {showArrow(column) &&
                      !showDownArray(orderHeader, column.id, order) && (
                        <MdKeyboardArrowUp
                          style={{ minHeight: 20, minWidth: 20 }}
                          onClick={() =>
                            setAndToggleOrder({
                              columnId: column.id,
                              order,
                              setOrder,
                              setOrderHeader,
                              rows,
                            })
                          }
                        />
                      )}

                    {typeof column.render("Header") === "string" && (
                      <div style={{ paddingRight: 10, position: "relative" }}>
                        {" "}
                        <span
                          style={{
                            backgroundColor: "#3a82c3",
                            borderRadius: 30,
                            color: "white",
                            padding: 5,
                            height: 10,
                            width: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: -5,
                            left: 5,
                          }}
                        >
                          -
                        </span>
                        <input
                          type="checkbox"
                          className="column-toggle"
                          {...column.getToggleHiddenProps()}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: -5,
                            left: 5,
                            opacity: "0",
                          }}
                        />{" "}
                      </div>
                    )}
                  </ColumnCard>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {useRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  const className = isNegative(cell.value) ? "red" : null
                  return (
                    <td key={i} className={className} {...cell.getCellProps()}>
                      {isRightAlignColumn(cell.column.Header) && (
                        <RightAlignDiv>{cell.render("Cell")}</RightAlignDiv>
                      )}
                      {!isRightAlignColumn(cell.column.Header) && (
                        <div>{cell.render("Cell")}</div>
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </React.Fragment>
  )
}

export function setColumns(
  tableType: string,
  fromYear: number,
  toYear: number
) {
  if (tableType === OCCUPATION_TABLE) return occupationColumns(fromYear, toYear)
  if (tableType === INDUSTRY_TABLE) return industryColumns(fromYear, toYear)
  if (tableType === PROGRAM_TABLE) return programColumns(fromYear, toYear)
  if (tableType === DEMOGRAPHICS_TABLE)
    return demographicsColumns(fromYear, toYear)

  return null
}

interface ContainerProps {
  test?: boolean
  tableType: string
  dataStore?: DataStore
}

interface ContainerState {
  toYear: number
  fromYear: number
  data: TableData[]
  descriptionFilter: string
  tableName: string
  digitFilter: string
}

function getTableData(
  tableType: string,
  dataStore: DataStore | undefined,
  test: boolean | undefined
) {
  if (dataStore !== undefined && !test) {
    switch (tableType) {
      case INDUSTRY_TABLE: {
        const data: IndustryData[] = toJS(dataStore.industryTable)
        return data
      }
      case OCCUPATION_TABLE: {
        const data: OccupationData[] = toJS(dataStore.occupationTable)
        return data
      }
      case DEMOGRAPHICS_TABLE: {
        const data: DemographicsData[] = toJS(dataStore.demographicsTable)
        return data
      }
      case PROGRAM_TABLE: {
        const data: ProgramData[] = toJS(dataStore.programTable)
        return data
      }
      default:
        return generateData({ tableType, test })
    }
  }
  return generateData({ tableType, test })
}

@inject("dataStore")
@observer
export default class Container extends React.Component<
  ContainerProps,
  ContainerState
> {
  constructor(props: ContainerProps) {
    super(props)
    this.state = {
      fromYear: this.props.tableType === PROGRAM_TABLE ? 2003 : 2001,
      toYear: this.props.tableType === PROGRAM_TABLE ? 2018 : 2019,
      data: [],
      descriptionFilter: "",
      tableName: "",
      digitFilter: "5-Digit",
    }
    this.handleDescriptionFilterChange = this.handleDescriptionFilterChange.bind(
      this
    )
    this.handleDescriptionFilterChange = debounce(
      this.handleDescriptionFilterChange,
      500
    )
  }

  componentDidMount() {
    const { dataStore, test, tableType } = this.props
    const { fromYear, toYear } = this.state
    if (!test && dataStore) {
      setOauth(dataStore)
      const timeout = setInterval(() => {
        if (!dataStore.oauthLoading && dataStore.oauthToken) {
          if (tableType === INDUSTRY_TABLE) {
            const self = this
            industryApiCall(
              dataStore,
              dataStore.oauthToken,
              fromYear,
              toYear
            ).then(function() {
              const data = getTableData(tableType, dataStore, test)
              self.setState({ data })
            })
          } else if (tableType === OCCUPATION_TABLE) {
            const self = this
            occupationApiCall(
              dataStore,
              dataStore.oauthToken,
              fromYear,
              toYear
            ).then(function() {
              const data = getTableData(tableType, dataStore, test)
              self.setState({ data })
            })
          } else if (tableType === DEMOGRAPHICS_TABLE) {
            const self = this
            demographicsApiCall(
              dataStore,
              dataStore.oauthToken,
              fromYear,
              toYear
            ).then(function() {
              const data = getTableData(tableType, dataStore, test)
              self.setState({ data })
            })
          } else if (tableType === PROGRAM_TABLE) {
            const self = this
            programApiCall(
              dataStore,
              dataStore.oauthToken,
              fromYear,
              toYear
            ).then(function() {
              const data = getTableData(tableType, dataStore, test)
              self.setState({ data })
            })
          }
          clearInterval(timeout)
        }
      }, 500)
    }
  }

  updateApiCall() {
    const self = this
    const { dataStore, tableType, test } = this.props
    const { fromYear, toYear } = this.state
    if (dataStore !== undefined) {
      if (tableType === INDUSTRY_TABLE) {
        industryApiCall(dataStore, dataStore.oauthToken, fromYear, toYear).then(
          function() {
            const data = getTableData(tableType, dataStore, test)
            self.setState({ data })
          }
        )
      }
      if (tableType === OCCUPATION_TABLE) {
        occupationApiCall(
          dataStore,
          dataStore.oauthToken,
          fromYear,
          toYear
        ).then(function() {
          const data = getTableData(tableType, dataStore, test)
          self.setState({ data })
        })
      }
      if (tableType === DEMOGRAPHICS_TABLE) {
        demographicsApiCall(
          dataStore,
          dataStore.oauthToken,
          fromYear,
          toYear
        ).then(function() {
          const data = getTableData(tableType, dataStore, test)
          self.setState({ data })
        })
      }
    }
  }

  handleChange = (el: object) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const { name, value } = el.target
    const obj = {}
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    obj[name] = value
    if (name === "fromYear") {
      if (value >= this.state.toYear) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore ts doesn't like variable names for objects
        obj["toYear"] = parseInt(value) + 1
      }
    }

    if (name === "toYear") {
      if (value <= this.state.fromYear) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore ts doesn't like variable names for objects
        obj["toYear"] = parseInt(this.state.fromYear) + 1
      }
    }
    this.setState(obj, this.updateApiCall)
  }

  handleDescriptionFilterChange(descriptionFilter: string) {
    this.setState({ descriptionFilter })
  }

  handleDigitFilterChange = (digitFilter: string) => {
    this.setState({ digitFilter })
  }

  occupationTableDigitFilter(element: string, filterNumber: number) {
    switch (filterNumber) {
      case 2:
        return parseInt(element["soc"][0].slice(-4)) === 0
      case 3:
        return parseInt(element["soc"][0].slice(-3)) === 0
      case 4:
        return parseInt(element["soc"][0].slice(-1)) === 0
      case 5:
        return parseInt(element["soc"][0].slice(-1)) !== 0
      default:
        return true
    }
  }

  mapDigitFilter = (tableType: string, data: object[]) => {
    const { digitFilter } = this.state
    if (tableType === PROGRAM_TABLE) return data
    if (tableType === DEMOGRAPHICS_TABLE) return data
    if (tableType === INDUSTRY_TABLE) {
      const filterNumber = digitFilterToNumber(digitFilter)
      if (filterNumber === 0) return data
      return data.filter(el => {
        if (el["naics"][0].length === filterNumber) {
          return el
        }
      })
    }

    if (tableType === OCCUPATION_TABLE) {
      const filterNumber = digitFilterToNumber(digitFilter)
      if (filterNumber === 0) return data
      return data.filter(el => {
        if (this.occupationTableDigitFilter(el, filterNumber)) {
          return el
        }
      })
    }
    return data
  }

  mapFilter = (descriptionFilter: string, data: object[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    if (data.length > 0 && data[0].description !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      return data.filter(el =>
        el.description.toLowerCase().includes(descriptionFilter.toLowerCase())
      )
    }

    if (data.length > 0 && data[0].demographic !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore ts doesn't like variable names for objects
      return data.filter(el =>
        el.demographic.toLowerCase().includes(descriptionFilter.toLowerCase())
      )
    }
    return data
  }

  showDigitFilter(tableType: string) {
    if (tableType === OCCUPATION_TABLE) return true
    if (tableType === INDUSTRY_TABLE) return true
    return false
  }

  readableTableName = (tableType: string) => {
    if (tableType === OCCUPATION_TABLE) return "Occupation Table"
    if (tableType === INDUSTRY_TABLE) return "Industry Table"
    if (tableType === DEMOGRAPHICS_TABLE) return "Demographics Table"
    if (tableType === PROGRAM_TABLE) return "Program Table"
  }

  pluralTableName = (tableType: string) => {
    if (tableType === OCCUPATION_TABLE) return "Occupations"
    if (tableType === INDUSTRY_TABLE) return "Industries"
    if (tableType === DEMOGRAPHICS_TABLE) return "Demographics"
    if (tableType === PROGRAM_TABLE) return "Programs"
  }

  render() {
    const { test, tableType, dataStore } = this.props
    const { toYear, fromYear, data, descriptionFilter } = this.state
    const columns = setColumns(tableType, fromYear, toYear)
    const tableName = this.readableTableName(tableType)
    const pluralTablename = this.pluralTableName(tableType)
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore ts doesn't like variable names for objects
    const filteredData: TableData[] = this.mapDigitFilter(
      tableType,
      this.mapFilter(descriptionFilter, data)
    )
    return (
      <Styles>
        <div>
          <DescriptionFilter
            handleChange={this.handleDescriptionFilterChange}
            pluralTablename={pluralTablename}
            dataLength={filteredData.length}
            filterValue={descriptionFilter}
          />
          <TimeframeFilter
            toYear={toYear}
            fromYear={fromYear}
            handleChange={this.handleChange}
            tableType={tableType}
          />
          <EMSINotice />
        </div>
        {columns && data && (
          <TableDiv>
            <HeaderContainer>
              <TableHeader>{tableName}</TableHeader>{" "}
              <DataExport
                filteredData={filteredData}
                tableName={tableName}
                pluralTablename={pluralTablename}
              />
            </HeaderContainer>
            <SubHeader>
              All {pluralTablename} in Fayetteville-Springdale-Rogers, AR
            </SubHeader>
            {this.showDigitFilter(tableType) && (
              <DigitFilter
                onSelect={this.handleDigitFilterChange}
                tableType={tableType}
              />
            )}
            <Table
              columns={columns}
              data={filteredData}
              tableType={tableType}
            />
            {data.length == 0 && (
              <LoadingStyles>
                <Loading />
              </LoadingStyles>
            )}
          </TableDiv>
        )}
      </Styles>
    )
  }
}
