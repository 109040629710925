import axios from "axios"
import { DataStore } from "../../types/types"
import { transform } from "../utils"

const API_URL = "https://agnitio.emsicloud.com/emsi.us.completers/2020.1"
function constructQuery(fromYear: number, toYear: number) {
  return {
    metrics: [
      {
        name: `Completers.${fromYear}`,
        as: `fromCompletions`,
      },
      {
        name: `Completers.${toYear}`,
        as: `toCompletions`,
      },
    ],
    constraints: [
      {
        dimensionName: "Program",
        map: {
          "Agriculture, General": ["01.00"],
          "Agricultural Business and Management": ["01.01"],
          "Agricultural Mechanization": ["01.02"],
          "Agricultural Production Operations": ["01.03"],
          "Agricultural and Food Products Processing": ["01.04"],
          "Agricultural and Domestic Animal Services": ["01.05"],
          "Applied Horticulture and Horticultural Business Services": ["01.06"],
          "International Agriculture": ["01.07"],
          "Agricultural Public Services": ["01.08"],
          "Animal Sciences": ["01.09"],
          "Food Science and Technology": ["01.10"],
          "Plant Sciences": ["01.11"],
          "Soil Sciences": ["01.12"],
          "Agriculture, Agriculture Operations, and Related Sciences, Other": [
            "01.99",
          ],
          "Natural Resources Conservation and Research": ["03.01"],
          "Natural Resources Management and Policy": ["03.02"],
          "Fishing and Fisheries Sciences and Management": ["03.03"],
          Forestry: ["03.05"],
          "Wildlife and Wildlands Science and Management": ["03.06"],
          "Natural Resources and Conservation, Other": ["03.99"],
          Architecture: ["04.02"],
          "City/Urban, Community and Regional Planning": ["04.03"],
          "Environmental Design": ["04.04"],
          "Interior Architecture": ["04.05"],
          "Landscape Architecture": ["04.06"],
          "Architectural History and Criticism": ["04.08"],
          "Architectural Sciences and Technology": ["04.09"],
          "Real Estate Development": ["04.10"],
          "Architecture and Related Services, Other": ["04.99"],
          "Area Studies": ["05.01"],
          "Ethnic, Cultural Minority, Gender, and Group Studies": ["05.02"],
          "Communication and Media Studies": ["09.01"],
          Journalism: ["09.04"],
          "Radio, Television, and Digital Communication": ["09.07"],
          "Public Relations, Advertising, and Applied Communication": ["09.09"],
          Publishing: ["09.10"],
          "Communication, Journalism, and Related Programs, Other": ["09.99"],
          "Communications Technology/Technician": ["10.01"],
          "Audiovisual Communications Technologies/Technicians": ["10.02"],
          "Graphic Communications": ["10.03"],
          "Communications Technologies/Technicians and Support Services, Other": [
            "10.99",
          ],
          "Computer and Information Sciences, General": ["11.01"],
          "Computer Programming": ["11.02"],
          "Data Processing": ["11.03"],
          "Information Science/Studies": ["11.04"],
          "Computer Systems Analysis": ["11.05"],
          "Data Entry/Microcomputer Applications": ["11.06"],
          "Computer Science": ["11.07"],
          "Computer Software and Media Applications": ["11.08"],
          "Computer Systems Networking and Telecommunications": ["11.09"],
          "Computer/Information Technology Administration and Management": [
            "11.10",
          ],
          "Computer and Information Sciences and Support Services, Other": [
            "11.99",
          ],
          "Funeral Service and Mortuary Science": ["12.03"],
          "Cosmetology and Related Personal Grooming Services": ["12.04"],
          "Culinary Arts and Related Services": ["12.05"],
          "Personal and Culinary Services, Other": ["12.99"],
          "Education, General": ["13.01"],
          "Bilingual, Multilingual, and Multicultural Education": ["13.02"],
          "Curriculum and Instruction": ["13.03"],
          "Educational Administration and Supervision": ["13.04"],
          "Educational/Instructional Media Design": ["13.05"],
          "Educational Assessment, Evaluation, and Research": ["13.06"],
          "International and Comparative Education": ["13.07"],
          "Social and Philosophical Foundations of Education": ["13.09"],
          "Special Education and Teaching": ["13.10"],
          "Student Counseling and Personnel Services": ["13.11"],
          "Teacher Education and Professional Development, Specific Levels and Methods": [
            "13.12",
          ],
          "Teacher Education and Professional Development, Specific Subject Areas": [
            "13.13",
          ],
          "Teaching English or French as a Second or Foreign Language": [
            "13.14",
          ],
          "Teaching Assistants/Aides": ["13.15"],
          "Education, Other": ["13.99"],
          "Engineering, General": ["14.01"],
          "Aerospace, Aeronautical and Astronautical Engineering": ["14.02"],
          "Agricultural Engineering": ["14.03"],
          "Architectural Engineering": ["14.04"],
          "Biomedical/Medical Engineering": ["14.05"],
          "Ceramic Sciences and Engineering": ["14.06"],
          "Chemical Engineering": ["14.07"],
          "Civil Engineering": ["14.08"],
          "Computer Engineering": ["14.09"],
          "Electrical, Electronics and Communications Engineering": ["14.10"],
          "Engineering Mechanics": ["14.11"],
          "Engineering Physics": ["14.12"],
          "Engineering Science": ["14.13"],
          "Environmental/Environmental Health Engineering": ["14.14"],
          "Materials Engineering": ["14.18"],
          "Mechanical Engineering": ["14.19"],
          "Metallurgical Engineering": ["14.20"],
          "Mining and Mineral Engineering": ["14.21"],
          "Naval Architecture and Marine Engineering": ["14.22"],
          "Nuclear Engineering": ["14.23"],
          "Ocean Engineering": ["14.24"],
          "Petroleum Engineering": ["14.25"],
          "Systems Engineering": ["14.27"],
          "Textile Sciences and Engineering": ["14.28"],
          "Polymer/Plastics Engineering": ["14.32"],
          "Construction Engineering": ["14.33"],
          "Forest Engineering": ["14.34"],
          "Industrial Engineering": ["14.35"],
          "Manufacturing Engineering": ["14.36"],
          "Operations Research": ["14.37"],
          "Surveying Engineering": ["14.38"],
          "Geological/Geophysical Engineering": ["14.39"],
          "Paper Science and Engineering": ["14.40"],
          "Electromechanical Engineering": ["14.41"],
          "Mechatronics, Robotics, and Automation Engineering": ["14.42"],
          "Biochemical Engineering": ["14.43"],
          "Engineering Chemistry": ["14.44"],
          "Biological/Biosystems Engineering": ["14.45"],
          "Engineering, Other": ["14.99"],
          "Engineering Technology, General": ["15.00"],
          "Architectural Engineering Technologies/Technicians": ["15.01"],
          "Civil Engineering Technologies/Technicians": ["15.02"],
          "Electrical Engineering Technologies/Technicians": ["15.03"],
          "Electromechanical Instrumentation and Maintenance Technologies/Technicians": [
            "15.04",
          ],
          "Environmental Control Technologies/Technicians": ["15.05"],
          "Industrial Production Technologies/Technicians": ["15.06"],
          "Quality Control and Safety Technologies/Technicians": ["15.07"],
          "Mechanical Engineering Related Technologies/Technicians": ["15.08"],
          "Mining and Petroleum Technologies/Technicians": ["15.09"],
          "Construction Engineering Technologies": ["15.10"],
          "Engineering-Related Technologies": ["15.11"],
          "Computer Engineering Technologies/Technicians": ["15.12"],
          "Drafting/Design Engineering Technologies/Technicians": ["15.13"],
          "Nuclear Engineering Technologies/Technicians": ["15.14"],
          "Engineering-Related Fields": ["15.15"],
          Nanotechnology: ["15.16"],
          "Engineering Technologies/Technicians, Other": ["15.99"],
          "Linguistic, Comparative, and Related Language Studies and Services": [
            "16.01",
          ],
          "African Languages, Literatures, and Linguistics": ["16.02"],
          "East Asian Languages, Literatures, and Linguistics": ["16.03"],
          "Slavic, Baltic and Albanian Languages, Literatures, and Linguistics": [
            "16.04",
          ],
          "Germanic Languages, Literatures, and Linguistics": ["16.05"],
          "Modern Greek Language and Literature": ["16.06"],
          "South Asian Languages, Literatures, and Linguistics": ["16.07"],
          "Iranian/Persian Languages, Literatures, and Linguistics": ["16.08"],
          "Romance Languages, Literatures, and Linguistics": ["16.09"],
          "American Indian/Native American Languages, Literatures, and Linguistics": [
            "16.10",
          ],
          "Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics": [
            "16.11",
          ],
          "Classics and Classical Languages, Literatures, and Linguistics": [
            "16.12",
          ],
          "Celtic Languages, Literatures, and Linguistics": ["16.13"],
          "Southeast Asian and Australasian/Pacific Languages, Literatures, and Linguistics": [
            "16.14",
          ],
          "Turkic, Uralic-Altaic, Caucasian, and Central Asian Languages, Literatures, and Linguistics": [
            "16.15",
          ],
          "American Sign Language": ["16.16"],
          "Foreign Languages, Literatures, and Linguistics, Other": ["16.99"],
          "Work and Family Studies": ["19.00"],
          "Family and Consumer Sciences/Human Sciences, General": ["19.01"],
          "Family and Consumer Sciences/Human Sciences Business Services": [
            "19.02",
          ],
          "Family and Consumer Economics and Related Studies": ["19.04"],
          "Foods, Nutrition, and Related Services": ["19.05"],
          "Housing and Human Environments": ["19.06"],
          "Human Development, Family Studies, and Related Services": ["19.07"],
          "Apparel and Textiles": ["19.09"],
          "Family and Consumer Sciences/Human Sciences, Other": ["19.99"],
          "Non-Professional General Legal Studies (Undergraduate)": ["22.00"],
          Law: ["22.01"],
          "Legal Research and Advanced Professional Studies": ["22.02"],
          "Legal Support Services": ["22.03"],
          "Legal Professions and Studies, Other": ["22.99"],
          "English Language and Literature, General": ["23.01"],
          "Rhetoric and Composition/Writing Studies": ["23.13"],
          Literature: ["23.14"],
          "English Language and Literature/Letters, Other": ["23.99"],
          "Liberal Arts and Sciences, General Studies and Humanities": [
            "24.01",
          ],
          "Library Science and Administration": ["25.01"],
          "Library and Archives Assisting": ["25.03"],
          "Library Science, Other": ["25.99"],
          "Biology, General": ["26.01"],
          "Biochemistry, Biophysics and Molecular Biology": ["26.02"],
          "Botany/Plant Biology": ["26.03"],
          "Cell/Cellular Biology and Anatomical Sciences": ["26.04"],
          "Microbiological Sciences and Immunology": ["26.05"],
          "Zoology/Animal Biology": ["26.07"],
          Genetics: ["26.08"],
          "Physiology, Pathology and Related Sciences": ["26.09"],
          "Pharmacology and Toxicology": ["26.10"],
          "Biomathematics, Bioinformatics, and Computational Biology": [
            "26.11",
          ],
          Biotechnology: ["26.12"],
          "Ecology, Evolution, Systematics, and Population Biology": ["26.13"],
          "Molecular Medicine": ["26.14"],
          "Neurobiology and Neurosciences": ["26.15"],
          "Biological and Biomedical Sciences, Other": ["26.99"],
          Mathematics: ["27.01"],
          "Applied Mathematics": ["27.03"],
          Statistics: ["27.05"],
          "Mathematics and Statistics, Other": ["27.99"],
          "Air Force ROTC, Air Science and Operations": ["28.01"],
          "Army ROTC, Military Science and Operations": ["28.03"],
          "Navy/Marine ROTC, Naval Science and Operations": ["28.04"],
          "Military Science and Operational Studies": ["28.05"],
          "Security Policy and Strategy": ["28.06"],
          "Military Economics and Management": ["28.07"],
          "Military Science, Leadership and Operational Art, Other": ["28.99"],
          "Intelligence, Command Control and Information Operations": ["29.02"],
          "Military Applied Sciences": ["29.03"],
          "Military Systems and Maintenance Technology": ["29.04"],
          "Military Technologies and Applied Sciences, Other": ["29.99"],
          "Multi-/Interdisciplinary Studies, General": ["30.00"],
          "Biological and Physical Sciences": ["30.01"],
          "Peace Studies and Conflict Resolution": ["30.05"],
          "Systems Science and Theory": ["30.06"],
          "Mathematics and Computer Science": ["30.08"],
          Biopsychology: ["30.10"],
          Gerontology: ["30.11"],
          "Historic Preservation and Conservation": ["30.12"],
          "Medieval and Renaissance Studies": ["30.13"],
          "Museology/Museum Studies": ["30.14"],
          "Science, Technology and Society": ["30.15"],
          "Accounting and Computer Science": ["30.16"],
          "Behavioral Sciences": ["30.17"],
          "Natural Sciences": ["30.18"],
          "Nutrition Sciences": ["30.19"],
          "International/Global Studies": ["30.20"],
          "Holocaust and Related Studies": ["30.21"],
          "Classical and Ancient Studies": ["30.22"],
          "Intercultural/Multicultural and Diversity Studies": ["30.23"],
          "Cognitive Science": ["30.25"],
          "Cultural Studies/Critical Theory and Analysis": ["30.26"],
          "Human Biology": ["30.27"],
          "Dispute Resolution": ["30.28"],
          "Maritime Studies": ["30.29"],
          "Computational Science": ["30.30"],
          "Human Computer Interaction": ["30.31"],
          "Marine Sciences": ["30.32"],
          "Sustainability Studies": ["30.33"],
          "Multi/Interdisciplinary Studies, Other": ["30.99"],
          "Parks, Recreation and Leisure Studies": ["31.01"],
          "Parks, Recreation and Leisure Facilities Management": ["31.03"],
          "Health and Physical Education/Fitness": ["31.05"],
          "Outdoor Education": ["31.06"],
          "Parks, Recreation, Leisure, and Fitness Studies, Other": ["31.99"],
          "Basic Skills and Developmental/Remedial Education": ["32.01"],
          "Citizenship Activities": ["33.01"],
          "Health-Related Knowledge and Skills": ["34.01"],
          "Interpersonal and Social Skills": ["35.01"],
          "Leisure and Recreational Activities": ["36.01"],
          "Personal Awareness and Self-Improvement": ["37.01"],
          "Philosophy and Religious Studies, General": ["38.00"],
          Philosophy: ["38.01"],
          "Religion/Religious Studies": ["38.02"],
          "Philosophy and Religious Studies, Other": ["38.99"],
          "Bible/Biblical Studies": ["39.02"],
          "Missions/Missionary Studies and Missiology": ["39.03"],
          "Religious Education": ["39.04"],
          "Religious/Sacred Music": ["39.05"],
          "Theological and Ministerial Studies": ["39.06"],
          "Pastoral Counseling and Specialized Ministries": ["39.07"],
          "Theology and Religious Vocations, Other": ["39.99"],
          "Physical Sciences": ["40.01"],
          "Astronomy and Astrophysics": ["40.02"],
          "Atmospheric Sciences and Meteorology": ["40.04"],
          Chemistry: ["40.05"],
          "Geological and Earth Sciences/Geosciences": ["40.06"],
          Physics: ["40.08"],
          "Materials Sciences": ["40.10"],
          "Physical Sciences, Other": ["40.99"],
          "Science Technologies/Technicians, General": ["41.00"],
          "Biology Technician/Biotechnology Laboratory Technician": ["41.01"],
          "Nuclear and Industrial Radiologic Technologies/Technicians": [
            "41.02",
          ],
          "Physical Science Technologies/Technicians": ["41.03"],
          "Science Technologies/Technicians, Other": ["41.99"],
          "Psychology, General": ["42.01"],
          "Research and Experimental Psychology": ["42.27"],
          "Clinical, Counseling and Applied Psychology": ["42.28"],
          "Psychology, Other": ["42.99"],
          "Criminal Justice and Corrections": ["43.01"],
          "Fire Protection": ["43.02"],
          "Homeland Security": ["43.03"],
          "Homeland Security, Law Enforcement, Firefighting and Related Protective Services, Other": [
            "43.99",
          ],
          "Human Services, General": ["44.00"],
          "Community Organization and Advocacy": ["44.02"],
          "Public Administration": ["44.04"],
          "Public Policy Analysis": ["44.05"],
          "Social Work": ["44.07"],
          "Public Administration and Social Service Professions, Other": [
            "44.99",
          ],
          "Social Sciences, General": ["45.01"],
          Anthropology: ["45.02"],
          Archaeology: ["45.03"],
          Criminology: ["45.04"],
          "Demography and Population Studies": ["45.05"],
          Economics: ["45.06"],
          "Geography and Cartography": ["45.07"],
          "International Relations and National Security Studies": ["45.09"],
          "Political Science and Government": ["45.10"],
          Sociology: ["45.11"],
          "Urban Studies/Affairs": ["45.12"],
          "Sociology and Anthropology": ["45.13"],
          "Rural Sociology": ["45.14"],
          "Social Sciences, Other": ["45.99"],
          "Construction Trades, General": ["46.00"],
          "Mason/Masonry": ["46.01"],
          Carpenters: ["46.02"],
          "Electrical and Power Transmission Installers": ["46.03"],
          "Building/Construction Finishing, Management, and Inspection": [
            "46.04",
          ],
          "Plumbing and Related Water Supply Services": ["46.05"],
          "Construction Trades, Other": ["46.99"],
          "Mechanics and Repairers, General": ["47.00"],
          "Electrical/Electronics Maintenance and Repair Technology": ["47.01"],
          "Heating, Air Conditioning, Ventilation and Refrigeration Maintenance Technology/Technician (HAC, HACR, HVAC, HVACR)": [
            "47.02",
          ],
          "Heavy/Industrial Equipment Maintenance Technologies": ["47.03"],
          "Precision Systems Maintenance and Repair Technologies": ["47.04"],
          "Vehicle Maintenance and Repair Technologies": ["47.06"],
          "Mechanic and Repair Technologies/Technicians, Other": ["47.99"],
          "Precision Production Trades, General": ["48.00"],
          "Leatherworking and Upholstery": ["48.03"],
          "Precision Metal Working": ["48.05"],
          Woodworking: ["48.07"],
          "Boilermaking/Boilermaker": ["48.08"],
          "Precision Production, Other": ["48.99"],
          "Air Transportation": ["49.01"],
          "Ground Transportation": ["49.02"],
          "Marine Transportation": ["49.03"],
          "Transportation and Materials Moving, Other": ["49.99"],
          "Visual and Performing Arts, General": ["50.01"],
          "Crafts/Craft Design, Folk Art and Artisanry": ["50.02"],
          Dance: ["50.03"],
          "Design and Applied Arts": ["50.04"],
          "Drama/Theatre Arts and Stagecraft": ["50.05"],
          "Film/Video and Photographic Arts": ["50.06"],
          "Fine and Studio Arts": ["50.07"],
          Music: ["50.09"],
          "Arts, Entertainment,and Media Management": ["50.10"],
          "Visual and Performing Arts, Other": ["50.99"],
          "Health Services/Allied Health/Health Sciences, General": ["51.00"],
          Chiropractic: ["51.01"],
          "Communication Disorders Sciences and Services": ["51.02"],
          Dentistry: ["51.04"],
          "Advanced/Graduate Dentistry and Oral Sciences": ["51.05"],
          "Dental Support Services and Allied Professions": ["51.06"],
          "Health and Medical Administrative Services": ["51.07"],
          "Allied Health and Medical Assisting Services": ["51.08"],
          "Allied Health Diagnostic, Intervention, and Treatment Professions": [
            "51.09",
          ],
          "Clinical/Medical Laboratory Science/Research and Allied Professions": [
            "51.10",
          ],
          "Health/Medical Preparatory Programs": ["51.11"],
          Medicine: ["51.12"],
          "Medical Clinical Sciences/Graduate Medical Studies": ["51.14"],
          "Mental and Social Health Services and Allied Professions": ["51.15"],
          Optometry: ["51.17"],
          "Ophthalmic and Optometric Support Services and Allied Professions": [
            "51.18",
          ],
          "Osteopathic Medicine/Osteopathy": ["51.19"],
          "Pharmacy, Pharmaceutical Sciences, and Administration": ["51.20"],
          "Podiatric Medicine/Podiatry": ["51.21"],
          "Public Health": ["51.22"],
          "Rehabilitation and Therapeutic Professions": ["51.23"],
          "Veterinary Medicine": ["51.24"],
          "Veterinary Biomedical and Clinical Sciences": ["51.25"],
          "Health Aides/Attendants/Orderlies": ["51.26"],
          "Medical Illustration and Informatics": ["51.27"],
          "Dietetics and Clinical Nutrition Services": ["51.31"],
          "Bioethics/Medical Ethics": ["51.32"],
          "Alternative and Complementary Medicine and Medical Systems": [
            "51.33",
          ],
          "Alternative and Complementary Medical Support Services": ["51.34"],
          "Somatic Bodywork and Related Therapeutic Services": ["51.35"],
          "Movement and Mind-Body Therapies and Education": ["51.36"],
          "Energy and Biologically Based Therapies": ["51.37"],
          "Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing": [
            "51.38",
          ],
          "Practical Nursing, Vocational Nursing and Nursing Assistants": [
            "51.39",
          ],
          "Health Professions and Related Clinical Sciences, Other": ["51.99"],
          "Business/Commerce, General": ["52.01"],
          "Business Administration, Management and Operations": ["52.02"],
          "Accounting and Related Services": ["52.03"],
          "Business Operations Support and Assistant Services": ["52.04"],
          "Business/Corporate Communications": ["52.05"],
          "Business/Managerial Economics": ["52.06"],
          "Entrepreneurial and Small Business Operations": ["52.07"],
          "Finance and Financial Management Services": ["52.08"],
          "Hospitality Administration/Management": ["52.09"],
          "Human Resources Management and Services": ["52.10"],
          "International Business": ["52.11"],
          "Management Information Systems and Services": ["52.12"],
          "Management Sciences and Quantitative Methods": ["52.13"],
          Marketing: ["52.14"],
          "Real Estate": ["52.15"],
          Taxation: ["52.16"],
          Insurance: ["52.17"],
          "General Sales, Merchandising and Related Marketing Operations": [
            "52.18",
          ],
          "Specialized Sales, Merchandising and  Marketing Operations": [
            "52.19",
          ],
          "Construction Management": ["52.20"],
          "Telecommunications Management": ["52.21"],
          "Business, Management, Marketing, and Related Support Services, Other": [
            "52.99",
          ],
          "High School/Secondary Diploma Programs": ["53.01"],
          "High School/Secondary Certificate Programs": ["53.02"],
          History: ["54.01"],
          "Dental Residency Programs": ["60.01"],
          "Veterinary Residency Programs": ["60.03"],
          "Medical Residency Programs - General Certificates": ["60.04"],
          "Medical Residency Programs - Subspecialty Certificates": ["60.05"],
          "Podiatric Medicine Residency Programs": ["60.06"],
          "Undesignated field of study": ["95.95"],
        },
      },
      {
        dimensioNname: "Unit",
        map: {
          "Fayetteville-Springdale-Rogers, AR": [
            "106397",
            "107141",
            "107220",
            "107488",
            "107789",
            "367459",
            "443191",
            "446233",
            "448530",
            "450119",
            "454458",
            "467562",
            "486619",
            "490939",
          ],
        },
      },
    ],
  }
}

function dynamicSort(property) {
  var sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function transformName(name: string, fromYear: number, toYear: number) {
  if (name === "Program") {
    return "description"
  }
  return name
}

export function programApiCall(
  store: DataStore,
  token: string,
  fromYear: number,
  toYear: number
) {
  return axios({
    method: "POST",
    url: API_URL,
    data: constructQuery(fromYear, toYear),
    headers: {
      "content-type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      const programTable = true
      const transformedData = transform({
        data: response.data.data,
        fromYear,
        toYear,
        transformName,
        programTable,
      })

      const cipCodes = constructQuery(fromYear, toYear).constraints[0].map
      const finalizedData = transformedData.map(el => {
        el["cipCode"] = cipCodes[el.description][0]
        return el
      })
      const sortedData = finalizedData.sort(dynamicSort("cipCode"))

      store.updateProgramTable(sortedData)
    })
    .catch(function(error) {
      console.log("err", error)
    })
}
