import React from "react"
import styled from "styled-components"

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  background-color: white;
  padding: 20px;
  margin: -8px 30px 30px 2px;
  border-radius: 3px;
  border: 1px solid #ddd;
`

const StyledSpan = styled.div`
  font-size: 13px;
  margin-bottom: 4px;
  color: gray;
  margin-left: -3px;
`

const StyledLabel = styled.label`
  font-size: 14px;
  margin-top: -5px;
  margin-left: -3px;
`

const Input = styled.input`
  font-size: 16px;
  width: 103%;
  margin-left: -3px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 15px;
  padding-left: 8px;
  color: gray;
  margin-top: 5px;
`

interface DescriptionFilterProps {
  handleChange(descriptionFilter: string): void
  pluralTablename: string | undefined
  dataLength: number
  filterValue: string
}

interface El {
  target: {
    value: string
  }
}

function displayName(dataLength: number, pluralTablename: string | undefined) {
  if (dataLength === 1 && pluralTablename !== undefined) {
    switch (pluralTablename) {
      case "Demographics":
        return "Demographic"
      case "Industries":
        return "Industry"
      case "Programs":
        return "Program"
      case "Occupations":
        return "Occupation"
      default:
        return pluralTablename
    }
  }
  return pluralTablename
}

function subHeader(
  pluralTablename: string | undefined,
  filterValue: string,
  dataLength: number
) {
  if (filterValue !== undefined && filterValue.length > 0) {
    const name = displayName(dataLength, pluralTablename)
    return `${dataLength} ${name}`
  }
  return "No limit. Showing all available."
}

const DescriptionFilter: React.FC<DescriptionFilterProps> = (
  props: DescriptionFilterProps
) => {
  const name = displayName(1, props.pluralTablename)
  return (
    <Styles>
      <StyledLabel htmlFor="Filter">All {props.pluralTablename}:</StyledLabel>
      <StyledSpan>
        {subHeader(props.pluralTablename, props.filterValue, props.dataLength)}
      </StyledSpan>
      <Input
        type="text"
        id="Filter"
        onChange={(el: El) => props.handleChange(el.target.value)}
        placeholder={`Limit by ${name}...`}
      />
    </Styles>
  )
}

export default DescriptionFilter
