export function csv() {
  return `
"Level","NAICS","Description","2019 Jobs"
2,11,"Agriculture, Forestry, Fishing and Hunting",1087.08681575
3,111,"Crop Production",28.766784689
4,1110,"Crop Production",28.766784689
5,11100,"Crop Production",28.766784689
6,111000,"Crop Production",28.766784689
3,112,"Animal Production and Aquaculture",798.322127583
4,1120,"Animal Production",798.322127583
5,11200,"Animal Production",798.322127583
6,112000,"Animal Production",798.322127583
3,113,"Forestry and Logging",0
4,1131,"Timber Tract Operations",0
5,11311,"Timber Tract Operations",0
6,113110,"Timber Tract Operations",0
4,1132,"Forest Nurseries and Gathering of Forest Products",0
5,11321,"Forest Nurseries and Gathering of Forest Products",0
6,113210,"Forest Nurseries and Gathering of Forest Products",0
4,1133,"Logging",0
5,11331,"Logging",0
6,113310,"Logging",0
3,114,"Fishing, Hunting and Trapping",0
4,1141,"Fishing",0
5,11411,"Fishing",0
6,114111,"Finfish Fishing",0
6,114112,"Shellfish Fishing",0
6,114119,"Other Marine Fishing",0
4,1142,"Hunting and Trapping",0
5,11421,"Hunting and Trapping",0
6,114210,"Hunting and Trapping",0
3,115,"Support Activities for Agriculture and Forestry",259.997903481
4,1151,"Support Activities for Crop Production",164.924486029
5,11511,"Support Activities for Crop Production",164.924486029
6,115111,"Cotton Ginning",1.04511569477
6,115112,"Soil Preparation, Planting, and Cultivating",14.8326916692
6,115113,"Crop Harvesting, Primarily by Machine",1.28195723977
6,115114,"Postharvest Crop Activities (except Cotton Ginning)",29.9435814352
6,115115,"Farm Labor Contractors and Crew Leaders",116.568949408
6,115116,"Farm Management Services",1.25219058171
4,1152,"Support Activities for Animal Production",88.1359951565
5,11521,"Support Activities for Animal Production",88.1359951565
6,115210,"Support Activities for Animal Production",88.1359951565
4,1153,"Support Activities for Forestry",6.9374222957
5,11531,"Support Activities for Forestry",6.9374222957
6,115310,"Support Activities for Forestry",6.9374222957
2,21,"Mining, Quarrying, and Oil and Gas Extraction",7.00469095404
3,211,"Oil and Gas Extraction",3.81105645712
4,2111,"Oil and Gas Extraction",3.81105645712
5,21112,"Crude Petroleum Extraction",3.02435184155
6,211120,"Crude Petroleum Extraction",3.02435184155
5,21113,"Natural Gas Extraction",0.786704615565
6,211130,"Natural Gas Extraction",0.786704615565
3,212,"Mining (except Oil and Gas)",0
4,2121,"Coal Mining",0
5,21211,"Coal Mining",0
6,212111,"Bituminous Coal and Lignite Surface Mining",0
6,212112,"Bituminous Coal Underground Mining",0
6,212113,"Anthracite Mining",0
4,2122,"Metal Ore Mining",0
5,21221,"Iron Ore Mining",0
6,212210,"Iron Ore Mining",0
5,21222,"Gold Ore and Silver Ore Mining",0
6,212221,"Gold Ore Mining",0
6,212222,"Silver Ore Mining",0
5,21223,"Copper, Nickel, Lead, and Zinc Mining",0
6,212230,"Copper, Nickel, Lead, and Zinc Mining",0
5,21229,"Other Metal Ore Mining",0
6,212291,"Uranium-Radium-Vanadium Ore Mining",0
6,212299,"All Other Metal Ore Mining",0
4,2123,"Nonmetallic Mineral Mining and Quarrying",0
5,21231,"Stone Mining and Quarrying",0
6,212311,"Dimension Stone Mining and Quarrying",0
6,212312,"Crushed and Broken Limestone Mining and Quarrying",0
6,212313,"Crushed and Broken Granite Mining and Quarrying",0
6,212319,"Other Crushed and Broken Stone Mining and Quarrying",0
5,21232,"Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying",0
6,212321,"Construction Sand and Gravel Mining",0
6,212322,"Industrial Sand Mining",0
6,212324,"Kaolin and Ball Clay Mining",0
6,212325,"Clay and Ceramic and Refractory Minerals Mining",0
5,21239,"Other Nonmetallic Mineral Mining and Quarrying",0
6,212391,"Potash, Soda, and Borate Mineral Mining",0
6,212392,"Phosphate Rock Mining",0
6,212393,"Other Chemical and Fertilizer Mineral Mining",0
6,212399,"All Other Nonmetallic Mineral Mining",0
3,213,"Support Activities for Mining",3.19363449692
4,2131,"Support Activities for Mining",3.19363449692
5,21311,"Support Activities for Mining",3.19363449692
6,213111,"Drilling Oil and Gas Wells",2.24321416026
6,213112,"Support Activities for Oil and Gas Operations",0
6,213113,"Support Activities for Coal Mining",0
6,213114,"Support Activities for Metal Mining",0
6,213115,"Support Activities for Nonmetallic Minerals (except Fuels) Mining",0.950420336659
2,22,"Utilities",15.6422102251
3,221,"Utilities",15.6422102251
4,2211,"Electric Power Generation, Transmission and Distribution",13.398085951
5,22111,"Electric Power Generation",0
6,221111,"Hydroelectric Power Generation",0
6,221112,"Fossil Fuel Electric Power Generation",0
6,221113,"Nuclear Electric Power Generation",0
6,221114,"Solar Electric Power Generation",0
6,221115,"Wind Electric Power Generation",0
6,221116,"Geothermal Electric Power Generation",0
6,221117,"Biomass Electric Power Generation",0
6,221118,"Other Electric Power Generation",0
5,22112,"Electric Power Transmission, Control, and Distribution",13.398085951
6,221121,"Electric Bulk Power Transmission and Control",0
6,221122,"Electric Power Distribution",13.398085951
4,2212,"Natural Gas Distribution",2.24412427414
5,22121,"Natural Gas Distribution",2.24412427414
6,221210,"Natural Gas Distribution",2.24412427414
4,2213,"Water, Sewage and Other Systems",0
5,22131,"Water Supply and Irrigation Systems",0
6,221310,"Water Supply and Irrigation Systems",0
5,22132,"Sewage Treatment Facilities",0
6,221320,"Sewage Treatment Facilities",0
5,22133,"Steam and Air-Conditioning Supply",0
6,221330,"Steam and Air-Conditioning Supply",0
2,23,"Construction",3899.14082673
3,236,"Construction of Buildings",1279.16000493
4,2361,"Residential Building Construction",463.153737739
5,23611,"Residential Building Construction",463.153737739
6,236115,"New Single-Family Housing Construction (except For-Sale Builders)",279.534190265
6,236116,"New Multifamily Housing Construction (except For-Sale Builders)",24.9079923901
6,236117,"New Housing For-Sale Builders",11.6826085578
6,236118,"Residential Remodelers",147.028946526
4,2362,"Nonresidential Building Construction",816.006267188
5,23621,"Industrial Building Construction",22.0263120661
6,236210,"Industrial Building Construction",22.0263120661
5,23622,"Commercial and Institutional Building Construction",793.979955122
6,236220,"Commercial and Institutional Building Construction",793.979955122
3,237,"Heavy and Civil Engineering Construction",242.667668267
4,2371,"Utility System Construction",30.8712115684
5,23711,"Water and Sewer Line and Related Structures Construction",0
6,237110,"Water and Sewer Line and Related Structures Construction",0
5,23712,"Oil and Gas Pipeline and Related Structures Construction",6.46664653191
6,237120,"Oil and Gas Pipeline and Related Structures Construction",6.46664653191
5,23713,"Power and Communication Line and Related Structures Construction",24.4045650365
6,237130,"Power and Communication Line and Related Structures Construction",24.4045650365
4,2372,"Land Subdivision",33.3405878136
5,23721,"Land Subdivision",33.3405878136
6,237210,"Land Subdivision",33.3405878136
4,2373,"Highway, Street, and Bridge Construction",92.0920438314
5,23731,"Highway, Street, and Bridge Construction",92.0920438314
6,237310,"Highway, Street, and Bridge Construction",92.0920438314
4,2379,"Other Heavy and Civil Engineering Construction",86.3638250533
5,23799,"Other Heavy and Civil Engineering Construction",86.3638250533
6,237990,"Other Heavy and Civil Engineering Construction",86.3638250533
3,238,"Specialty Trade Contractors",2377.31315354
4,2381,"Foundation, Structure, and Building Exterior Contractors",481.249399431
5,23811,"Poured Concrete Foundation and Structure Contractors",101.472265955
6,238110,"Poured Concrete Foundation and Structure Contractors",101.472265955
5,23812,"Structural Steel and Precast Concrete Contractors",41.053412036
6,238120,"Structural Steel and Precast Concrete Contractors",41.053412036
5,23813,"Framing Contractors",57.4607298656
6,238130,"Framing Contractors",57.4607298656
5,23814,"Masonry Contractors",21.6732632171
6,238140,"Masonry Contractors",21.6732632171
5,23815,"Glass and Glazing Contractors",81.224163648
6,238150,"Glass and Glazing Contractors",81.224163648
5,23816,"Roofing Contractors",118.885890233
6,238160,"Roofing Contractors",118.885890233
5,23817,"Siding Contractors",14.0824902157
6,238170,"Siding Contractors",14.0824902157
5,23819,"Other Foundation, Structure, and Building Exterior Contractors",45.3971842616
6,238190,"Other Foundation, Structure, and Building Exterior Contractors",45.3971842616
4,2382,"Building Equipment Contractors",663.581067919
5,23821,"Electrical Contractors and Other Wiring Installation Contractors",231.920432303
6,238210,"Electrical Contractors and Other Wiring Installation Contractors",231.920432303
5,23822,"Plumbing, Heating, and Air-Conditioning Contractors",356.73060708
6,238220,"Plumbing, Heating, and Air-Conditioning Contractors",356.73060708
5,23829,"Other Building Equipment Contractors",74.930028536
6,238290,"Other Building Equipment Contractors",74.930028536
4,2383,"Building Finishing Contractors",562.914389125
5,23831,"Drywall and Insulation Contractors",204.618932792
6,238310,"Drywall and Insulation Contractors",204.618932792
5,23832,"Painting and Wall Covering Contractors",261.698924872
6,238320,"Painting and Wall Covering Contractors",261.698924872
5,23833,"Flooring Contractors",51.2116827973
6,238330,"Flooring Contractors",51.2116827973
5,23834,"Tile and Terrazzo Contractors",13.4274769678
6,238340,"Tile and Terrazzo Contractors",13.4274769678
5,23835,"Finish Carpentry Contractors",31.9573716956
6,238350,"Finish Carpentry Contractors",31.9573716956
5,23839,"Other Building Finishing Contractors",0
6,238390,"Other Building Finishing Contractors",0
4,2389,"Other Specialty Trade Contractors",669.568297065
5,23891,"Site Preparation Contractors",438.477740364
6,238910,"Site Preparation Contractors",438.477740364
5,23899,"All Other Specialty Trade Contractors",231.090556701
6,238990,"All Other Specialty Trade Contractors",231.090556701
2,31,"Manufacturing",6723.78194956
3,311,"Food Manufacturing",3271.83154298
4,3111,"Animal Food Manufacturing",0
5,31111,"Animal Food Manufacturing",0
6,311111,"Dog and Cat Food Manufacturing",0
6,311119,"Other Animal Food Manufacturing",0
4,3112,"Grain and Oilseed Milling",0
5,31121,"Flour Milling and Malt Manufacturing",0
6,311211,"Flour Milling",0
6,311212,"Rice Milling",0
6,311213,"Malt Manufacturing",0
5,31122,"Starch and Vegetable Fats and Oils Manufacturing",0
6,311221,"Wet Corn Milling",0
6,311224,"Soybean and Other Oilseed Processing",0
6,311225,"Fats and Oils Refining and Blending",0
5,31123,"Breakfast Cereal Manufacturing",0
6,311230,"Breakfast Cereal Manufacturing",0
4,3113,"Sugar and Confectionery Product Manufacturing",0
5,31131,"Sugar Manufacturing",0
6,311313,"Beet Sugar Manufacturing",0
6,311314,"Cane Sugar Manufacturing",0
5,31134,"Nonchocolate Confectionery Manufacturing",0
6,311340,"Nonchocolate Confectionery Manufacturing",0
5,31135,"Chocolate and Confectionery Manufacturing",0
6,311351,"Chocolate and Confectionery Manufacturing from Cacao Beans",0
6,311352,"Confectionery Manufacturing from Purchased Chocolate",0
4,3114,"Fruit and Vegetable Preserving and Specialty Food Manufacturing",78.6274464129
5,31141,"Frozen Food Manufacturing",38.6902499827
6,311411,"Frozen Fruit, Juice, and Vegetable Manufacturing",0.455939628249
6,311412,"Frozen Specialty Food Manufacturing",38.2343103545
5,31142,"Fruit and Vegetable Canning, Pickling, and Drying",39.9371964302
6,311421,"Fruit and Vegetable Canning",39.9371964302
6,311422,"Specialty Canning",0
6,311423,"Dried and Dehydrated Food Manufacturing",0
4,3115,"Dairy Product Manufacturing",0
5,31151,"Dairy Product (except Frozen) Manufacturing",0
6,311511,"Fluid Milk Manufacturing",0
6,311512,"Creamery Butter Manufacturing",0
6,311513,"Cheese Manufacturing",0
6,311514,"Dry, Condensed, and Evaporated Dairy Product Manufacturing",0
5,31152,"Ice Cream and Frozen Dessert Manufacturing",0
6,311520,"Ice Cream and Frozen Dessert Manufacturing",0
4,3116,"Animal Slaughtering and Processing",2859.27466057
5,31161,"Animal Slaughtering and Processing",2859.27466057
6,311611,"Animal (except Poultry) Slaughtering",0
6,311612,"Meat Processed from Carcasses",3.60346991264
6,311613,"Rendering and Meat Byproduct Processing",0
6,311615,"Poultry Processing",2855.67119066
4,3117,"Seafood Product Preparation and Packaging",1.25794221777
5,31171,"Seafood Product Preparation and Packaging",1.25794221777
6,311710,"Seafood Product Preparation and Packaging",1.25794221777
4,3118,"Bakeries and Tortilla Manufacturing",210.925790027
5,31181,"Bread and Bakery Product Manufacturing",193.663367612
6,311811,"Retail Bakeries",46.745994865
6,311812,"Commercial Bakeries",146.917372746
6,311813,"Frozen Cakes, Pies, and Other Pastries Manufacturing",0
5,31182,"Cookie, Cracker, and Pasta Manufacturing",0.129419530366
6,311821,"Cookie and Cracker Manufacturing",0.063056596068
6,311824,"Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour",0.0663629342975
5,31183,"Tortilla Manufacturing",17.1330028849
6,311830,"Tortilla Manufacturing",17.1330028849
4,3119,"Other Food Manufacturing",121.745703751
5,31191,"Snack Food Manufacturing",52.5432847989
6,311911,"Roasted Nuts and Peanut Butter Manufacturing",0
6,311919,"Other Snack Food Manufacturing",52.5432847989
5,31192,"Coffee and Tea Manufacturing",2.14652967758
6,311920,"Coffee and Tea Manufacturing",2.14652967758
5,31193,"Flavoring Syrup and Concentrate Manufacturing",0
6,311930,"Flavoring Syrup and Concentrate Manufacturing",0
5,31194,"Seasoning and Dressing Manufacturing",67.0558892745
6,311941,"Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing",67.0558892745
6,311942,"Spice and Extract Manufacturing",0
5,31199,"All Other Food Manufacturing",0
6,311991,"Perishable Prepared Food Manufacturing",0
6,311999,"All Other Miscellaneous Food Manufacturing",0
3,312,"Beverage and Tobacco Product Manufacturing",106.155199761
4,3121,"Beverage Manufacturing",106.155199761
5,31211,"Soft Drink and Ice Manufacturing",49.3281595737
6,312111,"Soft Drink Manufacturing",0
6,312112,"Bottled Water Manufacturing",41.2630755
6,312113,"Ice Manufacturing",8.06508407373
5,31212,"Breweries",52.1809915244
6,312120,"Breweries",52.1809915244
5,31213,"Wineries",1.06339669713
6,312130,"Wineries",1.06339669713
5,31214,"Distilleries",3.58265196588
6,312140,"Distilleries",3.58265196588
4,3122,"Tobacco Manufacturing",0
5,31223,"Tobacco Manufacturing",0
6,312230,"Tobacco Manufacturing",0
3,313,"Textile Mills",1.27744773366
4,3131,"Fiber, Yarn, and Thread Mills",0
5,31311,"Fiber, Yarn, and Thread Mills",0
6,313110,"Fiber, Yarn, and Thread Mills",0
4,3132,"Fabric Mills",0
5,31321,"Broadwoven Fabric Mills",0
6,313210,"Broadwoven Fabric Mills",0
5,31322,"Narrow Fabric Mills and Schiffli Machine Embroidery",0
6,313220,"Narrow Fabric Mills and Schiffli Machine Embroidery",0
5,31323,"Nonwoven Fabric Mills",0
6,313230,"Nonwoven Fabric Mills",0
5,31324,"Knit Fabric Mills",0
6,313240,"Knit Fabric Mills",0
4,3133,"Textile and Fabric Finishing and Fabric Coating Mills",1.27744773366
5,31331,"Textile and Fabric Finishing Mills",1.27744773366
6,313310,"Textile and Fabric Finishing Mills",1.27744773366
5,31332,"Fabric Coating Mills",0
6,313320,"Fabric Coating Mills",0
3,314,"Textile Product Mills",472.206089576
4,3141,"Textile Furnishings Mills",1.30055264151
5,31411,"Carpet and Rug Mills",0
6,314110,"Carpet and Rug Mills",0
5,31412,"Curtain and Linen Mills",1.30055264151
6,314120,"Curtain and Linen Mills",1.30055264151
4,3149,"Other Textile Product Mills",470.905536934
5,31491,"Textile Bag and Canvas Mills",66.1283244462
6,314910,"Textile Bag and Canvas Mills",66.1283244462
5,31499,"All Other Textile Product Mills",404.777212488
6,314994,"Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills",369.57617925
6,314999,"All Other Miscellaneous Textile Product Mills",35.2010332379
3,315,"Apparel Manufacturing",17.1664814486
4,3151,"Apparel Knitting Mills",0
5,31511,"Hosiery and Sock Mills",0
6,315110,"Hosiery and Sock Mills",0
5,31519,"Other Apparel Knitting Mills",0
6,315190,"Other Apparel Knitting Mills",0
4,3152,"Cut and Sew Apparel Manufacturing",17.1664814486
5,31521,"Cut and Sew Apparel Contractors",6.77717846589
6,315210,"Cut and Sew Apparel Contractors",6.77717846589
5,31522,"Men’s and Boys’ Cut and Sew Apparel Manufacturing",0
6,315220,"Men’s and Boys’ Cut and Sew Apparel Manufacturing",0
5,31524,"Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing",6.55138090747
6,315240,"Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing",6.55138090747
5,31528,"Other Cut and Sew Apparel Manufacturing",3.83792207526
6,315280,"Other Cut and Sew Apparel Manufacturing",3.83792207526
4,3159,"Apparel Accessories and Other Apparel Manufacturing",0
5,31599,"Apparel Accessories and Other Apparel Manufacturing",0
6,315990,"Apparel Accessories and Other Apparel Manufacturing",0
3,316,"Leather and Allied Product Manufacturing",2.92570118658
4,3161,"Leather and Hide Tanning and Finishing",0
5,31611,"Leather and Hide Tanning and Finishing",0
6,316110,"Leather and Hide Tanning and Finishing",0
4,3162,"Footwear Manufacturing",0
5,31621,"Footwear Manufacturing",0
6,316210,"Footwear Manufacturing",0
4,3169,"Other Leather and Allied Product Manufacturing",2.92570118658
5,31699,"Other Leather and Allied Product Manufacturing",2.92570118658
6,316992,"Women's Handbag and Purse Manufacturing",0
6,316998,"All Other Leather Good and Allied Product Manufacturing",2.92570118658
3,321,"Wood Product Manufacturing",44.1306880667
4,3211,"Sawmills and Wood Preservation",15.2157322974
5,32111,"Sawmills and Wood Preservation",15.2157322974
6,321113,"Sawmills",15.2157322974
6,321114,"Wood Preservation",0
4,3212,"Veneer, Plywood, and Engineered Wood Product Manufacturing",2.61081757837
5,32121,"Veneer, Plywood, and Engineered Wood Product Manufacturing",2.61081757837
6,321211,"Hardwood Veneer and Plywood Manufacturing",0
6,321212,"Softwood Veneer and Plywood Manufacturing",0.943393162554
6,321213,"Engineered Wood Member (except Truss) Manufacturing",0
6,321214,"Truss Manufacturing",1.66742441581
6,321219,"Reconstituted Wood Product Manufacturing",0
4,3219,"Other Wood Product Manufacturing",26.304138191
5,32191,"Millwork",11.030540589
6,321911,"Wood Window and Door Manufacturing",0
6,321912,"Cut Stock, Resawing Lumber, and Planing",1.65331918842
6,321918,"Other Millwork (including Flooring)",9.3772214006
5,32192,"Wood Container and Pallet Manufacturing",0
6,321920,"Wood Container and Pallet Manufacturing",0
5,32199,"All Other Wood Product Manufacturing",15.273597602
6,321991,"Manufactured Home (Mobile Home) Manufacturing",0.575754903594
6,321992,"Prefabricated Wood Building Manufacturing",0
6,321999,"All Other Miscellaneous Wood Product Manufacturing",14.6978426984
3,322,"Paper Manufacturing",157.6530935
4,3221,"Pulp, Paper, and Paperboard Mills",7.83841075
5,32211,"Pulp Mills",0
6,322110,"Pulp Mills",0
5,32212,"Paper Mills",7.83841075
6,322121,"Paper (except Newsprint) Mills",7.83841075
6,322122,"Newsprint Mills",0
5,32213,"Paperboard Mills",0
6,322130,"Paperboard Mills",0
4,3222,"Converted Paper Product Manufacturing",149.81468275
5,32221,"Paperboard Container Manufacturing",139.96332725
6,322211,"Corrugated and Solid Fiber Box Manufacturing",139.96332725
6,322212,"Folding Paperboard Box Manufacturing",0
6,322219,"Other Paperboard Container Manufacturing",0
5,32222,"Paper Bag and Coated and Treated Paper Manufacturing",0
6,322220,"Paper Bag and Coated and Treated Paper Manufacturing",0
5,32223,"Stationery Product Manufacturing",9.8513555
6,322230,"Stationery Product Manufacturing",9.8513555
5,32229,"Other Converted Paper Product Manufacturing",0
6,322291,"Sanitary Paper Product Manufacturing",0
6,322299,"All Other Converted Paper Product Manufacturing",0
3,323,"Printing and Related Support Activities",237.337157638
4,3231,"Printing and Related Support Activities",237.337157638
5,32311,"Printing",218.29379328
6,323111,"Commercial Printing (except Screen and Books)",184.625740889
6,323113,"Commercial Screen Printing",33.6680523906
6,323117,"Books Printing",0
5,32312,"Support Activities for Printing",19.043364358
6,323120,"Support Activities for Printing",19.043364358
3,324,"Petroleum and Coal Products Manufacturing",0
4,3241,"Petroleum and Coal Products Manufacturing",0
5,32411,"Petroleum Refineries",0
6,324110,"Petroleum Refineries",0
5,32412,"Asphalt Paving, Roofing, and Saturated Materials Manufacturing",0
6,324121,"Asphalt Paving Mixture and Block Manufacturing",0
6,324122,"Asphalt Shingle and Coating Materials Manufacturing",0
5,32419,"Other Petroleum and Coal Products Manufacturing",0
6,324191,"Petroleum Lubricating Oil and Grease Manufacturing",0
6,324199,"All Other Petroleum and Coal Products Manufacturing",0
3,325,"Chemical Manufacturing",14.3314910883
4,3251,"Basic Chemical Manufacturing",0
5,32511,"Petrochemical Manufacturing",0
6,325110,"Petrochemical Manufacturing",0
5,32512,"Industrial Gas Manufacturing",0
6,325120,"Industrial Gas Manufacturing",0
5,32513,"Synthetic Dye and Pigment Manufacturing",0
6,325130,"Synthetic Dye and Pigment Manufacturing",0
5,32518,"Other Basic Inorganic Chemical Manufacturing",0
6,325180,"Other Basic Inorganic Chemical Manufacturing",0
5,32519,"Other Basic Organic Chemical Manufacturing",0
6,325193,"Ethyl Alcohol Manufacturing",0
6,325194,"Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing",0
6,325199,"All Other Basic Organic Chemical Manufacturing",0
4,3252,"Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing",0
5,32521,"Resin and Synthetic Rubber Manufacturing",0
6,325211,"Plastics Material and Resin Manufacturing",0
6,325212,"Synthetic Rubber Manufacturing",0
5,32522,"Artificial and Synthetic Fibers and Filaments Manufacturing",0
6,325220,"Artificial and Synthetic Fibers and Filaments Manufacturing",0
4,3253,"Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing",2.01105011782
5,32531,"Fertilizer Manufacturing",2.01105011782
6,325311,"Nitrogenous Fertilizer Manufacturing",2.01105011782
6,325312,"Phosphatic Fertilizer Manufacturing",0
6,325314,"Fertilizer (Mixing Only) Manufacturing",0
5,32532,"Pesticide and Other Agricultural Chemical Manufacturing",0
6,325320,"Pesticide and Other Agricultural Chemical Manufacturing",0
4,3254,"Pharmaceutical and Medicine Manufacturing",10.764846574
5,32541,"Pharmaceutical and Medicine Manufacturing",10.764846574
6,325411,"Medicinal and Botanical Manufacturing",4.3024371861
6,325412,"Pharmaceutical Preparation Manufacturing",5.80385678185
6,325413,"In-Vitro Diagnostic Substance Manufacturing",0
6,325414,"Biological Product (except Diagnostic) Manufacturing",0.658552606003
4,3255,"Paint, Coating, and Adhesive Manufacturing",0.75322373792
5,32551,"Paint and Coating Manufacturing",0.75322373792
6,325510,"Paint and Coating Manufacturing",0.75322373792
5,32552,"Adhesive Manufacturing",0
6,325520,"Adhesive Manufacturing",0
4,3256,"Soap, Cleaning Compound, and Toilet Preparation Manufacturing",0.802370658623
5,32561,"Soap and Cleaning Compound Manufacturing",0.802370658623
6,325611,"Soap and Other Detergent Manufacturing",0
6,325612,"Polish and Other Sanitation Good Manufacturing",0.802370658623
6,325613,"Surface Active Agent Manufacturing",0
5,32562,"Toilet Preparation Manufacturing",0
6,325620,"Toilet Preparation Manufacturing",0
4,3259,"Other Chemical Product and Preparation Manufacturing",0
5,32591,"Printing Ink Manufacturing",0
6,325910,"Printing Ink Manufacturing",0
5,32592,"Explosives Manufacturing",0
6,325920,"Explosives Manufacturing",0
5,32599,"All Other Chemical Product and Preparation Manufacturing",0
6,325991,"Custom Compounding of Purchased Resins",0
6,325992,"Photographic Film, Paper, Plate, and Chemical Manufacturing",0
6,325998,"All Other Miscellaneous Chemical Product and Preparation Manufacturing",0
3,326,"Plastics and Rubber Products Manufacturing",1134.87914906
4,3261,"Plastics Product Manufacturing",1134.87914906
5,32611,"Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing",628.46047925
6,326111,"Plastics Bag and Pouch Manufacturing",0
6,326112,"Plastics Packaging Film and Sheet (including Laminated) Manufacturing",0
6,326113,"Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing",628.46047925
5,32612,"Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing",0
6,326121,"Unlaminated Plastics Profile Shape Manufacturing",0
6,326122,"Plastics Pipe and Pipe Fitting Manufacturing",0
5,32613,"Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing",134.110100369
6,326130,"Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing",134.110100369
5,32614,"Polystyrene Foam Product Manufacturing",0
6,326140,"Polystyrene Foam Product Manufacturing",0
5,32615,"Urethane and Other Foam Product (except Polystyrene) Manufacturing",198.66904404
6,326150,"Urethane and Other Foam Product (except Polystyrene) Manufacturing",198.66904404
5,32616,"Plastics Bottle Manufacturing",0
6,326160,"Plastics Bottle Manufacturing",0
5,32619,"Other Plastics Product Manufacturing",173.639525401
6,326191,"Plastics Plumbing Fixture Manufacturing",0
6,326199,"All Other Plastics Product Manufacturing",173.639525401
4,3262,"Rubber Product Manufacturing",0
5,32621,"Tire Manufacturing",0
6,326211,"Tire Manufacturing (except Retreading)",0
6,326212,"Tire Retreading",0
5,32622,"Rubber and Plastics Hoses and Belting Manufacturing",0
6,326220,"Rubber and Plastics Hoses and Belting Manufacturing",0
5,32629,"Other Rubber Product Manufacturing",0
6,326291,"Rubber Product Manufacturing for Mechanical Use",0
6,326299,"All Other Rubber Product Manufacturing",0
3,327,"Nonmetallic Mineral Product Manufacturing",52.9955172142
4,3271,"Clay Product and Refractory Manufacturing",0
5,32711,"Pottery, Ceramics, and Plumbing Fixture Manufacturing",0
6,327110,"Pottery, Ceramics, and Plumbing Fixture Manufacturing",0
5,32712,"Clay Building Material and Refractories Manufacturing",0
6,327120,"Clay Building Material and Refractories Manufacturing",0
4,3272,"Glass and Glass Product Manufacturing",1.83503942597
5,32721,"Glass and Glass Product Manufacturing",1.83503942597
6,327211,"Flat Glass Manufacturing",0
6,327212,"Other Pressed and Blown Glass and Glassware Manufacturing",1.22120645932
6,327213,"Glass Container Manufacturing",0
6,327215,"Glass Product Manufacturing Made of Purchased Glass",0.613832966648
4,3273,"Cement and Concrete Product Manufacturing",24.8833857105
5,32731,"Cement Manufacturing",0
6,327310,"Cement Manufacturing",0
5,32732,"Ready-Mix Concrete Manufacturing",24.8833857105
6,327320,"Ready-Mix Concrete Manufacturing",24.8833857105
5,32733,"Concrete Pipe, Brick, and Block Manufacturing",0
6,327331,"Concrete Block and Brick Manufacturing",0
6,327332,"Concrete Pipe Manufacturing",0
5,32739,"Other Concrete Product Manufacturing",0
6,327390,"Other Concrete Product Manufacturing",0
4,3274,"Lime and Gypsum Product Manufacturing",0
5,32741,"Lime Manufacturing",0
6,327410,"Lime Manufacturing",0
5,32742,"Gypsum Product Manufacturing",0
6,327420,"Gypsum Product Manufacturing",0
4,3279,"Other Nonmetallic Mineral Product Manufacturing",26.2770920777
5,32791,"Abrasive Product Manufacturing",0
6,327910,"Abrasive Product Manufacturing",0
5,32799,"All Other Nonmetallic Mineral Product Manufacturing",26.2770920777
6,327991,"Cut Stone and Stone Product Manufacturing",4.2045305687
6,327992,"Ground or Treated Mineral and Earth Manufacturing",0
6,327993,"Mineral Wool Manufacturing",0.551090242573
6,327999,"All Other Miscellaneous Nonmetallic Mineral Product Manufacturing",21.5214712664
3,331,"Primary Metal Manufacturing",0
4,3311,"Iron and Steel Mills and Ferroalloy Manufacturing",0
5,33111,"Iron and Steel Mills and Ferroalloy Manufacturing",0
6,331110,"Iron and Steel Mills and Ferroalloy Manufacturing",0
4,3312,"Steel Product Manufacturing from Purchased Steel",0
5,33121,"Iron and Steel Pipe and Tube Manufacturing from Purchased Steel",0
6,331210,"Iron and Steel Pipe and Tube Manufacturing from Purchased Steel",0
5,33122,"Rolling and Drawing of Purchased Steel",0
6,331221,"Rolled Steel Shape Manufacturing",0
6,331222,"Steel Wire Drawing",0
4,3313,"Alumina and Aluminum Production and Processing",0
5,33131,"Alumina and Aluminum Production and Processing",0
6,331313,"Alumina Refining and Primary Aluminum Production",0
6,331314,"Secondary Smelting and Alloying of Aluminum",0
6,331315,"Aluminum Sheet, Plate, and Foil Manufacturing",0
6,331318,"Other Aluminum Rolling, Drawing, and Extruding",0
4,3314,"Nonferrous Metal (except Aluminum) Production and Processing",0
5,33141,"Nonferrous Metal (except Aluminum) Smelting and Refining",0
6,331410,"Nonferrous Metal (except Aluminum) Smelting and Refining",0
5,33142,"Copper Rolling, Drawing, Extruding, and Alloying",0
6,331420,"Copper Rolling, Drawing, Extruding, and Alloying",0
5,33149,"Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying",0
6,331491,"Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding",0
6,331492,"Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)",0
4,3315,"Foundries",0
5,33151,"Ferrous Metal Foundries",0
6,331511,"Iron Foundries",0
6,331512,"Steel Investment Foundries",0
6,331513,"Steel Foundries (except Investment)",0
5,33152,"Nonferrous Metal Foundries",0
6,331523,"Nonferrous Metal Die-Casting Foundries",0
6,331524,"Aluminum Foundries (except Die-Casting)",0
6,331529,"Other Nonferrous Metal Foundries (except Die-Casting)",0
3,332,"Fabricated Metal Product Manufacturing",207.408991831
4,3321,"Forging and Stamping",0
5,33211,"Forging and Stamping",0
6,332111,"Iron and Steel Forging",0
6,332112,"Nonferrous Forging",0
6,332114,"Custom Roll Forming",0
6,332117,"Powder Metallurgy Part Manufacturing",0
6,332119,"Metal Crown, Closure, and Other Metal Stamping (except Automotive)",0
4,3322,"Cutlery and Handtool Manufacturing",0.354788087132
5,33221,"Cutlery and Handtool Manufacturing",0.354788087132
6,332215,"Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing",0.354788087132
6,332216,"Saw Blade and Handtool Manufacturing",0
4,3323,"Architectural and Structural Metals Manufacturing",24.2899741775
5,33231,"Plate Work and Fabricated Structural Product Manufacturing",0
6,332311,"Prefabricated Metal Building and Component Manufacturing",0
6,332312,"Fabricated Structural Metal Manufacturing",0
6,332313,"Plate Work Manufacturing",0
5,33232,"Ornamental and Architectural Metal Products Manufacturing",24.2899741775
6,332321,"Metal Window and Door Manufacturing",0
6,332322,"Sheet Metal Work Manufacturing",19.7387159625
6,332323,"Ornamental and Architectural Metal Work Manufacturing",4.55125821504
4,3324,"Boiler, Tank, and Shipping Container Manufacturing",0
5,33241,"Power Boiler and Heat Exchanger Manufacturing",0
6,332410,"Power Boiler and Heat Exchanger Manufacturing",0
5,33242,"Metal Tank (Heavy Gauge) Manufacturing",0
6,332420,"Metal Tank (Heavy Gauge) Manufacturing",0
5,33243,"Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing",0
6,332431,"Metal Can Manufacturing",0
6,332439,"Other Metal Container Manufacturing",0
4,3325,"Hardware Manufacturing",0
5,33251,"Hardware Manufacturing",0
6,332510,"Hardware Manufacturing",0
4,3326,"Spring and Wire Product Manufacturing",24.5578798112
5,33261,"Spring and Wire Product Manufacturing",24.5578798112
6,332613,"Spring Manufacturing",0
6,332618,"Other Fabricated Wire Product Manufacturing",24.5578798112
4,3327,"Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing",63.9174101243
5,33271,"Machine Shops",33.1246510738
6,332710,"Machine Shops",33.1246510738
5,33272,"Turned Product and Screw, Nut, and Bolt Manufacturing",30.7927590506
6,332721,"Precision Turned Product Manufacturing",30.7927590506
6,332722,"Bolt, Nut, Screw, Rivet, and Washer Manufacturing",0
4,3328,"Coating, Engraving, Heat Treating, and Allied Activities",0.747434637982
5,33281,"Coating, Engraving, Heat Treating, and Allied Activities",0.747434637982
6,332811,"Metal Heat Treating",0
6,332812,"Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers",0.747434637982
6,332813,"Electroplating, Plating, Polishing, Anodizing, and Coloring",0
4,3329,"Other Fabricated Metal Product Manufacturing",93.5415049927
5,33291,"Metal Valve Manufacturing",2.12408419438
6,332911,"Industrial Valve Manufacturing",0
6,332912,"Fluid Power Valve and Hose Fitting Manufacturing",2.12408419438
6,332913,"Plumbing Fixture Fitting and Trim Manufacturing",0
6,332919,"Other Metal Valve and Pipe Fitting Manufacturing",0
5,33299,"All Other Fabricated Metal Product Manufacturing",91.4174207984
6,332991,"Ball and Roller Bearing Manufacturing",0
6,332992,"Small Arms Ammunition Manufacturing",0
6,332993,"Ammunition (except Small Arms) Manufacturing",0
6,332994,"Small Arms, Ordnance, and Ordnance Accessories Manufacturing",0
6,332996,"Fabricated Pipe and Pipe Fitting Manufacturing",90.285538
6,332999,"All Other Miscellaneous Fabricated Metal Product Manufacturing",1.13188279835
3,333,"Machinery Manufacturing",378.025108434
4,3331,"Agriculture, Construction, and Mining Machinery Manufacturing",25.1496639096
5,33311,"Agricultural Implement Manufacturing",13.388002634
6,333111,"Farm Machinery and Equipment Manufacturing",11.8014623925
6,333112,"Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing",1.58654024148
5,33312,"Construction Machinery Manufacturing",11.7616612756
6,333120,"Construction Machinery Manufacturing",11.7616612756
5,33313,"Mining and Oil and Gas Field Machinery Manufacturing",0
6,333131,"Mining Machinery and Equipment Manufacturing",0
6,333132,"Oil and Gas Field Machinery and Equipment Manufacturing",0
4,3332,"Industrial Machinery Manufacturing",4.05702922039
5,33324,"Industrial Machinery Manufacturing",4.05702922039
6,333241,"Food Product Machinery Manufacturing",0
6,333242,"Semiconductor Machinery Manufacturing",0
6,333243,"Sawmill, Woodworking, and Paper Machinery Manufacturing",0.985242204657
6,333244,"Printing Machinery and Equipment Manufacturing",0
6,333249,"Other Industrial Machinery Manufacturing",3.07178701573
4,3333,"Commercial and Service Industry Machinery Manufacturing",0
5,33331,"Commercial and Service Industry Machinery Manufacturing",0
6,333314,"Optical Instrument and Lens Manufacturing",0
6,333316,"Photographic and Photocopying Equipment Manufacturing",0
6,333318,"Other Commercial and Service Industry Machinery Manufacturing",0
4,3334,"Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing",2.62396516654
5,33341,"Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing",2.62396516654
6,333413,"Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing",0.992259021674
6,333414,"Heating Equipment (except Warm Air Furnaces) Manufacturing",0
6,333415,"Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing",1.63170614486
4,3335,"Metalworking Machinery Manufacturing",256.515514858
5,33351,"Metalworking Machinery Manufacturing",256.515514858
6,333511,"Industrial Mold Manufacturing",51.4034460892
6,333514,"Special Die and Tool, Die Set, Jig, and Fixture Manufacturing",37.2762642024
6,333515,"Cutting Tool and Machine Tool Accessory Manufacturing",166.350355674
6,333517,"Machine Tool Manufacturing",1.48544889201
6,333519,"Rolling Mill and Other Metalworking Machinery Manufacturing",0
4,3336,"Engine, Turbine, and Power Transmission Equipment Manufacturing",1.62913306301
5,33361,"Engine, Turbine, and Power Transmission Equipment Manufacturing",1.62913306301
6,333611,"Turbine and Turbine Generator Set Units Manufacturing",0
6,333612,"Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing",0
6,333613,"Mechanical Power Transmission Equipment Manufacturing",0
6,333618,"Other Engine Equipment Manufacturing",1.62913306301
4,3339,"Other General Purpose Machinery Manufacturing",88.0498022164
5,33391,"Pump and Compressor Manufacturing",0
6,333912,"Air and Gas Compressor Manufacturing",0
6,333914,"Measuring, Dispensing, and Other Pumping Equipment Manufacturing",0
5,33392,"Material Handling Equipment Manufacturing",3.30264196644
6,333921,"Elevator and Moving Stairway Manufacturing",0
6,333922,"Conveyor and Conveying Equipment Manufacturing",2.01567743608
6,333923,"Overhead Traveling Crane, Hoist, and Monorail System Manufacturing",0
6,333924,"Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing",1.28696453036
5,33399,"All Other General Purpose Machinery Manufacturing",84.74716025
6,333991,"Power-Driven Handtool Manufacturing",0
6,333992,"Welding and Soldering Equipment Manufacturing",0
6,333993,"Packaging Machinery Manufacturing",84.74716025
6,333994,"Industrial Process Furnace and Oven Manufacturing",0
6,333995,"Fluid Power Cylinder and Actuator Manufacturing",0
6,333996,"Fluid Power Pump and Motor Manufacturing",0
6,333997,"Scale and Balance Manufacturing",0
6,333999,"All Other Miscellaneous General Purpose Machinery Manufacturing",0
3,334,"Computer and Electronic Product Manufacturing",0
4,3341,"Computer and Peripheral Equipment Manufacturing",0
5,33411,"Computer and Peripheral Equipment Manufacturing",0
6,334111,"Electronic Computer Manufacturing",0
6,334112,"Computer Storage Device Manufacturing",0
6,334118,"Computer Terminal and Other Computer Peripheral Equipment Manufacturing",0
4,3342,"Communications Equipment Manufacturing",0
5,33421,"Telephone Apparatus Manufacturing",0
6,334210,"Telephone Apparatus Manufacturing",0
5,33422,"Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing",0
6,334220,"Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing",0
5,33429,"Other Communications Equipment Manufacturing",0
6,334290,"Other Communications Equipment Manufacturing",0
4,3343,"Audio and Video Equipment Manufacturing",0
5,33431,"Audio and Video Equipment Manufacturing",0
6,334310,"Audio and Video Equipment Manufacturing",0
4,3344,"Semiconductor and Other Electronic Component Manufacturing",0
5,33441,"Semiconductor and Other Electronic Component Manufacturing",0
6,334412,"Bare Printed Circuit Board Manufacturing",0
6,334413,"Semiconductor and Related Device Manufacturing",0
6,334416,"Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing",0
6,334417,"Electronic Connector Manufacturing",0
6,334418,"Printed Circuit Assembly (Electronic Assembly) Manufacturing",0
6,334419,"Other Electronic Component Manufacturing",0
4,3345,"Navigational, Measuring, Electromedical, and Control Instruments Manufacturing",0
5,33451,"Navigational, Measuring, Electromedical, and Control Instruments Manufacturing",0
6,334510,"Electromedical and Electrotherapeutic Apparatus Manufacturing",0
6,334511,"Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing",0
6,334512,"Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use",0
6,334513,"Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables",0
6,334514,"Totalizing Fluid Meter and Counting Device Manufacturing",0
6,334515,"Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals",0
6,334516,"Analytical Laboratory Instrument Manufacturing",0
6,334517,"Irradiation Apparatus Manufacturing",0
6,334519,"Other Measuring and Controlling Device Manufacturing",0
4,3346,"Manufacturing and Reproducing Magnetic and Optical Media",0
5,33461,"Manufacturing and Reproducing Magnetic and Optical Media",0
6,334613,"Blank Magnetic and Optical Recording Media Manufacturing",0
6,334614,"Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing",0
3,335,"Electrical Equipment, Appliance, and Component Manufacturing",165.677880451
4,3351,"Electric Lighting Equipment Manufacturing",7.27046044555
5,33511,"Electric Lamp Bulb and Part Manufacturing",0
6,335110,"Electric Lamp Bulb and Part Manufacturing",0
5,33512,"Lighting Fixture Manufacturing",7.27046044555
6,335121,"Residential Electric Lighting Fixture Manufacturing",5.5826931371
6,335122,"Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing",0
6,335129,"Other Lighting Equipment Manufacturing",1.68776730846
4,3352,"Household Appliance Manufacturing",0
5,33521,"Small Electrical Appliance Manufacturing",0
6,335210,"Small Electrical Appliance Manufacturing",0
5,33522,"Major Household Appliance Manufacturing",0
6,335220,"Major Household Appliance Manufacturing",0
4,3353,"Electrical Equipment Manufacturing",3.56170677096
5,33531,"Electrical Equipment Manufacturing",3.56170677096
6,335311,"Power, Distribution, and Specialty Transformer Manufacturing",1.44597553035
6,335312,"Motor and Generator Manufacturing",2.11573124061
6,335313,"Switchgear and Switchboard Apparatus Manufacturing",0
6,335314,"Relay and Industrial Control Manufacturing",0
4,3359,"Other Electrical Equipment and Component Manufacturing",154.845713234
5,33591,"Battery Manufacturing",0
6,335911,"Storage Battery Manufacturing",0
6,335912,"Primary Battery Manufacturing",0
5,33592,"Communication and Energy Wire and Cable Manufacturing",0
6,335921,"Fiber Optic Cable Manufacturing",0
6,335929,"Other Communication and Energy Wire Manufacturing",0
5,33593,"Wiring Device Manufacturing",154.845713234
6,335931,"Current-Carrying Wiring Device Manufacturing",1.25701998431
6,335932,"Noncurrent-Carrying Wiring Device Manufacturing",153.58869325
5,33599,"All Other Electrical Equipment and Component Manufacturing",0
6,335991,"Carbon and Graphite Product Manufacturing",0
6,335999,"All Other Miscellaneous Electrical Equipment and Component Manufacturing",0
3,336,"Transportation Equipment Manufacturing",77.6012298701
4,3361,"Motor Vehicle Manufacturing",0
5,33611,"Automobile and Light Duty Motor Vehicle Manufacturing",0
6,336111,"Automobile Manufacturing",0
6,336112,"Light Truck and Utility Vehicle Manufacturing",0
5,33612,"Heavy Duty Truck Manufacturing",0
6,336120,"Heavy Duty Truck Manufacturing",0
4,3362,"Motor Vehicle Body and Trailer Manufacturing",0
5,33621,"Motor Vehicle Body and Trailer Manufacturing",0
6,336211,"Motor Vehicle Body Manufacturing",0
6,336212,"Truck Trailer Manufacturing",0
6,336213,"Motor Home Manufacturing",0
6,336214,"Travel Trailer and Camper Manufacturing",0
4,3363,"Motor Vehicle Parts Manufacturing",42.0084325
5,33631,"Motor Vehicle Gasoline Engine and Engine Parts Manufacturing",0
6,336310,"Motor Vehicle Gasoline Engine and Engine Parts Manufacturing",0
5,33632,"Motor Vehicle Electrical and Electronic Equipment Manufacturing",42.0084325
6,336320,"Motor Vehicle Electrical and Electronic Equipment Manufacturing",42.0084325
5,33633,"Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing",0
6,336330,"Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing",0
5,33634,"Motor Vehicle Brake System Manufacturing",0
6,336340,"Motor Vehicle Brake System Manufacturing",0
5,33635,"Motor Vehicle Transmission and Power Train Parts Manufacturing",0
6,336350,"Motor Vehicle Transmission and Power Train Parts Manufacturing",0
5,33636,"Motor Vehicle Seating and Interior Trim Manufacturing",0
6,336360,"Motor Vehicle Seating and Interior Trim Manufacturing",0
5,33637,"Motor Vehicle Metal Stamping",0
6,336370,"Motor Vehicle Metal Stamping",0
5,33639,"Other Motor Vehicle Parts Manufacturing",0
6,336390,"Other Motor Vehicle Parts Manufacturing",0
4,3364,"Aerospace Product and Parts Manufacturing",0
5,33641,"Aerospace Product and Parts Manufacturing",0
6,336411,"Aircraft Manufacturing",0
6,336412,"Aircraft Engine and Engine Parts Manufacturing",0
6,336413,"Other Aircraft Parts and Auxiliary Equipment Manufacturing",0
6,336414,"Guided Missile and Space Vehicle Manufacturing",0
6,336415,"Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing",0
6,336419,"Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing",0
4,3365,"Railroad Rolling Stock Manufacturing",0
5,33651,"Railroad Rolling Stock Manufacturing",0
6,336510,"Railroad Rolling Stock Manufacturing",0
4,3366,"Ship and Boat Building",0.809669618663
5,33661,"Ship and Boat Building",0.809669618663
6,336611,"Ship Building and Repairing",0.351666660703
6,336612,"Boat Building",0.45800295796
4,3369,"Other Transportation Equipment Manufacturing",34.7831277514
5,33699,"Other Transportation Equipment Manufacturing",34.7831277514
6,336991,"Motorcycle, Bicycle, and Parts Manufacturing",1.18667805966
6,336992,"Military Armored Vehicle, Tank, and Tank Component Manufacturing",0
6,336999,"All Other Transportation Equipment Manufacturing",33.5964496917
3,337,"Furniture and Related Product Manufacturing",272.802574258
4,3371,"Household and Institutional Furniture and Kitchen Cabinet Manufacturing",264.331959261
5,33711,"Wood Kitchen Cabinet and Countertop Manufacturing",106.551952042
6,337110,"Wood Kitchen Cabinet and Countertop Manufacturing",106.551952042
5,33712,"Household and Institutional Furniture Manufacturing",157.780007219
6,337121,"Upholstered Household Furniture Manufacturing",0
6,337122,"Nonupholstered Wood Household Furniture Manufacturing",0.277496605198
6,337124,"Metal Household Furniture Manufacturing",0.108026384525
6,337125,"Household Furniture (except Wood and Metal) Manufacturing",0
6,337127,"Institutional Furniture Manufacturing",157.394484229
4,3372,"Office Furniture (including Fixtures) Manufacturing",0.926095234557
5,33721,"Office Furniture (including Fixtures) Manufacturing",0.926095234557
6,337211,"Wood Office Furniture Manufacturing",0
6,337212,"Custom Architectural Woodwork and Millwork Manufacturing",0
6,337214,"Office Furniture (except Wood) Manufacturing",0
6,337215,"Showcase, Partition, Shelving, and Locker Manufacturing",0.926095234557
4,3379,"Other Furniture Related Product Manufacturing",7.54451976304
5,33791,"Mattress Manufacturing",3.86421455552
6,337910,"Mattress Manufacturing",3.86421455552
5,33792,"Blind and Shade Manufacturing",3.68030520752
6,337920,"Blind and Shade Manufacturing",3.68030520752
3,339,"Miscellaneous Manufacturing",109.376605465
4,3391,"Medical Equipment and Supplies Manufacturing",16.225296836
5,33911,"Medical Equipment and Supplies Manufacturing",16.225296836
6,339112,"Surgical and Medical Instrument Manufacturing",0
6,339113,"Surgical Appliance and Supplies Manufacturing",1.12701684932
6,339114,"Dental Equipment and Supplies Manufacturing",0
6,339115,"Ophthalmic Goods Manufacturing",0
6,339116,"Dental Laboratories",15.0982799867
4,3399,"Other Miscellaneous Manufacturing",93.1513086294
5,33991,"Jewelry and Silverware Manufacturing",2.06872170171
6,339910,"Jewelry and Silverware Manufacturing",2.06872170171
5,33992,"Sporting and Athletic Goods Manufacturing",11.0298849373
6,339920,"Sporting and Athletic Goods Manufacturing",11.0298849373
5,33993,"Doll, Toy, and Game Manufacturing",0
6,339930,"Doll, Toy, and Game Manufacturing",0
5,33994,"Office Supplies (except Paper) Manufacturing",5.31411503982
6,339940,"Office Supplies (except Paper) Manufacturing",5.31411503982
5,33995,"Sign Manufacturing",74.7385869505
6,339950,"Sign Manufacturing",74.7385869505
5,33999,"All Other Miscellaneous Manufacturing",0
6,339991,"Gasket, Packing, and Sealing Device Manufacturing",0
6,339992,"Musical Instrument Manufacturing",0
6,339993,"Fastener, Button, Needle, and Pin Manufacturing",0
6,339994,"Broom, Brush, and Mop Manufacturing",0
6,339995,"Burial Casket Manufacturing",0
6,339999,"All Other Miscellaneous Manufacturing",0
2,42,"Wholesale Trade",2437.24007801
3,423,"Merchant Wholesalers, Durable Goods",935.55655149
4,4231,"Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers",123.989616398
5,42311,"Automobile and Other Motor Vehicle Merchant Wholesalers",8.98955131393
6,423110,"Automobile and Other Motor Vehicle Merchant Wholesalers",8.98955131393
5,42312,"Motor Vehicle Supplies and New Parts Merchant Wholesalers",115.000065084
6,423120,"Motor Vehicle Supplies and New Parts Merchant Wholesalers",115.000065084
5,42313,"Tire and Tube Merchant Wholesalers",0
6,423130,"Tire and Tube Merchant Wholesalers",0
5,42314,"Motor Vehicle Parts (Used) Merchant Wholesalers",0
6,423140,"Motor Vehicle Parts (Used) Merchant Wholesalers",0
4,4232,"Furniture and Home Furnishing Merchant Wholesalers",68.2683511862
5,42321,"Furniture Merchant Wholesalers",59.2762918509
6,423210,"Furniture Merchant Wholesalers",59.2762918509
5,42322,"Home Furnishing Merchant Wholesalers",8.9920593353
6,423220,"Home Furnishing Merchant Wholesalers",8.9920593353
4,4233,"Lumber and Other Construction Materials Merchant Wholesalers",80.4110046979
5,42331,"Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers",0
6,423310,"Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers",0
5,42332,"Brick, Stone, and Related Construction Material Merchant Wholesalers",29.6214687864
6,423320,"Brick, Stone, and Related Construction Material Merchant Wholesalers",29.6214687864
5,42333,"Roofing, Siding, and Insulation Material Merchant Wholesalers",38.6302090697
6,423330,"Roofing, Siding, and Insulation Material Merchant Wholesalers",38.6302090697
5,42339,"Other Construction Material Merchant Wholesalers",12.1593268418
6,423390,"Other Construction Material Merchant Wholesalers",12.1593268418
4,4234,"Professional and Commercial Equipment and Supplies Merchant Wholesalers",74.4240309109
5,42341,"Photographic Equipment and Supplies Merchant Wholesalers",0
6,423410,"Photographic Equipment and Supplies Merchant Wholesalers",0
5,42342,"Office Equipment Merchant Wholesalers",17.3598236651
6,423420,"Office Equipment Merchant Wholesalers",17.3598236651
5,42343,"Computer and Computer Peripheral Equipment and Software Merchant Wholesalers",0
6,423430,"Computer and Computer Peripheral Equipment and Software Merchant Wholesalers",0
5,42344,"Other Commercial Equipment Merchant Wholesalers",0
6,423440,"Other Commercial Equipment Merchant Wholesalers",0
5,42345,"Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers",54.2157558829
6,423450,"Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers",54.2157558829
5,42346,"Ophthalmic Goods Merchant Wholesalers",1.26222468528
6,423460,"Ophthalmic Goods Merchant Wholesalers",1.26222468528
5,42349,"Other Professional Equipment and Supplies Merchant Wholesalers",1.58622667762
6,423490,"Other Professional Equipment and Supplies Merchant Wholesalers",1.58622667762
4,4235,"Metal and Mineral (except Petroleum) Merchant Wholesalers",6.5920739308
5,42351,"Metal Service Centers and Other Metal Merchant Wholesalers",6.5920739308
6,423510,"Metal Service Centers and Other Metal Merchant Wholesalers",6.5920739308
5,42352,"Coal and Other Mineral and Ore Merchant Wholesalers",0
6,423520,"Coal and Other Mineral and Ore Merchant Wholesalers",0
4,4236,"Household Appliances and Electrical and Electronic Goods Merchant Wholesalers",342.983002852
5,42361,"Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers",16.4592079966
6,423610,"Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers",16.4592079966
5,42362,"Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers",76.9241870001
6,423620,"Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers",76.9241870001
5,42369,"Other Electronic Parts and Equipment Merchant Wholesalers",249.599607855
6,423690,"Other Electronic Parts and Equipment Merchant Wholesalers",249.599607855
4,4237,"Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers",51.7372345508
5,42371,"Hardware Merchant Wholesalers",22.2456004514
6,423710,"Hardware Merchant Wholesalers",22.2456004514
5,42372,"Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers",2.85381992961
6,423720,"Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers",2.85381992961
5,42373,"Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers",26.6378141698
6,423730,"Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers",26.6378141698
5,42374,"Refrigeration Equipment and Supplies Merchant Wholesalers",0
6,423740,"Refrigeration Equipment and Supplies Merchant Wholesalers",0
4,4238,"Machinery, Equipment, and Supplies Merchant Wholesalers",126.257067753
5,42381,"Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers",8.70465054364
6,423810,"Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers",8.70465054364
5,42382,"Farm and Garden Machinery and Equipment Merchant Wholesalers",0
6,423820,"Farm and Garden Machinery and Equipment Merchant Wholesalers",0
5,42383,"Industrial Machinery and Equipment Merchant Wholesalers",45.694665695
6,423830,"Industrial Machinery and Equipment Merchant Wholesalers",45.694665695
5,42384,"Industrial Supplies Merchant Wholesalers",71.5505995219
6,423840,"Industrial Supplies Merchant Wholesalers",71.5505995219
5,42385,"Service Establishment Equipment and Supplies Merchant Wholesalers",0
6,423850,"Service Establishment Equipment and Supplies Merchant Wholesalers",0
5,42386,"Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers",0.307151992205
6,423860,"Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers",0.307151992205
4,4239,"Miscellaneous Durable Goods Merchant Wholesalers",60.8941692106
5,42391,"Sporting and Recreational Goods and Supplies Merchant Wholesalers",55.279975415
6,423910,"Sporting and Recreational Goods and Supplies Merchant Wholesalers",55.279975415
5,42392,"Toy and Hobby Goods and Supplies Merchant Wholesalers",0
6,423920,"Toy and Hobby Goods and Supplies Merchant Wholesalers",0
5,42393,"Recyclable Material Merchant Wholesalers",5.6141937955
6,423930,"Recyclable Material Merchant Wholesalers",5.6141937955
5,42394,"Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers",0
6,423940,"Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers",0
5,42399,"Other Miscellaneous Durable Goods Merchant Wholesalers",0
6,423990,"Other Miscellaneous Durable Goods Merchant Wholesalers",0
3,424,"Merchant Wholesalers, Nondurable Goods",696.736316723
4,4241,"Paper and Paper Product Merchant Wholesalers",35.7700943007
5,42411,"Printing and Writing Paper Merchant Wholesalers",0
6,424110,"Printing and Writing Paper Merchant Wholesalers",0
5,42412,"Stationery and Office Supplies Merchant Wholesalers",0
6,424120,"Stationery and Office Supplies Merchant Wholesalers",0
5,42413,"Industrial and Personal Service Paper Merchant Wholesalers",35.7700943007
6,424130,"Industrial and Personal Service Paper Merchant Wholesalers",35.7700943007
4,4242,"Drugs and Druggists' Sundries Merchant Wholesalers",84.5882861133
5,42421,"Drugs and Druggists' Sundries Merchant Wholesalers",84.5882861133
6,424210,"Drugs and Druggists' Sundries Merchant Wholesalers",84.5882861133
4,4243,"Apparel, Piece Goods, and Notions Merchant Wholesalers",13.4365148246
5,42431,"Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers",0.722716117725
6,424310,"Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers",0.722716117725
5,42432,"Men's and Boys' Clothing and Furnishings Merchant Wholesalers",12.7137987069
6,424320,"Men's and Boys' Clothing and Furnishings Merchant Wholesalers",12.7137987069
5,42433,"Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers",0
6,424330,"Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers",0
5,42434,"Footwear Merchant Wholesalers",0
6,424340,"Footwear Merchant Wholesalers",0
4,4244,"Grocery and Related Product Merchant Wholesalers",424.483220138
5,42441,"General Line Grocery Merchant Wholesalers",0
6,424410,"General Line Grocery Merchant Wholesalers",0
5,42442,"Packaged Frozen Food Merchant Wholesalers",35.6137579502
6,424420,"Packaged Frozen Food Merchant Wholesalers",35.6137579502
5,42443,"Dairy Product (except Dried or Canned) Merchant Wholesalers",0
6,424430,"Dairy Product (except Dried or Canned) Merchant Wholesalers",0
5,42444,"Poultry and Poultry Product Merchant Wholesalers",0
6,424440,"Poultry and Poultry Product Merchant Wholesalers",0
5,42445,"Confectionery Merchant Wholesalers",63.3387017689
6,424450,"Confectionery Merchant Wholesalers",63.3387017689
5,42446,"Fish and Seafood Merchant Wholesalers",1.53411265608
6,424460,"Fish and Seafood Merchant Wholesalers",1.53411265608
5,42447,"Meat and Meat Product Merchant Wholesalers",0
6,424470,"Meat and Meat Product Merchant Wholesalers",0
5,42448,"Fresh Fruit and Vegetable Merchant Wholesalers",0
6,424480,"Fresh Fruit and Vegetable Merchant Wholesalers",0
5,42449,"Other Grocery and Related Products Merchant Wholesalers",323.996647763
6,424490,"Other Grocery and Related Products Merchant Wholesalers",323.996647763
4,4245,"Farm Product Raw Material Merchant Wholesalers",29.9236407931
5,42451,"Grain and Field Bean Merchant Wholesalers",1.64649519159
6,424510,"Grain and Field Bean Merchant Wholesalers",1.64649519159
5,42452,"Livestock Merchant Wholesalers",27.4991703138
6,424520,"Livestock Merchant Wholesalers",27.4991703138
5,42459,"Other Farm Product Raw Material Merchant Wholesalers",0.777975287741
6,424590,"Other Farm Product Raw Material Merchant Wholesalers",0.777975287741
4,4246,"Chemical and Allied Products Merchant Wholesalers",27.3090661572
5,42461,"Plastics Materials and Basic Forms and Shapes Merchant Wholesalers",2.13690511915
6,424610,"Plastics Materials and Basic Forms and Shapes Merchant Wholesalers",2.13690511915
5,42469,"Other Chemical and Allied Products Merchant Wholesalers",25.1721610381
6,424690,"Other Chemical and Allied Products Merchant Wholesalers",25.1721610381
4,4247,"Petroleum and Petroleum Products Merchant Wholesalers",17.28991175
5,42471,"Petroleum Bulk Stations and Terminals",17.28991175
6,424710,"Petroleum Bulk Stations and Terminals",17.28991175
5,42472,"Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)",0
6,424720,"Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)",0
4,4248,"Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers",44.5984828161
5,42481,"Beer and Ale Merchant Wholesalers",44.5984828161
6,424810,"Beer and Ale Merchant Wholesalers",44.5984828161
5,42482,"Wine and Distilled Alcoholic Beverage Merchant Wholesalers",0
6,424820,"Wine and Distilled Alcoholic Beverage Merchant Wholesalers",0
4,4249,"Miscellaneous Nondurable Goods Merchant Wholesalers",19.3370998307
5,42491,"Farm Supplies Merchant Wholesalers",0
6,424910,"Farm Supplies Merchant Wholesalers",0
5,42492,"Book, Periodical, and Newspaper Merchant Wholesalers",0
6,424920,"Book, Periodical, and Newspaper Merchant Wholesalers",0
5,42493,"Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",9.86349525
6,424930,"Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",9.86349525
5,42494,"Tobacco and Tobacco Product Merchant Wholesalers",0
6,424940,"Tobacco and Tobacco Product Merchant Wholesalers",0
5,42495,"Paint, Varnish, and Supplies Merchant Wholesalers",0
6,424950,"Paint, Varnish, and Supplies Merchant Wholesalers",0
5,42499,"Other Miscellaneous Nondurable Goods Merchant Wholesalers",9.47360458072
6,424990,"Other Miscellaneous Nondurable Goods Merchant Wholesalers",9.47360458072
3,425,"Wholesale Electronic Markets and Agents and Brokers",804.947209792
4,4251,"Wholesale Electronic Markets and Agents and Brokers",804.947209792
5,42511,"Business to Business Electronic Markets",25.8953191089
6,425110,"Business to Business Electronic Markets",25.8953191089
5,42512,"Wholesale Trade Agents and Brokers",779.051890683
6,425120,"Wholesale Trade Agents and Brokers",779.051890683
2,44,"Retail Trade",7938.93922224
3,441,"Motor Vehicle and Parts Dealers",724.065024935
4,4411,"Automobile Dealers",406.915722765
5,44111,"New Car Dealers",207.772181298
6,441110,"New Car Dealers",207.772181298
5,44112,"Used Car Dealers",199.143541467
6,441120,"Used Car Dealers",199.143541467
4,4412,"Other Motor Vehicle Dealers",155.816103984
5,44121,"Recreational Vehicle Dealers",6.90622781034
6,441210,"Recreational Vehicle Dealers",6.90622781034
5,44122,"Motorcycle, Boat, and Other Motor Vehicle Dealers",148.909876174
6,441222,"Boat Dealers",35.6966680114
6,441228,"Motorcycle, ATV, and All Other Motor Vehicle Dealers",113.213208162
4,4413,"Automotive Parts, Accessories, and Tire Stores",161.333198185
5,44131,"Automotive Parts and Accessories Stores",118.634931216
6,441310,"Automotive Parts and Accessories Stores",118.634931216
5,44132,"Tire Dealers",42.6982669695
6,441320,"Tire Dealers",42.6982669695
3,442,"Furniture and Home Furnishings Stores",300.215408895
4,4421,"Furniture Stores",133.1510262
5,44211,"Furniture Stores",133.1510262
6,442110,"Furniture Stores",133.1510262
4,4422,"Home Furnishings Stores",167.064382695
5,44221,"Floor Covering Stores",0
6,442210,"Floor Covering Stores",0
5,44229,"Other Home Furnishings Stores",167.064382695
6,442291,"Window Treatment Stores",4.36164605045
6,442299,"All Other Home Furnishings Stores",162.702736644
3,443,"Electronics and Appliance Stores",455.181120629
4,4431,"Electronics and Appliance Stores",455.181120629
5,44314,"Electronics and Appliance Stores",455.181120629
6,443141,"Household Appliance Stores",104.886889693
6,443142,"Electronics Stores",350.294230936
3,444,"Building Material and Garden Equipment and Supplies Dealers",524.41481318
4,4441,"Building Material and Supplies Dealers",506.073226572
5,44411,"Home Centers",331.950248262
6,444110,"Home Centers",331.950248262
5,44412,"Paint and Wallpaper Stores",8.64158671427
6,444120,"Paint and Wallpaper Stores",8.64158671427
5,44413,"Hardware Stores",5.87842722714
6,444130,"Hardware Stores",5.87842722714
5,44419,"Other Building Material Dealers",159.602964368
6,444190,"Other Building Material Dealers",159.602964368
4,4442,"Lawn and Garden Equipment and Supplies Stores",18.3415866089
5,44421,"Outdoor Power Equipment Stores",0
6,444210,"Outdoor Power Equipment Stores",0
5,44422,"Nursery, Garden Center, and Farm Supply Stores",18.3415866089
6,444220,"Nursery, Garden Center, and Farm Supply Stores",18.3415866089
3,445,"Food and Beverage Stores",768.47479785
4,4451,"Grocery Stores",637.133995515
5,44511,"Supermarkets and Other Grocery (except Convenience) Stores",616.020895878
6,445110,"Supermarkets and Other Grocery (except Convenience) Stores",616.020895878
5,44512,"Convenience Stores",21.113099637
6,445120,"Convenience Stores",21.113099637
4,4452,"Specialty Food Stores",71.0705310694
5,44521,"Meat Markets",5.30470493866
6,445210,"Meat Markets",5.30470493866
5,44522,"Fish and Seafood Markets",5.05286158124
6,445220,"Fish and Seafood Markets",5.05286158124
5,44523,"Fruit and Vegetable Markets",8.68286067947
6,445230,"Fruit and Vegetable Markets",8.68286067947
5,44529,"Other Specialty Food Stores",52.03010387
6,445291,"Baked Goods Stores",5.00561672016
6,445292,"Confectionery and Nut Stores",16.131427629
6,445299,"All Other Specialty Food Stores",30.8930595209
4,4453,"Beer, Wine, and Liquor Stores",60.2702712656
5,44531,"Beer, Wine, and Liquor Stores",60.2702712656
6,445310,"Beer, Wine, and Liquor Stores",60.2702712656
3,446,"Health and Personal Care Stores",447.809535322
4,4461,"Health and Personal Care Stores",447.809535322
5,44611,"Pharmacies and Drug Stores",126.843829172
6,446110,"Pharmacies and Drug Stores",126.843829172
5,44612,"Cosmetics, Beauty Supplies, and Perfume Stores",210.433554897
6,446120,"Cosmetics, Beauty Supplies, and Perfume Stores",210.433554897
5,44613,"Optical Goods Stores",19.8520835104
6,446130,"Optical Goods Stores",19.8520835104
5,44619,"Other Health and Personal Care Stores",90.6800677421
6,446191,"Food (Health) Supplement Stores",50.2099177001
6,446199,"All Other Health and Personal Care Stores",40.470150042
3,447,"Gasoline Stations",239.538547625
4,4471,"Gasoline Stations",239.538547625
5,44711,"Gasoline Stations with Convenience Stores",239.538547625
6,447110,"Gasoline Stations with Convenience Stores",239.538547625
5,44719,"Other Gasoline Stations",0
6,447190,"Other Gasoline Stations",0
3,448,"Clothing and Clothing Accessories Stores",773.023093532
4,4481,"Clothing Stores",623.315335713
5,44811,"Men's Clothing Stores",27.4883927375
6,448110,"Men's Clothing Stores",27.4883927375
5,44812,"Women's Clothing Stores",197.9257367
6,448120,"Women's Clothing Stores",197.9257367
5,44813,"Children's and Infants' Clothing Stores",55.9813760592
6,448130,"Children's and Infants' Clothing Stores",55.9813760592
5,44814,"Family Clothing Stores",236.961850023
6,448140,"Family Clothing Stores",236.961850023
5,44815,"Clothing Accessories Stores",29.3717677181
6,448150,"Clothing Accessories Stores",29.3717677181
5,44819,"Other Clothing Stores",75.5862124751
6,448190,"Other Clothing Stores",75.5862124751
4,4482,"Shoe Stores",86.0319339253
5,44821,"Shoe Stores",86.0319339253
6,448210,"Shoe Stores",86.0319339253
4,4483,"Jewelry, Luggage, and Leather Goods Stores",63.6758238933
5,44831,"Jewelry Stores",63.6758238933
6,448310,"Jewelry Stores",63.6758238933
5,44832,"Luggage and Leather Goods Stores",0
6,448320,"Luggage and Leather Goods Stores",0
3,451,"Sporting Goods, Hobby, Musical Instrument, and Book Stores",540.043804198
4,4511,"Sporting Goods, Hobby, and Musical Instrument Stores",487.067111684
5,45111,"Sporting Goods Stores",289.94985171
6,451110,"Sporting Goods Stores",289.94985171
5,45112,"Hobby, Toy, and Game Stores",121.267239034
6,451120,"Hobby, Toy, and Game Stores",121.267239034
5,45113,"Sewing, Needlework, and Piece Goods Stores",71.8832319662
6,451130,"Sewing, Needlework, and Piece Goods Stores",71.8832319662
5,45114,"Musical Instrument and Supplies Stores",3.9667889735
6,451140,"Musical Instrument and Supplies Stores",3.9667889735
4,4512,"Book Stores and News Dealers",52.9766925142
5,45121,"Book Stores and News Dealers",52.9766925142
6,451211,"Book Stores",41.1638050529
6,451212,"News Dealers and Newsstands",11.8128874613
3,452,"General Merchandise Stores",1904.12344844
4,4522,"Department Stores",679.554449073
5,45221,"Department Stores",679.554449073
6,452210,"Department Stores",679.554449073
4,4523,"General Merchandise Stores, including Warehouse Clubs and Supercenters",1224.56899937
5,45231,"General Merchandise Stores, including Warehouse Clubs and Supercenters",1224.56899937
6,452311,"Warehouse Clubs and Supercenters",1166.59029868
6,452319,"All Other General Merchandise Stores",57.9787006839
3,453,"Miscellaneous Store Retailers",882.219096682
4,4531,"Florists",41.204350113
5,45311,"Florists",41.204350113
6,453110,"Florists",41.204350113
4,4532,"Office Supplies, Stationery, and Gift Stores",193.972825918
5,45321,"Office Supplies and Stationery Stores",38.3484824175
6,453210,"Office Supplies and Stationery Stores",38.3484824175
5,45322,"Gift, Novelty, and Souvenir Stores",155.624343501
6,453220,"Gift, Novelty, and Souvenir Stores",155.624343501
4,4533,"Used Merchandise Stores",285.191572339
5,45331,"Used Merchandise Stores",285.191572339
6,453310,"Used Merchandise Stores",285.191572339
4,4539,"Other Miscellaneous Store Retailers",361.850348312
5,45391,"Pet and Pet Supplies Stores",109.23615603
6,453910,"Pet and Pet Supplies Stores",109.23615603
5,45392,"Art Dealers",0
6,453920,"Art Dealers",0
5,45393,"Manufactured (Mobile) Home Dealers",0
6,453930,"Manufactured (Mobile) Home Dealers",0
5,45399,"All Other Miscellaneous Store Retailers",252.614192282
6,453991,"Tobacco Stores",167.861490623
6,453998,"All Other Miscellaneous Store Retailers (except Tobacco Stores)",84.7527016586
3,454,"Nonstore Retailers",379.830530952
4,4541,"Electronic Shopping and Mail-Order Houses",226.72529278
5,45411,"Electronic Shopping and Mail-Order Houses",226.72529278
6,454110,"Electronic Shopping and Mail-Order Houses",226.72529278
4,4542,"Vending Machine Operators",30.8136249192
5,45421,"Vending Machine Operators",30.8136249192
6,454210,"Vending Machine Operators",30.8136249192
4,4543,"Direct Selling Establishments",122.291613253
5,45431,"Fuel Dealers",0
6,454310,"Fuel Dealers",0
5,45439,"Other Direct Selling Establishments",122.291613253
6,454390,"Other Direct Selling Establishments",122.291613253
2,48,"Transportation and Warehousing",1666.42734958
3,481,"Air Transportation",30.8385254172
4,4811,"Scheduled Air Transportation",0
5,48111,"Scheduled Air Transportation",0
6,481111,"Scheduled Passenger Air Transportation",0
6,481112,"Scheduled Freight Air Transportation",0
4,4812,"Nonscheduled Air Transportation",30.8385254172
5,48121,"Nonscheduled Air Transportation",30.8385254172
6,481211,"Nonscheduled Chartered Passenger Air Transportation",23.7612147347
6,481212,"Nonscheduled Chartered Freight Air Transportation",0
6,481219,"Other Nonscheduled Air Transportation",7.07731068249
3,482,"Rail Transportation",8.9540922319
4,4821,"Rail Transportation",8.9540922319
5,48211,"Rail Transportation",8.9540922319
6,482110,"Rail transportation",8.9540922319
3,483,"Water Transportation",0
4,4831,"Deep Sea, Coastal, and Great Lakes Water Transportation",0
5,48311,"Deep Sea, Coastal, and Great Lakes Water Transportation",0
6,483111,"Deep Sea Freight Transportation",0
6,483112,"Deep Sea Passenger Transportation",0
6,483113,"Coastal and Great Lakes Freight Transportation",0
6,483114,"Coastal and Great Lakes Passenger Transportation",0
4,4832,"Inland Water Transportation",0
5,48321,"Inland Water Transportation",0
6,483211,"Inland Water Freight Transportation",0
6,483212,"Inland Water Passenger Transportation",0
3,484,"Truck Transportation",299.143466572
4,4841,"General Freight Trucking",156.685443717
5,48411,"General Freight Trucking, Local",26.6835060789
6,484110,"General Freight Trucking, Local",26.6835060789
5,48412,"General Freight Trucking, Long-Distance",130.001937638
6,484121,"General Freight Trucking, Long-Distance, Truckload",123.219839662
6,484122,"General Freight Trucking, Long-Distance, Less Than Truckload",6.7820979757
4,4842,"Specialized Freight Trucking",142.458022855
5,48421,"Used Household and Office Goods Moving",7.1543931479
6,484210,"Used Household and Office Goods Moving",7.1543931479
5,48422,"Specialized Freight (except Used Goods) Trucking, Local",132.295749301
6,484220,"Specialized Freight (except Used Goods) Trucking, Local",132.295749301
5,48423,"Specialized Freight (except Used Goods) Trucking, Long-Distance",3.00788040598
6,484230,"Specialized Freight (except Used Goods) Trucking, Long-Distance",3.00788040598
3,485,"Transit and Ground Passenger Transportation",568.625457878
4,4851,"Urban Transit Systems",0
5,48511,"Urban Transit Systems",0
6,485111,"Mixed Mode Transit Systems",0
6,485112,"Commuter Rail Systems",0
6,485113,"Bus and Other Motor Vehicle Transit Systems",0
6,485119,"Other Urban Transit Systems",0
4,4852,"Interurban and Rural Bus Transportation",1.00078024167
5,48521,"Interurban and Rural Bus Transportation",1.00078024167
6,485210,"Interurban and Rural Bus Transportation",1.00078024167
4,4853,"Taxi and Limousine Service",558.130308219
5,48531,"Taxi Service",227.165649932
6,485310,"Taxi Service",227.165649932
5,48532,"Limousine Service",330.964658287
6,485320,"Limousine Service",330.964658287
4,4854,"School and Employee Bus Transportation",5.24020938974
5,48541,"School and Employee Bus Transportation",5.24020938974
6,485410,"School and Employee Bus Transportation",5.24020938974
4,4855,"Charter Bus Industry",0
5,48551,"Charter Bus Industry",0
6,485510,"Charter Bus Industry",0
4,4859,"Other Transit and Ground Passenger Transportation",4.2541600276
5,48599,"Other Transit and Ground Passenger Transportation",4.2541600276
6,485991,"Special Needs Transportation",0
6,485999,"All Other Transit and Ground Passenger Transportation",4.2541600276
3,486,"Pipeline Transportation",0
4,4861,"Pipeline Transportation of Crude Oil",0
5,48611,"Pipeline Transportation of Crude Oil",0
6,486110,"Pipeline Transportation of Crude Oil",0
4,4862,"Pipeline Transportation of Natural Gas",0
5,48621,"Pipeline Transportation of Natural Gas",0
6,486210,"Pipeline Transportation of Natural Gas",0
4,4869,"Other Pipeline Transportation",0
5,48691,"Pipeline Transportation of Refined Petroleum Products",0
6,486910,"Pipeline Transportation of Refined Petroleum Products",0
5,48699,"All Other Pipeline Transportation",0
6,486990,"All Other Pipeline Transportation",0
3,487,"Scenic and Sightseeing Transportation",0
4,4871,"Scenic and Sightseeing Transportation, Land",0
5,48711,"Scenic and Sightseeing Transportation, Land",0
6,487110,"Scenic and Sightseeing Transportation, Land",0
4,4872,"Scenic and Sightseeing Transportation, Water",0
5,48721,"Scenic and Sightseeing Transportation, Water",0
6,487210,"Scenic and Sightseeing Transportation, Water",0
4,4879,"Scenic and Sightseeing Transportation, Other",0
5,48799,"Scenic and Sightseeing Transportation, Other",0
6,487990,"Scenic and Sightseeing Transportation, Other",0
3,488,"Support Activities for Transportation",111.804134554
4,4881,"Support Activities for Air Transportation",0
5,48811,"Airport Operations",0
6,488111,"Air Traffic Control",0
6,488119,"Other Airport Operations",0
5,48819,"Other Support Activities for Air Transportation",0
6,488190,"Other Support Activities for Air Transportation",0
4,4882,"Support Activities for Rail Transportation",5.76430948918
5,48821,"Support Activities for Rail Transportation",5.76430948918
6,488210,"Support Activities for Rail Transportation",5.76430948918
4,4883,"Support Activities for Water Transportation",0
5,48831,"Port and Harbor Operations",0
6,488310,"Port and Harbor Operations",0
5,48832,"Marine Cargo Handling",0
6,488320,"Marine Cargo Handling",0
5,48833,"Navigational Services to Shipping",0
6,488330,"Navigational Services to Shipping",0
5,48839,"Other Support Activities for Water Transportation",0
6,488390,"Other Support Activities for Water Transportation",0
4,4884,"Support Activities for Road Transportation",36.6042805955
5,48841,"Motor Vehicle Towing",36.6042805955
6,488410,"Motor Vehicle Towing",36.6042805955
5,48849,"Other Support Activities for Road Transportation",0
6,488490,"Other Support Activities for Road Transportation",0
4,4885,"Freight Transportation Arrangement",69.4355444691
5,48851,"Freight Transportation Arrangement",69.4355444691
6,488510,"Freight Transportation Arrangement",69.4355444691
4,4889,"Other Support Activities for Transportation",0
5,48899,"Other Support Activities for Transportation",0
6,488991,"Packing and Crating",0
6,488999,"All Other Support Activities for Transportation",0
3,491,"Postal Service",0
4,4911,"Postal Service",0
5,49111,"Postal Service",0
6,491110,"Postal Service",0
3,492,"Couriers and Messengers",0
4,4921,"Couriers and Express Delivery Services",0
5,49211,"Couriers and Express Delivery Services",0
6,492110,"Couriers and Express Delivery Services",0
4,4922,"Local Messengers and Local Delivery",0
5,49221,"Local Messengers and Local Delivery",0
6,492210,"Local Messengers and Local Delivery",0
3,493,"Warehousing and Storage",647.061672932
4,4931,"Warehousing and Storage",647.061672932
5,49311,"General Warehousing and Storage",330.581107314
6,493110,"General Warehousing and Storage",330.581107314
5,49312,"Refrigerated Warehousing and Storage",293.290688431
6,493120,"Refrigerated Warehousing and Storage",293.290688431
5,49313,"Farm Product Warehousing and Storage",14.7459443828
6,493130,"Farm Product Warehousing and Storage",14.7459443828
5,49319,"Other Warehousing and Storage",8.44393280347
6,493190,"Other Warehousing and Storage",8.44393280347
2,51,"Information",460.637371639
3,511,"Publishing Industries (except Internet)",89.87064199
4,5111,"Newspaper, Periodical, Book, and Directory Publishers",43.073561242
5,51111,"Newspaper Publishers",37.1854175006
6,511110,"Newspaper Publishers",37.1854175006
5,51112,"Periodical Publishers",5.01558002717
6,511120,"Periodical Publishers",5.01558002717
5,51113,"Book Publishers",0.872563714205
6,511130,"Book Publishers",0.872563714205
5,51114,"Directory and Mailing List Publishers",0
6,511140,"Directory and Mailing List Publishers",0
5,51119,"Other Publishers",0
6,511191,"Greeting Card Publishers",0
6,511199,"All Other Publishers",0
4,5112,"Software Publishers",46.797080748
5,51121,"Software Publishers",46.797080748
6,511210,"Software Publishers",46.797080748
3,512,"Motion Picture and Sound Recording Industries",127.445530752
4,5121,"Motion Picture and Video Industries",117.754319766
5,51211,"Motion Picture and Video Production",7.49912732923
6,512110,"Motion Picture and Video Production",7.49912732923
5,51212,"Motion Picture and Video Distribution",4.89096649573
6,512120,"Motion Picture and Video Distribution",4.89096649573
5,51213,"Motion Picture and Video Exhibition",105.364225941
6,512131,"Motion Picture Theaters (except Drive-Ins)",105.364225941
6,512132,"Drive-In Motion Picture Theaters",0
5,51219,"Postproduction Services and Other Motion Picture and Video Industries",0
6,512191,"Teleproduction and Other Postproduction Services",0
6,512199,"Other Motion Picture and Video Industries",0
4,5122,"Sound Recording Industries",9.69121098641
5,51223,"Music Publishers",4.65337565914
6,512230,"Music Publishers",4.65337565914
5,51224,"Sound Recording Studios",1.81751297425
6,512240,"Sound Recording Studios",1.81751297425
5,51225,"Record Production and Distribution",3.22032235302
6,512250,"Record Production and Distribution",3.22032235302
5,51229,"Other Sound Recording Industries",0
6,512290,"Other Sound Recording Industries",0
3,515,"Broadcasting (except Internet)",98.8138913005
4,5151,"Radio and Television Broadcasting",98.8138913005
5,51511,"Radio Broadcasting",28.5543584363
6,515111,"Radio Networks",2.64825450689
6,515112,"Radio Stations",25.9061039294
5,51512,"Television Broadcasting",70.2595328642
6,515120,"Television Broadcasting",70.2595328642
4,5152,"Cable and Other Subscription Programming",0
5,51521,"Cable and Other Subscription Programming",0
6,515210,"Cable and Other Subscription Programming",0
3,517,"Telecommunications",126.456981653
4,5173,"Wired and Wireless Telecommunications Carriers",119.163800393
5,51731,"Wired and Wireless Telecommunications Carriers",119.163800393
6,517311,"Wired Telecommunications Carriers",59.9601880214
6,517312,"Wireless Telecommunications Carriers (except Satellite)",59.2036123717
4,5174,"Satellite Telecommunications",5.89075511888
5,51741,"Satellite Telecommunications",5.89075511888
6,517410,"Satellite Telecommunications",5.89075511888
4,5179,"Other Telecommunications",1.40242614107
5,51791,"Other Telecommunications",1.40242614107
6,517911,"Telecommunications Resellers",0
6,517919,"All Other Telecommunications",1.40242614107
3,518,"Data Processing, Hosting, and Related Services",18.0503259429
4,5182,"Data Processing, Hosting, and Related Services",18.0503259429
5,51821,"Data Processing, Hosting, and Related Services",18.0503259429
6,518210,"Data Processing, Hosting, and Related Services",18.0503259429
3,519,"Other Information Services",0
4,5191,"Other Information Services",0
5,51911,"News Syndicates",0
6,519110,"News Syndicates",0
5,51912,"Libraries and Archives",0
6,519120,"Libraries and Archives",0
5,51913,"Internet Publishing and Broadcasting and Web Search Portals",0
6,519130,"Internet Publishing and Broadcasting and Web Search Portals",0
5,51919,"All Other Information Services",0
6,519190,"All Other Information Services",0
2,52,"Finance and Insurance",2127.50281306
3,521,"Monetary Authorities-Central Bank",0
4,5211,"Monetary Authorities-Central Bank",0
5,52111,"Monetary Authorities-Central Bank",0
6,521110,"Monetary Authorities-Central Bank",0
3,522,"Credit Intermediation and Related Activities",649.990440298
4,5221,"Depository Credit Intermediation",535.910042735
5,52211,"Commercial Banking",520.406378609
6,522110,"Commercial Banking",520.406378609
5,52212,"Savings Institutions",6.00719198152
6,522120,"Savings Institutions",6.00719198152
5,52213,"Credit Unions",9.49647214374
6,522130,"Credit Unions",9.49647214374
5,52219,"Other Depository Credit Intermediation",0
6,522190,"Other Depository Credit Intermediation",0
4,5222,"Nondepository Credit Intermediation",102.942693142
5,52221,"Credit Card Issuing",0
6,522210,"Credit Card Issuing",0
5,52222,"Sales Financing",9.71497496145
6,522220,"Sales Financing",9.71497496145
5,52229,"Other Nondepository Credit Intermediation",93.2277181805
6,522291,"Consumer Lending",0
6,522292,"Real Estate Credit",43.5345788275
6,522293,"International Trade Financing",0
6,522294,"Secondary Market Financing",12.8162771204
6,522298,"All Other Nondepository Credit Intermediation",36.8768622326
4,5223,"Activities Related to Credit Intermediation",11.137704421
5,52231,"Mortgage and Nonmortgage Loan Brokers",0
6,522310,"Mortgage and Nonmortgage Loan Brokers",0
5,52232,"Financial Transactions Processing, Reserve, and Clearinghouse Activities",0
6,522320,"Financial Transactions Processing, Reserve, and Clearinghouse Activities",0
5,52239,"Other Activities Related to Credit Intermediation",11.137704421
6,522390,"Other Activities Related to Credit Intermediation",11.137704421
3,523,"Securities, Commodity Contracts, and Other Financial Investments and Related Activities",839.719636429
4,5231,"Securities and Commodity Contracts Intermediation and Brokerage",146.401245213
5,52311,"Investment Banking and Securities Dealing",0
6,523110,"Investment Banking and Securities Dealing",0
5,52312,"Securities Brokerage",118.077604486
6,523120,"Securities Brokerage",118.077604486
5,52313,"Commodity Contracts Dealing",18.4366937713
6,523130,"Commodity Contracts Dealing",18.4366937713
5,52314,"Commodity Contracts Brokerage",9.88694695551
6,523140,"Commodity Contracts Brokerage",9.88694695551
4,5232,"Securities and Commodity Exchanges",0
5,52321,"Securities and Commodity Exchanges",0
6,523210,"Securities and Commodity Exchanges",0
4,5239,"Other Financial Investment Activities",693.318391216
5,52391,"Miscellaneous Intermediation",155.484334505
6,523910,"Miscellaneous Intermediation",155.484334505
5,52392,"Portfolio Management",229.091357036
6,523920,"Portfolio Management",229.091357036
5,52393,"Investment Advice",288.36716513
6,523930,"Investment Advice",288.36716513
5,52399,"All Other Financial Investment Activities",20.3755345445
6,523991,"Trust, Fiduciary, and Custody Activities",14.1385753588
6,523999,"Miscellaneous Financial Investment Activities",6.23695918564
3,524,"Insurance Carriers and Related Activities",630.711245602
4,5241,"Insurance Carriers",11.2061092449
5,52411,"Direct Life, Health, and Medical Insurance Carriers",2.47388203512
6,524113,"Direct Life Insurance Carriers",0
6,524114,"Direct Health and Medical Insurance Carriers",2.47388203512
5,52412,"Direct Insurance (except Life, Health, and Medical) Carriers",6.69979191674
6,524126,"Direct Property and Casualty Insurance Carriers",6.69979191674
6,524127,"Direct Title Insurance Carriers",0
6,524128,"Other Direct Insurance (except Life, Health, and Medical) Carriers",0
5,52413,"Reinsurance Carriers",2.03243529305
6,524130,"Reinsurance Carriers",2.03243529305
4,5242,"Agencies, Brokerages, and Other Insurance Related Activities",619.505136357
5,52421,"Insurance Agencies and Brokerages",395.454679125
6,524210,"Insurance Agencies and Brokerages",395.454679125
5,52429,"Other Insurance Related Activities",224.050457232
6,524291,"Claims Adjusting",12.0302444485
6,524292,"Third Party Administration of Insurance and Pension Funds",26.2175619397
6,524298,"All Other Insurance Related Activities",185.802650843
3,525,"Funds, Trusts, and Other Financial Vehicles",7.08149073247
4,5251,"Insurance and Employee Benefit Funds",7.08149073247
5,52511,"Pension Funds",0
6,525110,"Pension Funds",0
5,52512,"Health and Welfare Funds",7.08149073247
6,525120,"Health and Welfare Funds",7.08149073247
5,52519,"Other Insurance Funds",0
6,525190,"Other Insurance Funds",0
4,5259,"Other Investment Pools and Funds",0
5,52591,"Open-End Investment Funds",0
6,525910,"Open-End Investment Funds",0
5,52592,"Trusts, Estates, and Agency Accounts",0
6,525920,"Trusts, Estates, and Agency Accounts",0
5,52599,"Other Financial Vehicles",0
6,525990,"Other Financial Vehicles",0
2,53,"Real Estate and Rental and Leasing",2011.61121674
3,531,"Real Estate",1778.35651477
4,5311,"Lessors of Real Estate",598.266015297
5,53111,"Lessors of Residential Buildings and Dwellings",268.127730091
6,531110,"Lessors of Residential Buildings and Dwellings",268.127730091
5,53112,"Lessors of Nonresidential Buildings (except Miniwarehouses)",152.045055124
6,531120,"Lessors of Nonresidential Buildings (except Miniwarehouses)",152.045055124
5,53113,"Lessors of Miniwarehouses and Self-Storage Units",114.973539242
6,531130,"Lessors of Miniwarehouses and Self-Storage Units",114.973539242
5,53119,"Lessors of Other Real Estate Property",63.1196908407
6,531190,"Lessors of Other Real Estate Property",63.1196908407
4,5312,"Offices of Real Estate Agents and Brokers",838.36301492
5,53121,"Offices of Real Estate Agents and Brokers",838.36301492
6,531210,"Offices of Real Estate Agents and Brokers",838.36301492
4,5313,"Activities Related to Real Estate",341.727484553
5,53131,"Real Estate Property Managers",181.603141034
6,531311,"Residential Property Managers",9.49567445168
6,531312,"Nonresidential Property Managers",172.107466582
5,53132,"Offices of Real Estate Appraisers",33.9322322077
6,531320,"Offices of Real Estate Appraisers",33.9322322077
5,53139,"Other Activities Related to Real Estate",126.192111311
6,531390,"Other Activities Related to Real Estate",126.192111311
3,532,"Rental and Leasing Services",227.743810093
4,5321,"Automotive Equipment Rental and Leasing",26.7335829397
5,53211,"Passenger Car Rental and Leasing",26.7335829397
6,532111,"Passenger Car Rental",26.5637368949
6,532112,"Passenger Car Leasing",0.169846044753
5,53212,"Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing",0
6,532120,"Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing",0
4,5322,"Consumer Goods Rental",33.8750310741
5,53221,"Consumer Electronics and Appliances Rental",5.71552142406
6,532210,"Consumer Electronics and Appliances Rental",5.71552142406
5,53228,"Other Consumer Goods Rental",28.15950965
6,532281,"Formal Wear and Costume Rental",1.18562920002
6,532282,"Video Tape and Disc Rental",0.535252307971
6,532283,"Home Health Equipment Rental",0
6,532284,"Recreational Goods Rental",1.48127893336
6,532289,"All Other Consumer Goods Rental",24.9573492086
4,5323,"General Rental Centers",7.72528358641
5,53231,"General Rental Centers",7.72528358641
6,532310,"General Rental Centers",7.72528358641
4,5324,"Commercial and Industrial Machinery and Equipment Rental and Leasing",159.409912493
5,53241,"Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing",95.3858222298
6,532411,"Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing",0
6,532412,"Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing",95.3858222298
5,53242,"Office Machinery and Equipment Rental and Leasing",17.832628436
6,532420,"Office Machinery and Equipment Rental and Leasing",17.832628436
5,53249,"Other Commercial and Industrial Machinery and Equipment Rental and Leasing",46.1914618275
6,532490,"Other Commercial and Industrial Machinery and Equipment Rental and Leasing",46.1914618275
3,533,"Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",5.51089187447
4,5331,"Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",5.51089187447
5,53311,"Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",5.51089187447
6,533110,"Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",5.51089187447
2,54,"Professional, Scientific, and Technical Services",3278.75898681
3,541,"Professional, Scientific, and Technical Services",3278.75898681
4,5411,"Legal Services",201.976931666
5,54111,"Offices of Lawyers",174.752490169
6,541110,"Offices of Lawyers",174.752490169
5,54119,"Other Legal Services",27.2244414975
6,541191,"Title Abstract and Settlement Offices",22.5597126337
6,541199,"All Other Legal Services",4.66472886385
4,5412,"Accounting, Tax Preparation, Bookkeeping, and Payroll Services",265.224724032
5,54121,"Accounting, Tax Preparation, Bookkeeping, and Payroll Services",265.224724032
6,541211,"Offices of Certified Public Accountants",32.1571882322
6,541213,"Tax Preparation Services",21.4492959965
6,541214,"Payroll Services",32.4475480192
6,541219,"Other Accounting Services",179.170691784
4,5413,"Architectural, Engineering, and Related Services",762.484549943
5,54131,"Architectural Services",172.150771156
6,541310,"Architectural Services",172.150771156
5,54132,"Landscape Architectural Services",32.8524908221
6,541320,"Landscape Architectural Services",32.8524908221
5,54133,"Engineering Services",373.715671421
6,541330,"Engineering Services",373.715671421
5,54134,"Drafting Services",0
6,541340,"Drafting Services",0
5,54135,"Building Inspection Services",13.8686265644
6,541350,"Building Inspection Services",13.8686265644
5,54136,"Geophysical Surveying and Mapping Services",0
6,541360,"Geophysical Surveying and Mapping Services",0
5,54137,"Surveying and Mapping (except Geophysical) Services",12.3983616439
6,541370,"Surveying and Mapping (except Geophysical) Services",12.3983616439
5,54138,"Testing Laboratories",157.498628336
6,541380,"Testing Laboratories",157.498628336
4,5414,"Specialized Design Services",187.377941168
5,54141,"Interior Design Services",81.3275386744
6,541410,"Interior Design Services",81.3275386744
5,54142,"Industrial Design Services",16.7237148159
6,541420,"Industrial Design Services",16.7237148159
5,54143,"Graphic Design Services",64.1149051954
6,541430,"Graphic Design Services",64.1149051954
5,54149,"Other Specialized Design Services",25.2117824823
6,541490,"Other Specialized Design Services",25.2117824823
4,5415,"Computer Systems Design and Related Services",262.185883831
5,54151,"Computer Systems Design and Related Services",262.185883831
6,541511,"Custom Computer Programming Services",19.6681788504
6,541512,"Computer Systems Design Services",33.5188151866
6,541513,"Computer Facilities Management Services",0.331863782364
6,541519,"Other Computer Related Services",208.667026012
4,5416,"Management, Scientific, and Technical Consulting Services",590.947668087
5,54161,"Management Consulting Services",524.690914849
6,541611,"Administrative Management and General Management Consulting Services",172.435620216
6,541612,"Human Resources Consulting Services",57.7011176371
6,541613,"Marketing Consulting Services",238.275095363
6,541614,"Process, Physical Distribution, and Logistics Consulting Services",34.2722579857
6,541618,"Other Management Consulting Services",22.0068236485
5,54162,"Environmental Consulting Services",22.9280740228
6,541620,"Environmental Consulting Services",22.9280740228
5,54169,"Other Scientific and Technical Consulting Services",43.3286792146
6,541690,"Other Scientific and Technical Consulting Services",43.3286792146
4,5417,"Scientific Research and Development Services",0
5,54171,"Research and Development in the Physical, Engineering, and Life Sciences",0
6,541713,"Research and Development in Nanotechnology",0
6,541714,"Research and Development in Biotechnology (except Nanobiotechnology)",0
6,541715,"Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)",0
5,54172,"Research and Development in the Social Sciences and Humanities",0
6,541720,"Research and Development in the Social Sciences and Humanities",0
4,5418,"Advertising, Public Relations, and Related Services",797.022974936
5,54181,"Advertising Agencies",248.574152536
6,541810,"Advertising Agencies",248.574152536
5,54182,"Public Relations Agencies",0
6,541820,"Public Relations Agencies",0
5,54183,"Media Buying Agencies",2.85192732707
6,541830,"Media Buying Agencies",2.85192732707
5,54184,"Media Representatives",8.04301586099
6,541840,"Media Representatives",8.04301586099
5,54185,"Outdoor Advertising",15.7833502002
6,541850,"Outdoor Advertising",15.7833502002
5,54186,"Direct Mail Advertising",0
6,541860,"Direct Mail Advertising",0
5,54187,"Advertising Material Distribution Services",0
6,541870,"Advertising Material Distribution Services",0
5,54189,"Other Services Related to Advertising",521.770529012
6,541890,"Other Services Related to Advertising",521.770529012
4,5419,"Other Professional, Scientific, and Technical Services",211.538313151
5,54191,"Marketing Research and Public Opinion Polling",0
6,541910,"Marketing Research and Public Opinion Polling",0
5,54192,"Photographic Services",113.182761085
6,541921,"Photography Studios, Portrait",100.907508043
6,541922,"Commercial Photography",12.2752530416
5,54193,"Translation and Interpretation Services",0
6,541930,"Translation and Interpretation Services",0
5,54194,"Veterinary Services",85.0548599912
6,541940,"Veterinary Services",85.0548599912
5,54199,"All Other Professional, Scientific, and Technical Services",13.3006920749
6,541990,"All Other Professional, Scientific, and Technical Services",13.3006920749
2,55,"Management of Companies and Enterprises",1170.30033524
3,551,"Management of Companies and Enterprises",1170.30033524
4,5511,"Management of Companies and Enterprises",1170.30033524
5,55111,"Management of Companies and Enterprises",1170.30033524
6,551111,"Offices of Bank Holding Companies",0
6,551112,"Offices of Other Holding Companies",0
6,551114,"Corporate, Subsidiary, and Regional Managing Offices",1170.30033524
2,56,"Administrative and Support and Waste Management and Remediation Services",2568.12904848
3,561,"Administrative and Support Services",2375.71295886
4,5611,"Office Administrative Services",428.088317024
5,56111,"Office Administrative Services",428.088317024
6,561110,"Office Administrative Services",428.088317024
4,5612,"Facilities Support Services",0
5,56121,"Facilities Support Services",0
6,561210,"Facilities Support Services",0
4,5613,"Employment Services",207.289184689
5,56131,"Employment Placement Agencies and Executive Search Services",38.3414201109
6,561311,"Employment Placement Agencies",18.6368278942
6,561312,"Executive Search Services",19.7045922167
5,56132,"Temporary Help Services",122.86019478
6,561320,"Temporary Help Services",122.86019478
5,56133,"Professional Employer Organizations",46.0875697987
6,561330,"Professional Employer Organizations",46.0875697987
4,5614,"Business Support Services",212.76173026
5,56141,"Document Preparation Services",0
6,561410,"Document Preparation Services",0
5,56142,"Telephone Call Centers",143.114065622
6,561421,"Telephone Answering Services",3.21872166825
6,561422,"Telemarketing Bureaus and Other Contact Centers",139.895343954
5,56143,"Business Service Centers",17.4754752109
6,561431,"Private Mail Centers",13.9432432382
6,561439,"Other Business Service Centers (including Copy Shops)",3.53223197269
5,56144,"Collection Agencies",0
6,561440,"Collection Agencies",0
5,56145,"Credit Bureaus",0
6,561450,"Credit Bureaus",0
5,56149,"Other Business Support Services",52.1721894268
6,561491,"Repossession Services",1.21081659564
6,561492,"Court Reporting and Stenotype Services",25.0569773627
6,561499,"All Other Business Support Services",25.9043954685
4,5615,"Travel Arrangement and Reservation Services",68.5855691845
5,56151,"Travel Agencies",35.6512064407
6,561510,"Travel Agencies",35.6512064407
5,56152,"Tour Operators",0
6,561520,"Tour Operators",0
5,56159,"Other Travel Arrangement and Reservation Services",32.9343627438
6,561591,"Convention and Visitors Bureaus",5.90553048224
6,561599,"All Other Travel Arrangement and Reservation Services",27.0288322616
4,5616,"Investigation and Security Services",72.3830798882
5,56161,"Investigation, Guard, and Armored Car Services",12.4460409066
6,561611,"Investigation Services",0.750851979217
6,561612,"Security Guards and Patrol Services",11.6951889274
6,561613,"Armored Car Services",0
5,56162,"Security Systems Services",59.9370389816
6,561621,"Security Systems Services (except Locksmiths)",48.7906172003
6,561622,"Locksmiths",11.1464217813
4,5617,"Services to Buildings and Dwellings",1356.43223506
5,56171,"Exterminating and Pest Control Services",2.93164798833
6,561710,"Exterminating and Pest Control Services",2.93164798833
5,56172,"Janitorial Services",949.088024395
6,561720,"Janitorial Services",949.088024395
5,56173,"Landscaping Services",288.288793929
6,561730,"Landscaping Services",288.288793929
5,56174,"Carpet and Upholstery Cleaning Services",31.7114784746
6,561740,"Carpet and Upholstery Cleaning Services",31.7114784746
5,56179,"Other Services to Buildings and Dwellings",84.4122902771
6,561790,"Other Services to Buildings and Dwellings",84.4122902771
4,5619,"Other Support Services",30.1728427552
5,56191,"Packaging and Labeling Services",8.62769782109
6,561910,"Packaging and Labeling Services",8.62769782109
5,56192,"Convention and Trade Show Organizers",10.8550154634
6,561920,"Convention and Trade Show Organizers",10.8550154634
5,56199,"All Other Support Services",10.6901294707
6,561990,"All Other Support Services",10.6901294707
3,562,"Waste Management and Remediation Services",192.416089614
4,5621,"Waste Collection",57.6373773514
5,56211,"Waste Collection",57.6373773514
6,562111,"Solid Waste Collection",56.5064138364
6,562112,"Hazardous Waste Collection",0
6,562119,"Other Waste Collection",1.13096351507
4,5622,"Waste Treatment and Disposal",20.3831098098
5,56221,"Waste Treatment and Disposal",20.3831098098
6,562211,"Hazardous Waste Treatment and Disposal",0
6,562212,"Solid Waste Landfill",2.16039050517
6,562213,"Solid Waste Combustors and Incinerators",0
6,562219,"Other Nonhazardous Waste Treatment and Disposal",18.2227193046
4,5629,"Remediation and Other Waste Management Services",114.395602453
5,56291,"Remediation Services",4.29717810168
6,562910,"Remediation Services",4.29717810168
5,56292,"Materials Recovery Facilities",110.098424351
6,562920,"Materials Recovery Facilities",110.098424351
5,56299,"All Other Waste Management Services",0
6,562991,"Septic Tank and Related Services",0
6,562998,"All Other Miscellaneous Waste Management Services",0
2,61,"Educational Services",604.30080769
3,611,"Educational Services",604.30080769
4,6111,"Elementary and Secondary Schools",206.017547939
5,61111,"Elementary and Secondary Schools",206.017547939
6,611110,"Elementary and Secondary Schools",206.017547939
4,6112,"Junior Colleges",0
5,61121,"Junior Colleges",0
6,611210,"Junior Colleges",0
4,6113,"Colleges, Universities, and Professional Schools",24.7411080453
5,61131,"Colleges, Universities, and Professional Schools",24.7411080453
6,611310,"Colleges, Universities, and Professional Schools",24.7411080453
4,6114,"Business Schools and Computer and Management Training",0
5,61141,"Business and Secretarial Schools",0
6,611410,"Business and Secretarial Schools",0
5,61142,"Computer Training",0
6,611420,"Computer Training",0
5,61143,"Professional and Management Development Training",0
6,611430,"Professional and Management Development Training",0
4,6115,"Technical and Trade Schools",112.768132085
5,61151,"Technical and Trade Schools",112.768132085
6,611511,"Cosmetology and Barber Schools",47.8690515961
6,611512,"Flight Training",0
6,611513,"Apprenticeship Training",17.525030185
6,611519,"Other Technical and Trade Schools",47.3740503035
4,6116,"Other Schools and Instruction",197.428270635
5,61161,"Fine Arts Schools",87.5129207396
6,611610,"Fine Arts Schools",87.5129207396
5,61162,"Sports and Recreation Instruction",41.8237128847
6,611620,"Sports and Recreation Instruction",41.8237128847
5,61163,"Language Schools",2.32815510078
6,611630,"Language Schools",2.32815510078
5,61169,"All Other Schools and Instruction",65.7634819096
6,611691,"Exam Preparation and Tutoring",34.3582724178
6,611692,"Automobile Driving Schools",1.67485652651
6,611699,"All Other Miscellaneous Schools and Instruction",29.7303529653
4,6117,"Educational Support Services",63.3457489861
5,61171,"Educational Support Services",63.3457489861
6,611710,"Educational Support Services",63.3457489861
2,62,"Health Care and Social Assistance",6701.57298003
3,621,"Ambulatory Health Care Services",2434.17599366
4,6211,"Offices of Physicians",976.161024412
5,62111,"Offices of Physicians",976.161024412
6,621111,"Offices of Physicians (except Mental Health Specialists)",964.545368825
6,621112,"Offices of Physicians, Mental Health Specialists",11.6156555871
4,6212,"Offices of Dentists",483.363068197
5,62121,"Offices of Dentists",483.363068197
6,621210,"Offices of Dentists",483.363068197
4,6213,"Offices of Other Health Practitioners",556.369807687
5,62131,"Offices of Chiropractors",76.6613188167
6,621310,"Offices of Chiropractors",76.6613188167
5,62132,"Offices of Optometrists",54.2261922602
6,621320,"Offices of Optometrists",54.2261922602
5,62133,"Offices of Mental Health Practitioners (except Physicians)",93.520548425
6,621330,"Offices of Mental Health Practitioners (except Physicians)",93.520548425
5,62134,"Offices of Physical, Occupational and Speech Therapists, and Audiologists",177.068726856
6,621340,"Offices of Physical, Occupational and Speech Therapists, and Audiologists",177.068726856
5,62139,"Offices of All Other Health Practitioners",154.89302133
6,621391,"Offices of Podiatrists",8.83591532688
6,621399,"Offices of All Other Miscellaneous Health Practitioners",146.057106003
4,6214,"Outpatient Care Centers",14.3464185191
5,62141,"Family Planning Centers",0
6,621410,"Family Planning Centers",0
5,62142,"Outpatient Mental Health and Substance Abuse Centers",0
6,621420,"Outpatient Mental Health and Substance Abuse Centers",0
5,62149,"Other Outpatient Care Centers",14.3464185191
6,621491,"HMO Medical Centers",0
6,621492,"Kidney Dialysis Centers",8.64629863381
6,621493,"Freestanding Ambulatory Surgical and Emergency Centers",5.70011988524
6,621498,"All Other Outpatient Care Centers",0
4,6215,"Medical and Diagnostic Laboratories",62.2088366165
5,62151,"Medical and Diagnostic Laboratories",62.2088366165
6,621511,"Medical Laboratories",40.9546377446
6,621512,"Diagnostic Imaging Centers",21.2541988719
4,6216,"Home Health Care Services",279.840630424
5,62161,"Home Health Care Services",279.840630424
6,621610,"Home Health Care Services",279.840630424
4,6219,"Other Ambulatory Health Care Services",61.886207808
5,62191,"Ambulance Services",43.6107864989
6,621910,"Ambulance Services",43.6107864989
5,62199,"All Other Ambulatory Health Care Services",18.2754213091
6,621991,"Blood and Organ Banks",0
6,621999,"All Other Miscellaneous Ambulatory Health Care Services",18.2754213091
3,622,"Hospitals",1716.47876881
4,6221,"General Medical and Surgical Hospitals",1716.47876881
5,62211,"General Medical and Surgical Hospitals",1716.47876881
6,622110,"General Medical and Surgical Hospitals",1716.47876881
4,6222,"Psychiatric and Substance Abuse Hospitals",0
5,62221,"Psychiatric and Substance Abuse Hospitals",0
6,622210,"Psychiatric and Substance Abuse Hospitals",0
4,6223,"Specialty (except Psychiatric and Substance Abuse) Hospitals",0
5,62231,"Specialty (except Psychiatric and Substance Abuse) Hospitals",0
6,622310,"Specialty (except Psychiatric and Substance Abuse) Hospitals",0
3,623,"Nursing and Residential Care Facilities",886.994748544
4,6231,"Nursing Care Facilities (Skilled Nursing Facilities)",638.00452161
5,62311,"Nursing Care Facilities (Skilled Nursing Facilities)",638.00452161
6,623110,"Nursing Care Facilities (Skilled Nursing Facilities)",638.00452161
4,6232,"Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities",0
5,62321,"Residential Intellectual and Developmental Disability Facilities",0
6,623210,"Residential Intellectual and Developmental Disability Facilities",0
5,62322,"Residential Mental Health and Substance Abuse Facilities",0
6,623220,"Residential Mental Health and Substance Abuse Facilities",0
4,6233,"Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly",248.990226934
5,62331,"Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly",248.990226934
6,623311,"Continuing Care Retirement Communities",145.378059042
6,623312,"Assisted Living Facilities for the Elderly",103.612167892
4,6239,"Other Residential Care Facilities",0
5,62399,"Other Residential Care Facilities",0
6,623990,"Other Residential Care Facilities",0
3,624,"Social Assistance",1663.92346902
4,6241,"Individual and Family Services",1033.00845509
5,62411,"Child and Youth Services",28.8149223141
6,624110,"Child and Youth Services",28.8149223141
5,62412,"Services for the Elderly and Persons with Disabilities",643.712685349
6,624120,"Services for the Elderly and Persons with Disabilities",643.712685349
5,62419,"Other Individual and Family Services",360.480847425
6,624190,"Other Individual and Family Services",360.480847425
4,6242,"Community Food and Housing, and Emergency and Other Relief Services",15.8581482893
5,62421,"Community Food Services",12.2540854558
6,624210,"Community Food Services",12.2540854558
5,62422,"Community Housing Services",3.60406283342
6,624221,"Temporary Shelters",0
6,624229,"Other Community Housing Services",3.60406283342
5,62423,"Emergency and Other Relief Services",0
6,624230,"Emergency and Other Relief Services",0
4,6243,"Vocational Rehabilitation Services",24.6760586447
5,62431,"Vocational Rehabilitation Services",24.6760586447
6,624310,"Vocational Rehabilitation Services",24.6760586447
4,6244,"Child Day Care Services",590.380806995
5,62441,"Child Day Care Services",590.380806995
6,624410,"Child Day Care Services",590.380806995
2,71,"Arts, Entertainment, and Recreation",1013.05581996
3,711,"Performing Arts, Spectator Sports, and Related Industries",446.665834447
4,7111,"Performing Arts Companies",66.5371678317
5,71111,"Theater Companies and Dinner Theaters",66.5371678317
6,711110,"Theater Companies and Dinner Theaters",66.5371678317
5,71112,"Dance Companies",0
6,711120,"Dance Companies",0
5,71113,"Musical Groups and Artists",0
6,711130,"Musical Groups and Artists",0
5,71119,"Other Performing Arts Companies",0
6,711190,"Other Performing Arts Companies",0
4,7112,"Spectator Sports",154.336269914
5,71121,"Spectator Sports",154.336269914
6,711211,"Sports Teams and Clubs",19.6599075996
6,711212,"Racetracks",16.7172348481
6,711219,"Other Spectator Sports",117.959127466
4,7113,"Promoters of Performing Arts, Sports, and Similar Events",112.581828831
5,71131,"Promoters of Performing Arts, Sports, and Similar Events with Facilities",93.9028583446
6,711310,"Promoters of Performing Arts, Sports, and Similar Events with Facilities",93.9028583446
5,71132,"Promoters of Performing Arts, Sports, and Similar Events without Facilities",18.6789704866
6,711320,"Promoters of Performing Arts, Sports, and Similar Events without Facilities",18.6789704866
4,7114,"Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",6.21896608269
5,71141,"Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",6.21896608269
6,711410,"Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",6.21896608269
4,7115,"Independent Artists, Writers, and Performers",106.991601787
5,71151,"Independent Artists, Writers, and Performers",106.991601787
6,711510,"Independent Artists, Writers, and Performers",106.991601787
3,712,"Museums, Historical Sites, and Similar Institutions",10.4982988801
4,7121,"Museums, Historical Sites, and Similar Institutions",10.4982988801
5,71211,"Museums",2.29025636778
6,712110,"Museums",2.29025636778
5,71212,"Historical Sites",0.229509456126
6,712120,"Historical Sites",0.229509456126
5,71213,"Zoos and Botanical Gardens",0
6,712130,"Zoos and Botanical Gardens",0
5,71219,"Nature Parks and Other Similar Institutions",7.97853305619
6,712190,"Nature Parks and Other Similar Institutions",7.97853305619
3,713,"Amusement, Gambling, and Recreation Industries",555.891686631
4,7131,"Amusement Parks and Arcades",0
5,71311,"Amusement and Theme Parks",0
6,713110,"Amusement and Theme Parks",0
5,71312,"Amusement Arcades",0
6,713120,"Amusement Arcades",0
4,7132,"Gambling Industries",2.02352883827
5,71321,"Casinos (except Casino Hotels)",0
6,713210,"Casinos (except Casino Hotels)",0
5,71329,"Other Gambling Industries",2.02352883827
6,713290,"Other Gambling Industries",2.02352883827
4,7139,"Other Amusement and Recreation Industries",553.868157793
5,71391,"Golf Courses and Country Clubs",289.400420903
6,713910,"Golf Courses and Country Clubs",289.400420903
5,71392,"Skiing Facilities",0
6,713920,"Skiing Facilities",0
5,71393,"Marinas",37.2916187661
6,713930,"Marinas",37.2916187661
5,71394,"Fitness and Recreational Sports Centers",120.019692982
6,713940,"Fitness and Recreational Sports Centers",120.019692982
5,71395,"Bowling Centers",9.5635857063
6,713950,"Bowling Centers",9.5635857063
5,71399,"All Other Amusement and Recreation Industries",97.5928394358
6,713990,"All Other Amusement and Recreation Industries",97.5928394358
2,72,"Accommodation and Food Services",5835.02288678
3,721,"Accommodation",786.126164126
4,7211,"Traveler Accommodation",645.676177776
5,72111,"Hotels (except Casino Hotels) and Motels",645.676177776
6,721110,"Hotels (except Casino Hotels) and Motels",645.676177776
5,72112,"Casino Hotels",0
6,721120,"Casino Hotels",0
5,72119,"Other Traveler Accommodation",0
6,721191,"Bed-and-Breakfast Inns",0
6,721199,"All Other Traveler Accommodation",0
4,7212,"RV (Recreational Vehicle) Parks and Recreational Camps",99.3833719387
5,72121,"RV (Recreational Vehicle) Parks and Recreational Camps",99.3833719387
6,721211,"RV (Recreational Vehicle) Parks and Campgrounds",0
6,721214,"Recreational and Vacation Camps (except Campgrounds)",99.3833719387
4,7213,"Rooming and Boarding Houses, Dormitories, and Workers' Camps",41.0666144117
5,72131,"Rooming and Boarding Houses, Dormitories, and Workers' Camps",41.0666144117
6,721310,"Rooming and Boarding Houses, Dormitories, and Workers' Camps",41.0666144117
3,722,"Food Services and Drinking Places",5048.89672265
4,7223,"Special Food Services",16.6376294097
5,72231,"Food Service Contractors",0
6,722310,"Food Service Contractors",0
5,72232,"Caterers",0
6,722320,"Caterers",0
5,72233,"Mobile Food Services",16.6376294097
6,722330,"Mobile Food Services",16.6376294097
4,7224,"Drinking Places (Alcoholic Beverages)",103.780549664
5,72241,"Drinking Places (Alcoholic Beverages)",103.780549664
6,722410,"Drinking Places (Alcoholic Beverages)",103.780549664
4,7225,"Restaurants and Other Eating Places",4928.47854358
5,72251,"Restaurants and Other Eating Places",4928.47854358
6,722511,"Full-Service Restaurants",2938.55179801
6,722513,"Limited-Service Restaurants",1572.84293233
6,722514,"Cafeterias, Grill Buffets, and Buffets",110.161850947
6,722515,"Snack and Nonalcoholic Beverage Bars",306.92196229
2,81,"Other Services (except Public Administration)",3116.3147477
3,811,"Repair and Maintenance",760.292804693
4,8111,"Automotive Repair and Maintenance",344.023140101
5,81111,"Automotive Mechanical and Electrical Repair and Maintenance",77.2687864379
6,811111,"General Automotive Repair",56.1600167078
6,811112,"Automotive Exhaust System Repair",0.573755437186
6,811113,"Automotive Transmission Repair",1.29626915207
6,811118,"Other Automotive Mechanical and Electrical Repair and Maintenance",19.2387451409
5,81112,"Automotive Body, Paint, Interior, and Glass Repair",163.782526153
6,811121,"Automotive Body, Paint, and Interior Repair and Maintenance",147.433997795
6,811122,"Automotive Glass Replacement Shops",16.3485283581
5,81119,"Other Automotive Repair and Maintenance",102.971827511
6,811191,"Automotive Oil Change and Lubrication Shops",22.6925602414
6,811192,"Car Washes",80.2792672691
6,811198,"All Other Automotive Repair and Maintenance",0
4,8112,"Electronic and Precision Equipment Repair and Maintenance",0
5,81121,"Electronic and Precision Equipment Repair and Maintenance",0
6,811211,"Consumer Electronics Repair and Maintenance",0
6,811212,"Computer and Office Machine Repair and Maintenance",0
6,811213,"Communication Equipment Repair and Maintenance",0
6,811219,"Other Electronic and Precision Equipment Repair and Maintenance",0
4,8113,"Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",227.459399699
5,81131,"Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",227.459399699
6,811310,"Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",227.459399699
4,8114,"Personal and Household Goods Repair and Maintenance",188.810264893
5,81141,"Home and Garden Equipment and Appliance Repair and Maintenance",6.37910515169
6,811411,"Home and Garden Equipment Repair and Maintenance",0
6,811412,"Appliance Repair and Maintenance",6.37910515169
5,81142,"Reupholstery and Furniture Repair",5.666798395
6,811420,"Reupholstery and Furniture Repair",5.666798395
5,81143,"Footwear and Leather Goods Repair",0.991965866464
6,811430,"Footwear and Leather Goods Repair",0.991965866464
5,81149,"Other Personal and Household Goods Repair and Maintenance",175.77239548
6,811490,"Other Personal and Household Goods Repair and Maintenance",175.77239548
3,812,"Personal and Laundry Services",879.013606681
4,8121,"Personal Care Services",739.834629503
5,81211,"Hair, Nail, and Skin Care Services",568.77518529
6,812111,"Barber Shops",53.4976140448
6,812112,"Beauty Salons",443.835326951
6,812113,"Nail Salons",71.4422442942
5,81219,"Other Personal Care Services",171.059444214
6,812191,"Diet and Weight Reducing Centers",32.0735415126
6,812199,"Other Personal Care Services",138.985902701
4,8122,"Death Care Services",31.9946870112
5,81221,"Funeral Homes and Funeral Services",31.9946870112
6,812210,"Funeral Homes and Funeral Services",31.9946870112
5,81222,"Cemeteries and Crematories",0
6,812220,"Cemeteries and Crematories",0
4,8123,"Drycleaning and Laundry Services",6.84877163747
5,81231,"Coin-Operated Laundries and Drycleaners",3.52820939456
6,812310,"Coin-Operated Laundries and Drycleaners",3.52820939456
5,81232,"Drycleaning and Laundry Services (except Coin-Operated)",3.3205622429
6,812320,"Drycleaning and Laundry Services (except Coin-Operated)",3.3205622429
5,81233,"Linen and Uniform Supply",0
6,812331,"Linen Supply",0
6,812332,"Industrial Launderers",0
4,8129,"Other Personal Services",100.335518529
5,81291,"Pet Care (except Veterinary) Services",61.5421244662
6,812910,"Pet Care (except Veterinary) Services",61.5421244662
5,81292,"Photofinishing",1.83450401974
6,812921,"Photofinishing Laboratories (except One-Hour)",1.26436987621
6,812922,"One-Hour Photofinishing",0.570134143528
5,81293,"Parking Lots and Garages",0
6,812930,"Parking Lots and Garages",0
5,81299,"All Other Personal Services",36.9588900431
6,812990,"All Other Personal Services",36.9588900431
3,813,"Religious, Grantmaking, Civic, Professional, and Similar Organizations",1221.8071498
4,8131,"Religious Organizations",1084.1539638
5,81311,"Religious Organizations",1084.1539638
6,813110,"Religious Organizations",1084.1539638
4,8132,"Grantmaking and Giving Services",34.7517505443
5,81321,"Grantmaking and Giving Services",34.7517505443
6,813211,"Grantmaking Foundations",2.42086260958
6,813212,"Voluntary Health Organizations",3.84552980255
6,813219,"Other Grantmaking and Giving Services",28.4853581321
4,8133,"Social Advocacy Organizations",20.5197953032
5,81331,"Social Advocacy Organizations",20.5197953032
6,813311,"Human Rights Organizations",5.64482507677
6,813312,"Environment, Conservation and Wildlife Organizations",10.8520441265
6,813319,"Other Social Advocacy Organizations",4.02292609993
4,8134,"Civic and Social Organizations",42.5264017311
5,81341,"Civic and Social Organizations",42.5264017311
6,813410,"Civic and Social Organizations",42.5264017311
4,8139,"Business, Professional, Labor, Political, and Similar Organizations",39.8552384177
5,81391,"Business Associations",37.0200612082
6,813910,"Business Associations",37.0200612082
5,81392,"Professional Organizations",0.877672790552
6,813920,"Professional Organizations",0.877672790552
5,81393,"Labor Unions and Similar Labor Organizations",0.769847080047
6,813930,"Labor Unions and Similar Labor Organizations",0.769847080047
5,81394,"Political Organizations",0
6,813940,"Political Organizations",0
5,81399,"Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)",1.18765733891
6,813990,"Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)",1.18765733891
3,814,"Private Households",255.201186521
4,8141,"Private Households",255.201186521
5,81411,"Private Households",255.201186521
6,814110,"Private Households",255.201186521
2,90,"Government",3655.56472341
3,901,"Federal Government",779.816821868
4,9011,"Federal Government, Civilian",142.226748691
5,90114,"US Postal Service",102.297095369
6,901149,"US Postal Service",102.297095369
5,90119,"Federal Government, Civilian, Excluding Postal Service",39.9296533219
6,901199,"Federal Government, Civilian, Excluding Postal Service",39.9296533219
4,9012,"Federal Government, Military",637.590073177
5,90120,"Federal Government, Military",637.590073177
6,901200,"Federal Government, Military",637.590073177
3,902,"State Government",58.5644828769
4,9026,"Education and Hospitals (State Government)",0
5,90261,"Education (State Government)",0
6,902611,"Elementary and Secondary Schools (State Government)",0
6,902612,"Colleges, Universities, and Professional Schools (State Government)",0
6,902619,"All Other Schools and Educational Support Services (State Government)",0
5,90262,"Hospitals (State Government)",0
6,902622,"Hospitals (State Government)",0
4,9029,"State Government, Excluding Education and Hospitals",58.5644828769
5,90299,"State Government, Excluding Education and Hospitals",58.5644828769
6,902999,"State Government, Excluding Education and Hospitals",58.5644828769
3,903,"Local Government",2817.18341866
4,9036,"Education and Hospitals (Local Government)",2048.88939228
5,90361,"Education (Local Government)",2048.88939228
6,903611,"Elementary and Secondary Schools (Local Government)",2048.88939228
6,903612,"Colleges, Universities, and Professional Schools (Local Government)",0
6,903619,"All Other Schools and Educational Support Services (Local Government)",0
5,90362,"Hospitals (Local Government)",0
6,903622,"Hospitals (Local Government)",0
4,9039,"Local Government, Excluding Education and Hospitals",768.294026379
5,90399,"Local Government, Excluding Education and Hospitals",768.294026379
6,903999,"Local Government, Excluding Education and Hospitals",768.294026379
2,99,"Unclassified Industry",0
3,999,"Unclassified Industry",0
4,9999,"Unclassified Industry",0
5,99999,"Unclassified Industry",0
6,999999,"Unclassified Industry",0'
`
}
