import styled from "styled-components"

export const RightAlignDiv = styled.div`
  text-align: right;
`

export const ColumnCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ToggleRowButtonAlt = styled.button`
  margin-top: -10px;
  background-color: #eee;
  min-width: 85px;
  height: 30px;
  font-size: 14px;
  margin-bottom: 16px;
  border: none;
  border-radius: 3px;
  margin-top: -46px;
  margin-left: 20px;
`

export const ToggleColumnsButton = styled.button`
  background-color: #eee;
  min-width: 85px;
  height: 30px;
  font-size: 14px;
  margin-bottom: 16px;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
`

export const ToggleRowsButton = styled.button`
  margin-top: -10px;
  background-color: #eee;
  min-width: 85px;
  height: 30px;
  font-size: 14px;
  margin-bottom: 16px;
  border: none;
  border-radius: 3px;
  margin-top: -46px;
  margin-left: 20px;
`

export const HeaderContainer = styled.div`
  display: flex;
`

export const LoadingStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

export const Checkbox = styled.input`
  width: 20px;
`
export const TableDiv = styled.div`
  background-color: white;
  margin-top: -54px;
  width: 100%;
  margin-left: -18px;
  border-radius: 3px;
  border: 1px solid #ddd;
  @media (max-width: 768px) {
    margin-top: -16px;
    margin-left: 2px;
  }
`
export const SubHeader = styled.h2`
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  margin-top: -18px;
  margin-bottom: 22px;
  margin-left: 15px;
  letter-spacing: -0.5px;
  padding-left: 30px;
`

export const TableHeader = styled.h1`
  padding-left: 30px;
  padding-top: 30px;
  font-family: inherit;
  font-size: 30px;
  margin-left: 15px;
  font-weight: 500;
  letter-spacing: -1px;
`

export const Styles = styled.div`
  display: flex;
  padding: 1rem;
  width: 100%;
  margin-top: 5px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    min-width: 200vw;
  }

  table {
    overflow-y: hidden;
    background-color: white;
    border-spacing: 0;
    border: 1px solid #e0e0e0;
    font-size: 0.75em;
    line-height: 1.1em;
    width: 100%;

    .red {
      color: #ff0000;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      :last-child {
        border-right: 0;
      }
    }

    td {
      border: 1px solid #e0e0e0;
    }

    th {
      color: #3a82c3;
      background-color: #eeeeee;
      font-weight: 600;
    }
  }
`
