import React from "react"
import { DataStore } from "../../types/types"
import { inject, observer } from "mobx-react"

interface NotificationsProps {
  dataStore?: DataStore
}

const MESSAGE_TYPE = "MESSAGE_TYPE"
const ERROR_MESSAGE_TYPE = "ERROR_MESSAGE_TYPE"

@inject("dataStore")
@observer
export default class Notifications extends React.Component<NotificationsProps> {
  componentDidUpdate() {
    const { dataStore } = this.props
    if (dataStore) {
      if (dataStore.aMessageExists) {
        setTimeout(function() {
          dataStore.clearMessages()
        }, 3000)
      }
    }
  }
  render() {
    const { dataStore } = this.props
    let display = false
    let message, type
    if (dataStore !== undefined && dataStore.message) {
      message = dataStore.message
      type = MESSAGE_TYPE
      display = true
    }
    if (dataStore !== undefined && dataStore.errorMessage) {
      message = dataStore.errorMessage
      type = ERROR_MESSAGE_TYPE
      display = true
    }
    return display ? <div>{message}</div> : null
  }
}
