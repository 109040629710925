import React from "react"
import styled from "styled-components"

import { YEARS, PROGRAM_YEARS } from "./years"
import { PROGRAM_TABLE } from "../../types/types"

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  background-color: white;
  margin: -17px 30px 30px 2px;
  padding: 20px;
  width: 300px;
  border-radius: 3px;
  border: 1px solid #ddd;
`

const StyledSelect = styled.select`
  background-color: #eee;
  border: 1px solid transparent;
  color: #555;
  cursor: pointer;
  display: inline-block;
  width: 76px;
  height: 27px;
  font-size: 13px;
`

const Header = styled.span`
  margin-bottom: -18px;
  font-size: 14px;
`

const HiddenLabel = styled.label`
  display: none;
`

const MiddleSpan = styled.span`
  margin-left: 7px;
  margin-right: 7px;
  font-size: 14px;
`

interface TimeframeFilterProps {
  toYear: number
  fromYear: number
  handleChange(el: object): void
  tableType: string
}
const TimeframeFilter: React.FC<TimeframeFilterProps> = (
  props: TimeframeFilterProps
) => {
  function generateOptions(years: number[]) {
    const optionsArray = years.map(el => {
      return (
        <option key={el} value={el}>
          {el}
        </option>
      )
    })
    return optionsArray
  }
  function constructYears(tableType: string) {
    if (tableType === PROGRAM_TABLE) return PROGRAM_YEARS
    return YEARS
  }
  return (
    <Styles>
      <Header>Timeframe</Header>
      <br />
      <div>
        <HiddenLabel htmlFor="fromYear">From:</HiddenLabel>
        <StyledSelect
          name="fromYear"
          id="fromYear"
          onChange={props.handleChange}
          value={props.fromYear}
        >
          {generateOptions(constructYears(props.tableType))}
        </StyledSelect>
        <MiddleSpan>to</MiddleSpan>
        <HiddenLabel htmlFor="toYear">To:</HiddenLabel>
        <StyledSelect
          name="toYear"
          id="toYear"
          onChange={el => props.handleChange(el)}
          value={props.toYear}
        >
          {generateOptions(constructYears(props.tableType))}
        </StyledSelect>
      </div>
    </Styles>
  )
}

export default TimeframeFilter
