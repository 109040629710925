import { observable } from "mobx"
import {
  IndustryData,
  OccupationData,
  DemographicsData,
  ProgramData,
  DEMOGRAPHICS_TABLE,
} from "../types/types"

class DataStore {
  @observable data = " Data Store"
  @observable oauthToken = ""
  @observable oauthTokenTime = 0
  @observable oauthLoading = false
  @observable message = ""
  @observable errorMessage = ""
  @observable aMessageExists = false
  @observable industryTable: IndustryData[] = []
  @observable occupationTable: OccupationData[] = []
  @observable demographicsTable: DemographicsData[] = []
  @observable programTable: ProgramData[] = []

  updateOauthToken(newToken: string) {
    this.oauthToken = newToken
  }

  updateOauthTokenTime(newTokenTime: number) {
    this.oauthTokenTime = newTokenTime
  }

  updateMessage(newMessage: string) {
    this.aMessageExists = true
    this.message = newMessage
  }

  updateErrorMessage(newErrorMessage: string) {
    this.aMessageExists = true
    this.errorMessage = newErrorMessage
  }

  clearMessages() {
    this.errorMessage = ""
    this.message = ""
    this.aMessageExists = false
  }

  updateIndustryTable(newIndustryTable: IndustryData[]) {
    this.industryTable = newIndustryTable
  }

  updateOauthLoading(loading: boolean) {
    this.oauthLoading = loading
  }

  updateOccupationTable(newOccupationTable: OccupationData[]) {
    this.occupationTable = newOccupationTable
  }

  updateDemographicsTable(newDemographicsTable: DemographicsData[]) {
    this.demographicsTable = newDemographicsTable
  }

  updateProgramTable(newProgramTable: ProgramData[]) {
    this.programTable = newProgramTable
  }
}

export default new DataStore()
